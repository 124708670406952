/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Allan Moya <amoya@costacodecr.com>
 */

import React from "react";
import { Button } from "primereact/button";
import { showSuccess } from "../../utils/message";
import useService from "../../hooks/hook-service";

const ElectoralVoters = () => {
  const { post } = useService();

  const updateElectoralPersons = () => {
    post("persons/load", {}, () => {
      showSuccess("El padrón electoral se está actualizando. Usted será notificado de dicha actualización cuando esté completa. Puede continuar en el sistema con normalidad.");
    });
  }

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Actualizar Padrón Electoral</h1>
            </div>
            <div className="spacer20" />
            <div className="grid">
              <div className="col-12 md:col-12">
                <label htmlFor="input">
                  Actualizar archivo del padrón electoral
                </label>
              </div>

              <div className="md:col-12">
                <Button label="Actualizar" onClick={updateElectoralPersons} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElectoralVoters;