/* eslint-disable jsx-a11y/anchor-is-valid */
/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React, { useState } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { removeAuthentication } from "../store/authManager";
import { useDispatch, useSelector } from "react-redux";
import useService from "../hooks/hook-service";

export const AppInlineProfile = () => {
  const auth = useSelector((state) => state.authManager.value);
  const [expanded, setExpanded] = useState(false);
  const { post } = useService();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClick = (event) => {
    setExpanded(!expanded);
    event.preventDefault();
  };

  const closeSession = () => {
    post("user/logout", {}, () => dispatch(removeAuthentication()));
  };

  const profile = () =>
    navigate("/profile", {
      state: { user: auth.user },
    });

  return (
    <div className="profile">
      <a className="profile-link" onClick={onClick}>
        <span className="username">
          {auth.user?.name + " " + auth.user?.lastName}
        </span>
        <i className="pi pi-fw pi-cog" />
      </a>
      <ul className={classNames({ "profile-expanded": expanded })}>
        <li onClick={() => profile()}>
          <a>
            <i className="pi pi-fw pi-user" />
            <span>Mi Perfil</span>
          </a>
        </li>
        <li onClick={() => closeSession()}>
          <a>
            <i className="pi pi-fw pi-power-off" />
            <span>Cerrar Sesión</span>
          </a>
        </li>
      </ul>
    </div>
  );
};
