import React from "react";
import { formatCurrencyValue, formatDate } from "../../utils/format";

const ReceiptHeader = ({ receipt }) => {
  return (
    <div className="grid">
      <div className="md:col-4">
        <label htmlFor="input">
          Cédula del Asociado: {receipt.affiliate?.idCard}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Nombre del Asociado: {receipt.affiliate?.firstName}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Apellidos del Asociado: {receipt.affiliate?.lastName}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Monto Pagado: {formatCurrencyValue(receipt.amountReceived)}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Fecha Creación: {formatDate(receipt.creationDate,  "DD-MM-YYYY, hh:mm:ss a")}
        </label>
      </div>
    </div>
  );
};

export default ReceiptHeader;
