import Swal from "sweetalert2";

export const showError = (message) => {
  Swal.fire({
    title: "Error",
    text: message,
    icon: "error",
    confirmButtonColor: "#007ad9",
  });
};

export const showSuccess = (message) => {
  Swal.fire({
    title: "Éxito",
    text: message,
    icon: "success",
    confirmButtonColor: "#007ad9",
  });
};

export const showWarning = (message) => {
  Swal.fire({
    title: "Advertencia",
    text: message,
    icon: "warning",
    confirmButtonColor: "#007ad9",
  });
};

export const showWarningDialog = (options, func) => {
  Swal.fire({
    ...options,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#007ad9",
    confirmButtonAriaLabel: "Confirm button aria-label",
    cancelButtonAriaLabel: "Cancel button aria-label",
  }).then((result) => {
    if (result.value) {
      if (func.confirm) {
        func.confirm();
      }
    }
  });
};
