import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import GenericInputId from "../../components/GenericComponents/GenericInputId";
import GenericInputPhone from "../../components/GenericComponents/GenericInputPhone";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import useService from "../../hooks/hook-service";
import {
  showSuccess,
  showWarning,
  showWarningDialog,
} from "../../utils/message";
import GenericCalendar from "../GenericComponents/GenericCalendar";
import { classNames } from "primereact/utils";

export const AffiliateOtherInformationForm = ({ isFromCreate = true }) => {
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [counties, setCounties] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [genders, setGenders] = useState([]);

  const { post, manyPost } = useService();
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const idProvince = watch("idProvince");
  const idCounty = watch("idCounty");

  useEffect(() => {
    const request = [
      {
        url: "catalog/list",
        body: {
          catalogType: 1008,
        },
      },
      {
        url: "division/list",
        body: {},
      },
      {
        url: "catalog/list",
        body: {
          catalogType: 1006,
        },
      },
    ];
    manyPost(request, (result) => {
      if (result[0].catalogs.length === 0) {
        showWarning("No se encontraron estados civiles");
      }
      setMaritalStatus(result[0].catalogs);

      if (result[1].divisions.length === 0) {
        showWarning("No se encontraron provincias");
      }
      setProvinces(result[1].divisions);

      setGenders(result[2].catalogs);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const data = {
      parentId: idProvince,
    };
    post("division/list", data, ({ divisions }) => {
      if (divisions.length === 0) showWarning("No se encontraron cantones");

      setCounties(divisions);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProvince]);

  useEffect(() => {
    const data = {
      parentId: idCounty,
    };
    post("division/list", data, ({ divisions }) => {
      if (divisions.length === 0) showWarning("No se encontraron districtos");

      setDistricts(divisions);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCounty]);

  return (
    <>
      <div className="col-6 md:col-2">
        <label htmlFor="input">Correo Electrónico</label>
      </div>
      <div className="col-12 md:col-4">
        <InputText type="text" {...register("email")} />
      </div>
      <div className="col-6 md:col-2">
        <label htmlFor="input">Teléfono</label>
      </div>
      <div className="col-12 md:col-4">
        <Controller
          control={control}
          name="telephone"
          render={({ field }) => (
            <GenericInputPhone id="telephone" {...field} />
          )}
        />
      </div>
      <div className="col-6 md:col-2">
        <label htmlFor="input">Celular</label>
      </div>
      <div className="col-12 md:col-4">
        <Controller
          control={control}
          name="celphone"
          render={({ field }) => <GenericInputPhone id="celphone" {...field} />}
        />
      </div>
      {isFromCreate && (
        <>
          <div className="col-12 md:col-2">
            <label htmlFor="input">Sexo</label>
          </div>
          <div className="col-12 md:col-4">
            <Controller
              control={control}
              name="idGender"
              rules={{ required: true }}
              render={({ field }) => (
                <Dropdown
                  id="idGender"
                  optionLabel="name"
                  optionValue="id"
                  className={classNames({
                    "p-invalid": !!errors.idGender,
                  })}
                  placeholder="Sexo"
                  {...field}
                  options={genders}
                />
              )}
            />
          </div>
        </>
      )}
      <div className="col-12 md:col-2">
        <label htmlFor="input">Estado Civil</label>
      </div>
      <div className="col-12 md:col-4">
        <Controller
          control={control}
          name="idMaritalStatus"
          rules={{ required: true }}
          render={({ field }) => (
            <Dropdown
              id="idMaritalStatus"
              optionLabel="name"
              optionValue="id"
              placeholder="Estado"
              {...field}
              className={classNames({
                "p-invalid": !!errors.idMaritalStatus,
              })}
              options={maritalStatus}
            />
          )}
        />
      </div>
      {isFromCreate && (
        <>
          <div className="col-12 md:col-2">
            <label htmlFor="input">Fecha De Nacimiento</label>
          </div>
          <div className="col-12 md:col-4">
            <Controller
              control={control}
              name="birthDate"
              rules={{ required: true }}
              render={({ field }) => (
                <GenericCalendar
                  id="birthDate"
                  {...field}
                  className={classNames({
                    "p-invalid": !!errors.birthDate,
                  })}
                />
              )}
            />
          </div>
        </>
      )}
      <div className="col-12 md:col-2">
        <label htmlFor="input">Provincia</label>
      </div>
      <div className="col-12 md:col-4">
        <Controller
          control={control}
          name="idProvince"
          rules={{ required: true }}
          render={({ field }) => (
            <Dropdown
              id="idProvince"
              {...field}
              optionLabel="name"
              optionValue="id"
              className={classNames({
                "p-invalid": !!errors.idProvince,
              })}
              placeholder="Provincia"
              options={provinces}
            />
          )}
        />
      </div>
      {idProvince && (
        <div className="col-12 md:col-2">
          <label htmlFor="input">Cantón</label>
        </div>
      )}
      {idProvince && (
        <div className="col-12 md:col-4">
          <Controller
            control={control}
            name="idCounty"
            rules={{ required: true }}
            render={({ field }) => (
              <Dropdown
                id="idCounty"
                {...field}
                optionLabel="name"
                optionValue="id"
                className={classNames({
                  "p-invalid": !!errors.idCounty,
                })}
                placeholder="Cantón"
                options={counties}
              />
            )}
          />
        </div>
      )}
      {idCounty && (
        <div className="col-12 md:col-2">
          <label htmlFor="input">Distrito</label>
        </div>
      )}
      {idCounty && (
        <div className="col-12 md:col-4">
          <Controller
            control={control}
            name="idDistrict"
            rules={{ required: true }}
            render={({ field }) => (
              <Dropdown
                id="idDistrict"
                {...field}
                optionLabel="name"
                optionValue="id"
                className={classNames({
                  "p-invalid": !!errors.idDistrict,
                })}
                placeholder="Distrito"
                options={districts}
              />
            )}
          />
        </div>
      )}
      <div className="col-6 md:col-2">
        <label htmlFor="input">Dirección</label>
      </div>
      <div className="col-12 md:col-4">
        <InputText {...register("address")} type="text" />
      </div>
    </>
  );
};

export const AffiliateForm = ({ children, isFromCreate = false }) => {
  const [states, setStates] = useState([]);

  const { post } = useService();
  const {
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    post(
      "catalog/list",
      {
        catalogType: 1013,
      },
      ({ catalogs }) => {
        setStates(catalogs);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchPerson = () => {
    const data = {
      idCard: getValues("idCard"),
    };

    post("persons/get", data, ({ person }) => {
      if (!person) {
        showWarning("No se encontro ninguna persona con esa cedula");
        return;
      }

      setValue("firstName", person.firstName);
      setValue("lastName", person.lastName);
      setValue("idGender", person.genre.id);
      setValue("idProvince", person.division.id);
      setValue("idCounty", person.division.subDivision.id);
    });
  };

  return (
    <>
      <div className="col-12 md:col-2">
        <label htmlFor="input">Cédula</label>
      </div>
      <div className="col-12 md:col-4">
        <Controller
          control={control}
          name="idCard"
          rules={{ required: true }}
          render={({ field }) => (
            <GenericInputId
              id="idCard"
              {...field}
              className={classNames({
                "p-invalid": !!errors.idCard,
              })}
            />
          )}
        />
        {isFromCreate && (
          <Button type="button" label="Buscar" onClick={searchPerson} />
        )}
      </div>
      <div className="col-12 md:col-2">
        <label htmlFor="input">Nombre</label>
      </div>
      <div className="col-12 md:col-4">
        <InputText
          type="text"
          {...register("firstName", {
            required: true,
          })}
          className={classNames({
            "p-invalid": !!errors.firstName,
          })}
        />
      </div>
      <div className="col-12 md:col-2">
        <label htmlFor="input">Apellidos</label>
      </div>
      <div className="col-12 md:col-4">
        <InputText
          type="text"
          {...register("lastName", {
            required: true,
          })}
          className={classNames({
            "p-invalid": !!errors.lastName,
          })}
        />
      </div>
      <AffiliateOtherInformationForm isFromCreate={isFromCreate} />

      {!isFromCreate && (
        <>
          <div className="col-12 md:col-2">
            <label htmlFor="input">Estado</label>
          </div>
          <div className="col-12 md:col-4">
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <Dropdown
                  id="status"
                  {...field}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Estado"
                  options={states}
                />
              )}
            />
          </div>
        </>
      )}
      {children}
    </>
  );
};

export const AffiliateFormAndUpdate = ({ affiliate, goBack }) => {
  const { post } = useService();
  const formMethods = useForm();
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formMethods;

  const askToGoBack = () => {
    if (isDirty === true) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios sin guardar dentro de la página, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            goBack();
          },
        }
      );
    } else {
      goBack();
    }
  };

  useEffect(() => {
    reset({
      idCard: affiliate.idCard,
      firstName: affiliate.firstName,
      lastName: affiliate.lastName,
      idMaritalStatus: affiliate.maritalStatus?.id,
      telephone: affiliate.telephone,
      celphone: affiliate.celphone,
      status: affiliate.status?.id,
      email: affiliate.email,
      address: affiliate.address,
      idProvince: affiliate.division?.id,
      idCounty: affiliate.division?.subDivision?.id,
      idDistrict: affiliate.division?.subDivision?.subDivision?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliate]);

  const save = (formData) => {
    let division = {
      id: formData.idProvince,
      subDivision: {
        id: formData.idCounty,
        subDivision: {
          id: formData.idDistrict,
        },
      },
    };

    const request = {
      affiliate: {
        ...affiliate,
        idCard: formData.idCard,
        address: formData.address,
        division: division,
        maritalStatus: {
          id: formData.idMaritalStatus,
        },
        status: {
          id: formData.status,
        },
        email: formData.email,
        celphone: formData.celphone ? formData.celphone.replace("-", "") : null,
        telephone: formData.telephone
          ? formData.telephone.replace("-", "")
          : null,
        firstName: formData.firstName,
        lastName: formData.lastName,
      },
    };

    post("affiliate/update", request, () => {
      showSuccess("La información se actualizó correctamente");
      goBack(true);
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(save)} className="grid">
        <AffiliateForm isFromCreate={false}>
          <div className="md:col-12">
            <Button label="Guardar" />
            <Button type="button" label="Regresar" onClick={askToGoBack} />
          </div>
        </AffiliateForm>
      </form>
    </FormProvider>
  );
};
