import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import ActionToolbar from "../../components/ActionsToolbar";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import useService from "../../hooks/hook-service";
import { showWarning } from "../../utils/message";

const DocumentList = () => {
  const [document, setDocument] = useState({});
  const [documents, setDocuments] = useState([]);

  const location = useLocation();
  const { post } = useService();
  const actions = location.state.actions;
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    handleSubmit(search)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = (data) => {
    post("document/search", data, ({ documents }) => {
      if (documents.length === 0) {
        showWarning("No se encontraron registros");
      }
      setDocuments(documents);
      setDocument({});
    });
  };

  const downloadFile = () => {
    post("document/get", {
      id: document.id,
    }, ( data ) => {
      const linkSource = data.document.data;
      const downloadLink = window.document.createElement("a");
      const fileName = data.document.name;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  const disableAction = (actionId) => {
    return Object.getOwnPropertyNames(document).length === 0;
  };

  const select = (e) => {
    if (e.value) {
      setDocument(e.value);
    } else {
      setDocument({});
    }
  };

  const redirect = (route) => {
    switch (route) {
      case "/downloadDocument":
        downloadFile();
        break;
      default:
        break;
    }
  };
    return (
      <div className="p-fluid">
        <div className="grid">
          <div className="col-12">
            <div className="custom-card">
              <div className="custom-card-header-info">
                <h1>Documentos</h1>
              </div>
              <ActionToolbar
              actions={actions}
              checkIfHaveToDisable={disableAction}
              executeAction={redirect}
            />
              <div className="grid">
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Nombre</label>
                </div>
                <div className="col-12 md:col-4">
                <InputText type="text" {...register("name")} />
                </div>
                <div className="md:col-12">
                  <Button label="Buscar" onClick={handleSubmit(search)} />
                </div>
                  <div className="col-12">
                    <DataTable
                      value={documents}
                      paginator={true}
                      rows={10}
                      emptyMessage="No se encontraron registros"
                      selection={document}
                      onSelectionChange={select}
                    >
                      <Column selectionMode="single" style={{ width: "4em" }} />
                      <Column field="name" header="Nombre" />
                    </DataTable>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default DocumentList;