/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import GenericInputId from "../../components/GenericComponents/GenericInputId";
import { InputText } from "primereact/inputtext";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import AffiliateHeader from "../../components/AffiliateHeader";
import { Controller, useForm } from "react-hook-form";
import { showError, showSuccess } from "../../utils/message";
import { formatDate } from "../../utils/format";
import BeneficiaryDetails from "../../components/BeneficiaryDetails";

const CongressAddAffiliate = () => {
  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();
  const { register, handleSubmit, control } = useForm();

  const [affiliate, setAffiliate] = useState({});
  const [affiliates, setAffiliates] = useState([]);

  useEffect(() => {
    handleSubmit(search)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = (formData) => {
    const data = {
      idCard: formData.idCard,
      firstName: formData.firstName,
      lastName: formData.lastName,
      startNumber: formData.number,
      endNumber: formData.number,
    };

    post("affiliate/search", data, ({ affiliates }) => {
      if (affiliates.length === 0) {
        showError("No se encontraron registros");
      }
      setAffiliates(affiliates);
      setAffiliate({});
    });
  };

  const goBack = () => navigate(-1);

  const addAffiliateToCongress = () => {
    if (!affiliate?.id) {
      showError("Debe seleccionar un afiliado para incluirlo a la asamblea.");
      return;
    }
    const data = {
      congress: {
        id: location.state.congress.id,
      },
      affiliate: {
        id: affiliate.id,
      },
    };

    post("congress/affiliate", data, () => {
      showSuccess("Asociado agregado correctamente a la asamblea");
      setAffiliate({});
    });
  };

  const loadAffiliate = (id) => {
    const data = {
      id: id,
    };
    post("affiliate/get", data, ({ affiliate }) => setAffiliate(affiliate));
  };

  const actionButton = (rowData, column) => {
    return (
      <Button
        type="button"
        className="button-icon"
        icon="pi pi-plus"
        onClick={() => loadAffiliate(rowData.id)}
        tooltip="Seleccionar"
      />
    );
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Agregar asociado a una asamblea</h1>
            </div>
            <div className="spacer20" />
            <div className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">Número de contrato</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="number" {...register("number")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Número de Cédula</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="idCard"
                  render={({ field }) => (
                    <GenericInputId id="idCard" {...field} />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Nombre</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("firstName")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Apellidos</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("lastName")} />
              </div>
              <div className="col-12 md:col-4">
                <Button
                  type="button"
                  label="Buscar"
                  onClick={handleSubmit(search)}
                />
              </div>
            </div>

            {affiliate.id && (
              <>
                <h3>Asociado</h3>
                <div className="grid">
                  <AffiliateHeader affiliate={affiliate} />
                </div>
                <div className="spacer20" />
                <BeneficiaryDetails beneficiaries={affiliate.beneficiaries} />
              </>
            )}

            <div className="spacer20" />
            <h3>Listado de Asociados</h3>
            <DataTable
              paginator={true}
              rows={10}
              value={affiliates}
              emptyMessage="No se encontraron registros"
            >
              <Column field="idCard" header="Número de cédula" />
              <Column field="firstName" header="Nombre" />
              <Column field="lastName" header="Apellidos" />
              <Column
                body={(rowData) => formatDate(rowData.payDate)}
                header="Fecha del siguiente pago"
              />
              <Column field="lateQuotas" header="Cuotas pendientes" />
              <Column body={actionButton} header="Seleccionar" />
            </DataTable>

            <div className="spacer20" />
            <Button
              type="button"
              label="Agregar"
              onClick={addAffiliateToCongress}
            />
            <Button type="button" label="Regresar" onClick={goBack} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CongressAddAffiliate;
