const moment = require("moment");

module.exports.formatHour = (time) => {
  if (!time) {
    return null;
  }

  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};

module.exports.formatDecimal = (num) => {
  return Math.round(num * 100) / 100;
};

module.exports.formatCurrencyValue = (value) => {
  const num = this.formatDecimal(value ?? 0);
  return new Intl.NumberFormat("es-CR", {
    style: "currency",
    currency: "CRC",
  }).format(num);
};

module.exports.formatDate = (date, format) => {
  if (date !== null) {
    return moment.utc(new Date(date))
      .format(format ?? "DD-MM-YYYY")
      .toString();
  } else {
    return "";
  }
};

module.exports.editDate = (date) => {
  if (!date) return null;

  const dateTime = new Date(date);
  let d = dateTime.getDay();
  let m = dateTime.getMonth() + 1;
  if (d <= 9) {
    d = "0" + d;
  }
  if (m <= 9) {
    m = "0" + m;
  }
  return d + "/" + m + "/" + dateTime.getFullYear();
};

module.exports.convertStringToDate = (date) => {
  if (!date || date === "") return null;
  return new Date(
    date.replace(/\//g, "-").replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
  );
};
