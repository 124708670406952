import React, { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import { showSuccess, showWarningDialog } from "../../utils/message";

const ContractUploadFile = () => {
  const [document, setDocument] = useState();

  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();

  const handleSelectedFile = (event) => {
    const nameFile = event.target.files[0].name;
    const typeFile = event.target.files[0].type;
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      setDocument({
        name: nameFile,
        type: typeFile,
        data: event.target.result,
      });
    };
    reader.readAsDataURL(file);
  };

  const askToGoBack = () => {
    if (document) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios sin guardar dentro de la página, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            navigate(-1);
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  const saveDocument = () => {
    const request = {
      contract: {
        id: location.state.contract.id,
      },
      document,
    };

    post("contract/insertDocument", request, () => {
      showSuccess("Se agrego correctamente el documento");
      navigate(-1);
    });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Agregar Archivo al Contrato</h1>
            </div>
            <div className="spacer20" />
            <div className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">Archivo De Contrato</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="file"
                  required={true}
                  onChange={handleSelectedFile}
                />
              </div>
              <div className="md:col-12">
                <Button
                  label="Guardar"
                  onClick={saveDocument}
                  disabled={!document}
                />
                <Button type="button" label="Regresar" onClick={askToGoBack} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractUploadFile;
