import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import React from "react";

const ActionToolbar = ({ actions, checkIfHaveToDisable, executeAction }) => {
  const disableAction = ({ isDependentOfElement, actionId }) =>
    isDependentOfElement &&
    checkIfHaveToDisable &&
    checkIfHaveToDisable(actionId);

  const startContent = actions.map((prop, key) => (
    <Button
      label={prop.name}
      className="toolbar-button"
      onClick={() => executeAction(prop.route)}
      key={key}
      icon={prop.icon}
      disabled={disableAction(prop)}
    />
  ));
  return (
    <div className="col-12">
      <div className="spacer20" />
      <Toolbar start={<div> {startContent} </div>} />
      <div className="spacer10" />
    </div>
  );
};

export default ActionToolbar;
