/*
 * Copyright (C) Jean Carlos Berrocal M. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Jean Carlos Berrocal <berrocal13@gmail.com>
 */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import { showSuccess } from "../../utils/message";
import ContractTypeAndBeneficiaries from "../../components/ContractTypeAndBeneficiaries";
import { FormProvider, useForm } from "react-hook-form";

const ContractEdit = () => {
  const [contract, setContract] = useState({});

  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();
  const formMethods = useForm();

  useEffect(() => {
    const request = {
      id: location.state.contract.id,
    };

    post("contract/get", request, ({ contract }) => {
      setContract(contract);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const saveContract = (formData) => {
    const data = {
      id: contract.id,
      type: {
        id: formData.contractType,
      },
      beneficiaries: formData.beneficiaries,
    };

    post("contract/update", data, () => {
      showSuccess("El contracto se ha actualizado correctamente");
      navigate(-1);
    });
  };


  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Editar Contrato</h1>
            </div>
            <div className="spacer20" />
            <div className="grid">
              <div className="md:col-4">
                <label htmlFor="input">
                  Número de Contrato: {contract?.number || ""}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Tipo de Contrato: {contract?.type?.name || ""}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Estado de Contrato: {contract?.status?.name || ""}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Cédula del Asociado: {contract?.affiliate?.idCard || ""}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Nombre del Asociado: {contract?.affiliate?.firstName || ""}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Apellidos del Asociado: {contract?.affiliate?.lastName || ""}
                </label>
              </div>
              <div className="spacer20" />
              <FormProvider {...formMethods}>
                <form className="grid md:col-12">
                  <ContractTypeAndBeneficiaries label="Nuevo Tipo de Contrato" onSave={saveContract} onBack={goBack} contract={contract} />
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractEdit;
