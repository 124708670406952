/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { formatCurrencyValue, formatDate } from "../../utils/format";
import { Checkbox } from "primereact/checkbox";
import Voucher from "../../components/Vouchers/PaymentVoucher";
import AffiliateHeader from "../../components/AffiliateHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { showError, showSuccess, showWarningDialog } from "../../utils/message";
import useService from "../../hooks/hook-service";
import WayToPay from "../../components/WayToPayForm";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { TabPanel, TabView } from "primereact/tabview";
import ReceiptDetails from "../../components/ReceiptDetails";
import WayToPayDetails from "../../components/WayToPayDetails";
import { classNames } from "primereact/utils";
import AdvanceServiceInvoiceHeader from "../../components/AdvanceServiceInvoice/AdvanceServiceInvoiceHeader";

const PayingQuota = ({ calculatingPayment, affiliate, paymentChanged }) => {
  const [hasExtraordinaryAmount, setHasExtraordinaryAmount] = useState(false);

  const {
    register,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (affiliate.id) {
      reset({
        quotasToPay: affiliate.lateQuotas === 0 ? 1 : affiliate.lateQuotas,
      });
      calculatingPayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliate]);

  const isApplyPaymentArrangement = watch("isApplyPaymentArrangement");
  const quotasToPay = watch("quotasToPay");
  const extraordinaryAmount = watch("extraordinaryAmount");
  const applyPenaltyFee = watch("applyPenaltyFee");

  useEffect(() => {
    if (quotasToPay !== affiliate.lateQuotas) {
      setValue("quotasToPay", affiliate.lateQuotas);
    } else {
      paymentChanged();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyPenaltyFee]);

  useEffect(() => {
    paymentChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotasToPay, extraordinaryAmount, isApplyPaymentArrangement]);

  return (
    <>
      <div className="col-12 md:col-2">
        <label htmlFor="input">Cuotas por pagar</label>
      </div>
      <div className="col-12 md:col-4">
        <InputText
          type="number"
          step="1"
          min="0"
          disabled={applyPenaltyFee}
          {...register("quotasToPay", {
            required: true,
          })}
          className={classNames({
            "p-invalid": !!errors.quotasToPay,
          })}
          onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
        />
        <Button type="button" label="Calcular" onClick={calculatingPayment} />
      </div>
      <div className="col-12 md:col-2">
        <label htmlFor="input">¿Desea aplicar arreglo de pago?</label>
      </div>
      <div className="col-12 md:col-4">
        <Checkbox
          onChange={(e) => setValue("isApplyPaymentArrangement", e.checked)}
          checked={isApplyPaymentArrangement}
        />
      </div>
      <div className="col-12 md:col-2">
        <label htmlFor="input">Agregar monto extraordinario</label>
      </div>
      <div className="col-12 md:col-4">
        <Checkbox
          onChange={(e) => setHasExtraordinaryAmount(e.checked)}
          checked={hasExtraordinaryAmount}
        />
      </div>
      {hasExtraordinaryAmount && (
        <>
          <div className="col-12 md:col-2">
            <label htmlFor="input">Monto extraordinario</label>
          </div>
          <div className="col-12 md:col-4">
            <InputText
              type="number"
              {...register("extraordinaryAmount")}
              step="1"
              min="0"
            />
          </div>
        </>
      )}
      {affiliate.lateQuotas >= 6 && (
        <>
          <div className="col-12 md:col-2">
            <label htmlFor="input">Cuota de Penalización</label>
          </div>
          <div className="col-12 md:col-4">
            <Checkbox
              onChange={(e) => setValue("applyPenaltyFee", e.checked)}
              checked={applyPenaltyFee}
            />
          </div>
        </>
      )}
      <div className="col-12 md:col-2">
        <label htmlFor="input">Recibido</label>
      </div>
      <div className="col-12 md:col-4">
        <InputText
          {...register("appliedBy")}
        />
      </div>
    </>
  );
};

const PayingAmount = ({ calculatingPayment, paymentChanged }) => {
  const {
    register,
    reset,
    watch,
    formState: { errors },
  } = useFormContext();

  const extraordinaryAmount = watch("extraordinaryAmount");

  useEffect(() => {
    paymentChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraordinaryAmount]);

  useEffect(() => {
    reset({
      quotasToPay: 0,
      isApplyPaymentArrangement: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="col-12 md:col-2">
        <label htmlFor="input">Monto a Pagar</label>
      </div>
      <div className="col-12 md:col-4">
        <InputText
          type="number"
          {...register("extraordinaryAmount", {
            required: true,
          })}
          className={classNames({
            "p-invalid": !!errors.extraordinaryAmount,
          })}
          step="1"
          min="0"
        />
        <Button type="button" label="Calcular" onClick={calculatingPayment} />
      </div>
    </>
  );
};

const HeaderForNotAffiliate = ({ affiliate }) => {
  return (
    <>
      <div className="md:col-4">
        <label htmlFor="input">Cédula: {affiliate.idCard}</label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">Nombre: {affiliate.firstName}</label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">Apellidos: {affiliate.lastName}</label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Fecha de proximo pago: {formatDate(affiliate.payDate)}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Ultima fecha de pago: {formatDate(affiliate.lastFeeDate)}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">Teléfono: {affiliate.telephone}</label>
      </div>
      {affiliate.email && (
        <div className="md:col-4">
          <label htmlFor="input">Correo Electrónico: {affiliate.email}</label>
        </div>
      )}
      {affiliate.celphone && (
        <div className="md:col-4">
          <label htmlFor="input">Celular {affiliate.celphone}</label>
        </div>
      )}
    </>
  );
};

export const RECEIPT_CONFIG = {
  NORMAL: {
    title: "Recibo de Pago de Cuotas",
    isPayingQuota: true,
    type: "NORMAL",
    urlCalcule: "paymentReceived/calculePay",
    urlPay: "paymentReceived/pay",
    goBack: -2,
  },
  INVOICE_RECEIVALBLE: {
    title: "Recibo por Factura por Cobrar",
    isPayingQuota: false,
    type: "INVOICE_RECEIVALBLE",
    urlCalcule: "bill/calculePay",
    urlPay: "bill/pay",
    goBack: -2,
  },
  ADVANCE_INVOICE: {
    title: "Abono por Servico Funerario",
    isPayingQuota: false,
    type: "ADVANCE_INVOICE",
    urlCalcule: "paymentReceived/calculePay",
    urlPay: "paymentReceived/pay",
    goBack: -1,
  },
};

const ReceiptPay = () => {
  const auth = useSelector((state) => state.authManager.value);

  const [catalogPaymentType, setCatalogPaymentType] = useState([]);
  const [receiptTem, setReceiptTem] = useState({});
  const [affiliate, setAffiliate] = useState({});
  const [payment, setPayment] = useState({});
  const [waysToPay, setWaysToPay] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const config = location.state.config;
  const formMethodsForWayToPay = useForm();
  const formMethodsForCalcule = useForm();
  const { post, manyPost } = useService();

  useEffect(() => {
    const request = [
      {
        url: "affiliate/get",
        body: {
          id: location.state.affiliate.id,
        },
      },
      {
        url: "catalog/list",
        body: { catalogType: "1004" },
      },
    ];

    manyPost(request, (result) => {
      setAffiliate(result[0].affiliate);
      setCatalogPaymentType(result[1].catalogs);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (affiliate.savingAmount > 0) {
      showWarningDialog(
        {
          title: "Usar Ahorros",
          text: `El usuario seleccionado cuenta con ${formatCurrencyValue(
            affiliate.savingAmount
          )} ahorros disponibles ¿Desea usarlos?`,
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            const pay = {
              referenceNumber: "",
              transferType: {
                id: 75,
              },
              amount: affiliate.savingAmount,
            };
            waysToPay.push(pay);
            setWaysToPay([...waysToPay]);
          },
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliate]);

  const makeCalculatingPayment = () => {
    formMethodsForCalcule.handleSubmit(
      (formData) => {
        const request = {
          isApplyPaymentArrangement: formData.isApplyPaymentArrangement,
          affiliate,
          quotasToPay: formData.quotasToPay,
          extraordinaryAmount: formData.extraordinaryAmount,
          applyPenaltyFee: formData.applyPenaltyFee
        };

        if (
          config.type === "INVOICE_RECEIVALBLE" &&
          location.state.affiliate.number
        ) {
          request.number = location.state.affiliate.number;
        }

        if (
          config.type === "ADVANCE_INVOICE" &&
          location.state.paymentArrangement
        ) {
          request.paymentArrangement = location.state.paymentArrangement;
        }

        post(config.urlCalcule, request, ({ paymentReceived }) => {
          formMethodsForWayToPay.setValue("amount", paymentReceived.paidAmount);
          setReceiptTem(paymentReceived);
        });
      },
      () => showError("Debe completar los campos")
    )();
  };

  const addWayToPay = (formData) => {
    if (formData.amount && formData.transferType) {
      const transferType = catalogPaymentType.find(
        (x) => x.id === formData.transferType
      );

      const pay = {
        referenceNumber: formData.referenceNumber,
        transferType: transferType,
        amount: formData.amount,
      };
      waysToPay.push(pay);
      setWaysToPay([...waysToPay]);
      formMethodsForWayToPay.reset({
        amount: 0,
        transferType: 18,
        referenceNumber: "",
      });
    } else {
      showError("Debe seleccionar un tipo de transferencia y agregar un monto");
    }
  };

  const generateReceipt = () => {
    formMethodsForCalcule.handleSubmit(
      (formData) => {
        if (waysToPay.length === 0) {
          showError("Debe incluir al menos un método de pago.");
          return;
        }
        const request = {
          pay: {
            waysToPay: waysToPay,
            quotasToPay: formData.quotasToPay,
            affiliate: affiliate,
            isApplyPaymentArrangement:
              formData.isApplyPaymentArrangement ?? false,
            extraordinaryAmount: formData.extraordinaryAmount ?? 0,
            applyPenaltyFee: formData.applyPenaltyFee,
            appliedBy: formData.appliedBy
          },
        };

        if (
          config.type === "INVOICE_RECEIVALBLE" &&
          location.state.affiliate.number
        ) {
          request.number = location.state.affiliate.number;
        }

        if (
          config.type === "ADVANCE_INVOICE" &&
          location.state.paymentArrangement
        ) {
          request.pay.paymentArrangement = location.state.paymentArrangement;
        }

        const amountReceived = waysToPay?.reduce(
          (total, paie) => total + paie.amount
        ).amount;

        const amount = receiptTem.details?.reduce(
          (total, tem) => total + tem.totalPrice
        ).totalPrice;

        if (amount + request.pay.extraordinaryAmount < amountReceived) {
          showWarningDialog(
            {
              title: "Precaución",
              text: `¿El monto recibido es mayor al monto a pagar, está seguro(a) de que desea aplicar el recibo?`,
              confirmButtonText: "Si",
              cancelButtonText: "No",
            },
            {
              confirm: () => {
                buildPayment(request);
              },
            }
          );
        } else {
          buildPayment(request);
        }
      },
      () => showError("Debe completar los campos")
    )();
  };

  const buildPayment = (request) => {
    post(config.urlPay, request, ({ paymentReceived }) => {
      showSuccess("El pago se realizó correctamente");
      setPayment(paymentReceived);
    });
  };

  const goToBack = () => {
    if (
      Object.getOwnPropertyNames(receiptTem).length !== 0 ||
      waysToPay.length > 0
    ) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios sin guardar dentro de la página, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            navigate(-1);
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  const onHideResult = () => navigate(config.goBack);

  const actionTemplate = (rowData, column) => {
    return (
      <Button
        type="button"
        className="p-button-danger button-icon"
        icon="pi pi-trash"
        onClick={() => deletePay(column.rowIndex)}
      />
    );
  };

  const paymentUpdated = () => {
    setReceiptTem({});
  }

  const deletePay = (rowIndex) => {
    waysToPay.splice(rowIndex, 1);
    setWaysToPay([...waysToPay]);
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-create">
              <h1>{config.title}</h1>
            </div>
            <div className="spacer20" />
            <Dialog
              header="Información"
              modal={true}
              onHide={onHideResult}
              visible={Object.getOwnPropertyNames(payment).length !== 0}
            >
              <TabView activeIndex={0}>
                <TabPanel header="Recibo">
                  <Voucher title={config.title} payment={payment} />
                </TabPanel>
              </TabView>
            </Dialog>
            <div className="grid">
              {config.type === "NORMAL" && (
                <AffiliateHeader affiliate={affiliate} />
              )}
              {config.type === "INVOICE_RECEIVALBLE" && (
                <HeaderForNotAffiliate affiliate={affiliate} />
              )}
              {config.type === "ADVANCE_INVOICE" && (
                <AdvanceServiceInvoiceHeader
                  reserveBill={location.state.reserveBill}
                />
              )}
            </div>
            <div className="spacer20" />
            <div className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">Cédula</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="text"
                  value={affiliate.idCard || ""}
                  readOnly
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Nombre de afiliado</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="text"
                  value={affiliate.firstName + " " + affiliate.lastName}
                  readOnly
                />
              </div>
              <div className="spacer10" />
              <FormProvider {...formMethodsForWayToPay}>
                <WayToPay
                  addButton={
                    <Button
                      type="button"
                      label="Agregar forma de pago"
                      onClick={formMethodsForWayToPay.handleSubmit(addWayToPay)}
                    />
                  }
                />
              </FormProvider>
              <FormProvider {...formMethodsForCalcule}>
                {config.isPayingQuota && (
                  <PayingQuota
                    calculatingPayment={makeCalculatingPayment}
                    affiliate={affiliate}
                    paymentChanged={paymentUpdated}
                  />
                )}
                {!config.isPayingQuota && (
                  <PayingAmount calculatingPayment={makeCalculatingPayment} paymentChanged={paymentUpdated} />
                )}
              </FormProvider>
            </div>
            <div className="spacer20" />
            {receiptTem.paidAmount && (
              <div>
                <h2>Información </h2>
                <h2>Recibo de pago de cuotas</h2>
                {receiptTem.affiliate && (
                  <>
                    <div className="grid">
                      <div className="col-12">
                        <label htmlFor="input">Fecha</label>{" "}
                        {formatDate(new Date())}
                      </div>
                      <div className="col-12">
                        <label htmlFor="input">Recibo Realizado por</label>{" "}
                        {auth.user.name + " " + auth.user.lastName}
                      </div>
                      <div className="col-12 md:col-2">
                        <label htmlFor="input">RECIBIMOS DE</label>
                      </div>
                      <div className="col-12 md:col-2">
                        {receiptTem.affiliate.lastName}{" "}
                        {receiptTem.affiliate.firstName}
                      </div>
                      {receiptTem.affiliate.number !== 0 && (
                        <div className="col-12 md:col-2">
                          <label htmlFor="input">ASOCIADO #</label>
                          {receiptTem.affiliate.number}
                        </div>
                      )}
                      <div className="col-12 md:col-2">
                        <label htmlFor="input">RECIBO #</label>{" "}
                        {receiptTem.number + 1}
                      </div>
                      <div className="col-12 md:col-2">
                        <label htmlFor="input">CEDULA ASOCIADO </label>{" "}
                        {receiptTem.affiliate.idCard}
                      </div>
                    </div>
                    <ReceiptDetails details={receiptTem.details} />
                  </>
                )}
              </div>
            )}
            <div className="spacer20" />
            <WayToPayDetails title="Pagos" waysToPay={waysToPay}>
              <Column body={actionTemplate} header="Acciones" />
            </WayToPayDetails>
            <div className="md:col-12">
              <Button label="Pagar" onClick={generateReceipt} disabled={!receiptTem.paidAmount} />
              <Button type="button" label="Regresar" onClick={goToBack} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiptPay;
