import { createSlice } from "@reduxjs/toolkit";
import { deleteAuth, getAuth, setAuth } from "../utils/security";

// auth Manager Slice

const authManagerSlice = createSlice({
  name: "authManager",
  initialState: {
    value: getAuth(),
  },
  reducers: {
    setAuthentication: (state, action) => {
      state.value = action.payload;
      setAuth(action.payload);
    },
    removeAuthentication: (state) => {
      state.value = null;
      deleteAuth();
    },
  },
});

export const { removeAuthentication, setAuthentication } =
  authManagerSlice.actions;

export default authManagerSlice.reducer;
