import { InputText } from "primereact/inputtext";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import GenericCalendar from "../../components/GenericComponents/GenericCalendar";
import GenericInputId from "../../components/GenericComponents/GenericInputId";
import GenericInputPhone from "../../components/GenericComponents/GenericInputPhone";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { showWarningDialog } from "../../utils/message";
import { useNavigate } from "react-router-dom";

const UserForm = ({
  headerClass,
  title,
  titleButton,
  isCreatedForm,
  actionToExecute,
  user,
}) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm();

  useEffect(() => {
    reset(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const goBackToHome = () => {
    if (isDirty === true) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios sin guardar dentro de la página, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            navigate(-1);
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  const status = [
    { label: "Activo", value: 1 },
    { label: "Inactivo", value: 0 },
  ];

  return (
    <>
      <div className={headerClass}>
        <h1>{title}</h1>
      </div>
      <div className="spacer20" />
      <form onSubmit={handleSubmit(actionToExecute)} className="col-12">
        <div className="grid">
          <label className="col-12 mb-2 md:col-2 md:mb-0">Nombre</label>
          <div className="col-12 md:col-4">
            <InputText type="text" {...register("name")} />
          </div>
          <label className="col-12 mb-2 md:col-2 md:mb-0">Apellidos</label>
          <div className="col-12 md:col-4">
            <InputText type="text" {...register("lastName")} />
          </div>
          <label className="col-12 mb-2 md:col-2 md:mb-0">Cédula</label>
          <div className="col-12 md:col-4">
            <Controller
              control={control}
              name="idCard"
              render={({ field }) => <GenericInputId id="idCard" {...field} />}
            />
          </div>
          <label className="col-12 mb-2 md:col-2 md:mb-0">
            Fecha de nacimiento
          </label>
          <div className="col-12 md:col-4">
            <Controller
              control={control}
              name="birthDate"
              render={({ field }) => (
                <GenericCalendar id="birthDate" {...field} />
              )}
            />
          </div>
          <label className="col-12 mb-2 md:col-2 md:mb-0">
            Nombre de usuario
          </label>
          <div className="col-12 md:col-4">
            <InputText type="text" {...register("username")} />
          </div>
          {isCreatedForm && (
            <>
              <label className="col-12 mb-2 md:col-2 md:mb-0">Contraseña</label>
              <div className="col-12 md:col-4">
                <InputText type="password" {...register("password")} />
              </div>
            </>
          )}
          <label className="col-12 mb-2 md:col-2 md:mb-0">
            Número de teléfono
          </label>
          <div className="col-12 md:col-4">
            <Controller
              control={control}
              name="phone"
              render={({ field }) => (
                <GenericInputPhone id="phone" {...field} />
              )}
            />
          </div>
          <label className="col-12 mb-2 md:col-2 md:mb-0">
            Correo electrónico
          </label>
          <div className="col-12 md:col-4">
            <InputText type="text" {...register("email")} />
          </div>
          {isCreatedForm && (
            <>
              <label className="col-12 mb-2 md:col-2 md:mb-0">Estado</label>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => (
                    <Dropdown
                      id="status"
                      {...field}
                      placeholder="Estado"
                      options={status}
                    />
                  )}
                />
              </div>
            </>
          )}
        </div>
        <div className="md:col-12">
          <Button label={titleButton} />
          <Button type="button" label="Regresar" onClick={goBackToHome} />
        </div>
      </form>
    </>
  );
};

export default UserForm;
