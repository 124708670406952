/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React from "react";
import { useNavigate } from "react-router-dom";
import ServiceForm from "./ServiceForm";
import { showSuccess } from "../../utils/message";
import useService from "../../hooks/hook-service";

const ServiceCreate = () => {
    const { post } = useService();
    const navigate = useNavigate();

    const createService = (service) => {
        post("service/insert", { service }, () => {
            showSuccess("El servicio se creó correctamente");
            navigate(-1);
        });
    };

    return (
        <div className="p-fluid">
            <div className="grid">
                <div className="col-12">
                    <div className="custom-card">
                        <ServiceForm
                            headerClass="custom-card-header-create"
                            title="Crear Servicio"
                            titleButton="Guardar"
                            actionToExecute={createService}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceCreate;
