/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import BillToPrint from "../../components/Vouchers/BillToPrint";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import BillHeader from "../../components/BillHeader";

const BillDetail = () => {
  const [bill, setBill] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const { post } = useService();

  useEffect(() => {
    const request = {
      id: location.state.bill.id,
    };

    post("bill/get", request, ({ bill }) => {
      setBill(bill);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Detalle de la Factura</h1>
            </div>
            <div className="spacer20" />
            <div className="grid">
              <BillHeader bill={bill} />

              {bill?.items?.length > 0 && (
                <div className="md:col-12">
                  <h2>Articulos de Inventario</h2>
                  <DataTable
                    paginator={true}
                    rows={10}
                    value={bill.items}
                    emptyMessage="No se encontraron registros"
                  >
                    <Column field="code" header="Codigo" />
                    <Column field="description" header="Descripción" />
                  </DataTable>
                </div>
              )}
            </div>
            <div className="spacer20" />
            <BillToPrint previewComponent={false} bill={bill} />
            <Button
              type="button"
              label="Regresar"
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillDetail;
