/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { showSuccess } from "../../utils/message";
import RoleForm from "./RoleForm";
import useService from "../../hooks/hook-service";

const RoleEdit = () => {
  const [allPermissions, setAllPermissions] = useState([]);
  const [role, setRole] = useState({});
  const navigate = useNavigate();
  const { post, manyPost } = useService();
  const location = useLocation();

  useEffect(() => {
    const requests = [
      {
        url: "product/permissions",
        body: {},
      },
      {
        url: "role/get",
        body: {
          id: location.state.role.id,
        },
      },
    ];
    manyPost(requests, (result) => {
      setAllPermissions(result[0].products);
      setRole(result[1].role);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editRole = (formData) => {
    const data = {
      role: {
        id: location.state.role.id,
        ...formData,
      },
    };

    post("role/update", data, () => {
      showSuccess("El rol se ha actualizado correctamente");
      navigate(-1);
    });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <RoleForm
              headerClass="custom-card-header-edit"
              title="Editar Rol"
              titleButton="Editar"
              actionToExecute={editRole}
              allPermissions={allPermissions}
              role={role}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleEdit;
