/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import GenericInputPhone from "../../components/GenericComponents/GenericInputPhone";
import GenericInputId from "../../components/GenericComponents/GenericInputId";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import ServiceEdit from "../../components/Services/ServiceEdit";
import { Controller, useForm } from "react-hook-form";
import { showError, showSuccess, showWarningDialog } from "../../utils/message";
import { classNames } from "primereact/utils";
import { formatCurrencyValue } from "../../utils/format";

const AdvanceServiceInvoiceCreate = () => {
  const navigate = useNavigate();
  const { post } = useService();
  const {
    register,
    handleSubmit,
    control,
    formState: { isDirty, errors },
  } = useForm();

  const [servicesAdded, setServicesAdded] = useState([]);
  const [service, setService] = useState();
  const [subtotal, setSubtotal] = useState(0);
  const [services, setServices] = useState([]);

  useEffect(() => {
    post(
      "service/search",
      {
        code: null,
        name: null,
        startPrice: null,
        endPrice: null,
        state: null,
        isAffiliate: false,
      },
      ({ services }) => {
        setServices(services);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHideEditingService = () => {
    setService();
  };

  const askIfDesireAddService = (service) => {
    setService(service);
  };

  const addService = (service) => {
    const ser = {
      service: service,
      price: service.price,
      quantity: 1,
      tax: 0,
      total: service.price,
    };
    servicesAdded.push(ser);
    let subtotal = 0;
    servicesAdded.forEach((element) => {
      const price = parseInt(element.service.price);
      subtotal += price;
    });
    setSubtotal(subtotal);
    setServicesAdded([...servicesAdded]);
  };

  const onError = (errors) => {
    showError("Debe completar los campos");
  };

  const onSubmit = (formData) => {
    if (servicesAdded.length === 0) {
      showError("Debe seleccionar al menos un servicio");
      return;
    }

    const data = {
      reserveBill: {
        client: {
          id: formData.idCard,
          name: formData.name,
          email: formData.email,
          address: formData.address,
          phone: formData.phone.replace("-", ""),
        },
        subtotal: subtotal,
        details: servicesAdded,
        observations: formData.observations,
      },
    };

    post("reserveBill/create", data, () => {
      showSuccess("Se creo correctamente el apartado de servicio");
      navigate(-1);
    });
  };

  const askToGoBack = () => {
    if (isDirty === true || servicesAdded.length > 0) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios en el edición de un beneficiario, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            navigate(-1);
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  const deleteService = (index) => {
    servicesAdded.splice(index, 1);
    let subtotal = 0;
    servicesAdded.forEach((element) => {
      const price = parseInt(element.price);
      subtotal += price;
    });
    setSubtotal(subtotal);
    setServicesAdded([...servicesAdded]);
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-create">
              <h1>Crear apartado de servicio funebre</h1>
            </div>
            <div className="spacer20" />
            <ServiceEdit
              service={service}
              onClose={onHideEditingService}
              onUpdate={addService}
            />
            <form onSubmit={handleSubmit(onSubmit, onError)} className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">Cedula del Cliente</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="idCard"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <GenericInputId
                      id="idCard"
                      {...field}
                      className={classNames({
                        "p-invalid": !!errors.idCard,
                      })}
                    />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Nombre del Cliente</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="text"
                  {...register("name", {
                    required: true,
                  })}
                  className={classNames({
                    "p-invalid": !!errors.name,
                  })}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Correo del Cliente</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="email" {...register("email")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Dirección del Cliente</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="text"
                  r
                  {...register("address", {
                    required: true,
                  })}
                  className={classNames({
                    "p-invalid": !!errors.address,
                  })}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Teléfono del Cliente</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="phone"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <GenericInputPhone
                      id="phone"
                      {...field}
                      className={classNames({
                        "p-invalid": !!errors.phone,
                      })}
                    />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Observaciones</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("observations")} />
              </div>
              <div className="col-12" />
              <div className="col-6">Seleccione los servicios</div>
              <div className="col-6">
                SubTotal: {formatCurrencyValue(subtotal)}
              </div>
              <DataTable
                className="col-12"
                paginator={true}
                rows={10}
                value={services}
                emptyMessage="No se encontraron registros"
              >
                <Column field="code" header="Codigo" />
                <Column field="name" header="Nombre" />
                <Column field="description" header="Descripción" />
                <Column
                  body={(rowData) => formatCurrencyValue(rowData.price)}
                  header="Precio"
                />
                <Column
                  body={(rowData) => (
                    <Button
                      type="button"
                      icon="pi pi-plus"
                      className="button-icon"
                      onClick={() => askIfDesireAddService(rowData)}
                      tooltip="Seleccionar"
                    />
                  )}
                  header="Agregar Servicio"
                />
              </DataTable>
              {servicesAdded.length > 0 && (
                <div className="col-12">
                  Servicios agregados
                  <DataTable
                    paginator={true}
                    rows={10}
                    value={servicesAdded}
                    emptyMessage="No se encontraron registros"
                  >
                    <Column field="service.code" header="Codigo" />
                    <Column field="service.name" header="Nombre" />
                    <Column field="service.description" header="Descripción" />
                    <Column
                      body={(rowData) =>
                        formatCurrencyValue(rowData.service.price)
                      }
                      header="Precio"
                    />
                    <Column
                      body={(rowData, column) => (
                        <Button
                          type="button"
                          icon="pi pi-trash"
                          className="p-button-danger button-icon"
                          onClick={() => deleteService(column.rowIndex)}
                          tooltip="Eliminar"
                        />
                      )}
                      header="Eliminar Servicio"
                    />
                  </DataTable>
                </div>
              )}
              <div className="md:col-12">
                <Button label="Guardar" />
                <Button type="button" label="Regresar" onClick={askToGoBack} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvanceServiceInvoiceCreate;
