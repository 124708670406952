/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import AdvanceServiceInvoiceHeader from "../../components/AdvanceServiceInvoice/AdvanceServiceInvoiceHeader";

const AdvanceServiceInvoiceDetail = () => {
  const [reserveBill, setReserveBill] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const { post } = useService();

  useEffect(() => {
    const request = {
      id: location.state.reserveBill?.id,
    };

    post("reserveBill/get", request, ({ reserveBill }) => {
      setReserveBill(reserveBill);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Detalle de la Factura</h1>
            </div>
            <div className="spacer20" />
            <div className="grid">
              <AdvanceServiceInvoiceHeader reserveBill={reserveBill} />
            </div>
            <div className="spacer20" />
            <Button
              type="button"
              label="Regresar"
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvanceServiceInvoiceDetail;
