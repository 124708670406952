import { Button } from "primereact/button";
import React, { forwardRef, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import BeneficiaryDetails from "../BeneficiaryDetails";
const ComponentToPrint = forwardRef(({ contract, contractType }, ref) => {
  return (
    <div ref={ref}>
      <h1>Contrato</h1>
      <div className="spacer10" />
      {contract?.affiliate && (
        <>
          <div className="grid">
            <div className="col-4 md:col-2">
              <label htmlFor="input">Número de Contrato:</label>
            </div>
            <div className="col-8 md:col-2">{contract.number}</div>
            <div className="col-4 md:col-2">
              <label htmlFor="input">Tipo de Contrato:</label>
            </div>
            <div className="col-8 md:col-2">{contract.type.name}</div>
            <div className="col-4 md:col-2">
              <label htmlFor="input">Cedula:</label>
            </div>
            <div className="col-8 md:col-2">{contract.affiliate.idCard}</div>
            <div className="col-4 md:col-2">
              <label htmlFor="input">Nombre:</label>
            </div>
            <div className="col-8 md:col-2">
              {contract.affiliate.firstName} {contract.affiliate.lastName}
            </div>
            <div className="col-4 md:col-2">
              <label htmlFor="input">Sexo:</label>
            </div>
            <div className="col-8 md:col-2">
              {contract.affiliate.genre.name}
            </div>
            <div className="col-4 md:col-2">
              <label htmlFor="input">Estado Civil:</label>
            </div>
            <div className="col-8 md:col-2">
              {contract.affiliate.maritalStatus.name}
            </div>
            <div className="col-4 md:col-2">
              <label htmlFor="input">Teléfono:</label>
            </div>
            <div className="col-8 md:col-2">{contract.affiliate.telephone}</div>
            {contract.affiliate.phone && (
              <>
                <div className="col-4 md:col-2">
                  <label htmlFor="input">Celular:</label>
                </div>
                <div className="col-8 md:col-2">{contract.affiliate.phone}</div>
              </>
            )}
            {contract.affiliate.email && (
              <>
                <div className="col-4 md:col-2">
                  <label htmlFor="input">Correo Electrónico:</label>
                </div>
                <div className="col-8 md:col-2">{contract.affiliate.email}</div>
              </>
            )}
            <div className="col-4 md:col-2">
              <label htmlFor="input">Dirección:</label>
            </div>
            <div className="col-8 md:col-2">{contract.affiliate.address}</div>
          </div>
          <div className="spacer10" />
          <BeneficiaryDetails
            beneficiaries={contract.affiliate.beneficiaries}
            contractType={contractType}
          />
        </>
      )}
    </div>
  );
});

const ContractToPrint = ({ contract, contractType }) => {
  const componentRef = useRef();
  const pageStyle = `
  @page {
    size: A4;
    margin: 10mm;
  }

  @media print {
  html, body {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}
`;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
  });

  return (
    <>
      <ComponentToPrint
        ref={componentRef}
        contract={contract}
        contractType={contractType}
      />
      <div className="spacer10" />
      <div className="grid">
        <Button
          className="col-2"
          type="button"
          label="Imprimir"
          icon="pi pi-print"
          onClick={handlePrint}
        />
      </div>
    </>
  );
};

export default ContractToPrint;
