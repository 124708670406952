import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatCurrencyValue } from "../../utils/format";

const WayToPayDetails = ({ title, waysToPay, children }) => {
  if (!waysToPay || (waysToPay && waysToPay.length === 0)) return null;

  return (
    <>
      {title || ""}
      <DataTable value={waysToPay}>
        <Column
          body={(row) => formatCurrencyValue(row.amount)}
          header="Monto"
        />
        <Column field="transferType.name" header="Tipo de transferencia" />
        <Column field="referenceNumber" header="Número de referencia" />
        {children}
      </DataTable>
    </>
  );
};

export default WayToPayDetails;
