/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import GenericCalendar from "../../components/GenericComponents/GenericCalendar";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { convertStringToDate, formatCurrencyValue } from "../../utils/format";
import { Controller, useForm } from "react-hook-form";
import {
  showError,
  showSuccess,
  showWarning,
  showWarningDialog,
} from "../../utils/message";
import { classNames } from "primereact/utils";
import { useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";

const BillsToPayCreate = () => {
  const SUPPLY_ITEM = {
    NONE: -2,
    ADD: -1,
  };
  const [isShowingAvailableItems, setIsShowingAvailableItems] = useState(false);
  const [rowSupplyItem, setRowSupplyItem] = useState(SUPPLY_ITEM.NONE);
  const [providerSupplies, setProviderSupplies] = useState([]);
  const [supplies, setSupplies] = useState([]);
  const [providers, setProviders] = useState([]);
  const [billTypes, setBillTypes] = useState([]);

  const navigate = useNavigate();
  const { manyPost, post } = useService();
  const formAddingSupplyItem = useForm();
  const formBill = useForm();
  const provider = formBill.watch("provider");

  const isInventoried = formAddingSupplyItem.watch("isInventoried");
  const isConsignment = formAddingSupplyItem.watch("isConsignment");

  useEffect(() => {
    const request = [
      {
        url: "provider/search",
        body: {
          idCard: null,
          name: "",
        },
      },
      {
        url: "catalog/list",
        body: {
          catalogType: 1005,
        },
      },
    ];
    manyPost(request, (result) => {
      setProviders(result[0].providers);
      setBillTypes(result[1].catalogs);
      formBill.setValue("amount", 0);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!provider) return;

    post(
      "provider/get",
      {
        id: provider,
      },
      ({ provider }) => {
        if (provider.supplies.length === 0) {
          showWarning("El proveedor no cuenta con articulos registrado.");
        }

        setProviderSupplies(provider.supplies);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  const onHideAvailableItems = () => {
    setIsShowingAvailableItems(false);
  };

  const onHideEditItem = () => {
    setRowSupplyItem(SUPPLY_ITEM.NONE);
  };

  const showAddItemOfSupply = (item, column) => {
    formAddingSupplyItem.reset(item);
    setRowSupplyItem(column ?? SUPPLY_ITEM.ADD);
  };

  const calculatedAmount = () => {
    let calculatedAmount = 0;
    for (let item of supplies) {
      calculatedAmount += item.quantity * item.amount;
    }
    formBill.setValue("amount", calculatedAmount);
  };

  const deleteSupply = (index) => {
    supplies.splice(index, 1);
    setSupplies([...supplies]);
    calculatedAmount();
  };

  const saveSupply = (supply) => {
    if (rowSupplyItem === SUPPLY_ITEM.ADD) {
      addSupply(supply);
    } else {
      editSupply(supply);
    }
  };
  const editSupply = (supply) => {
    const existSupply = supplies.find(
      (x) => x.code === supply.code && supply.provider === x.provider
    );

    if (existSupply && supplies.indexOf(existSupply) !== rowSupplyItem) {
      showError("El articulo ha sido registrado anteriormente");
      return;
    }

    supplies[rowSupplyItem] = supply;
    setSupplies([...supplies]);
    onHideEditItem();
  };

  const addSupply = (supply) => {
    const existSupply = supplies.find(
      (x) => x.code === supply.code && provider === x.provider
    );
    if (existSupply) {
      showError("El articulo ha sido registrado anteriormente");
      return;
    }

    supplies.push({ ...supply, provider: provider });
    setSupplies([...supplies]);
    calculatedAmount();
    onHideEditItem();
  };

  const onError = (errors) => {
    showError("Debe completar los campos");
  };

  const saveBill = (formData) => {
    if (supplies.length === 0) {
      showError("Al menos debe agregar una articulo a la lista");
      return;
    }

    const data = {
      receivableInvoice: {
        number: formData.number,
        provider: providers.find((x) => x.id === formData.provider),
        type: billTypes.find((x) => x.id === formData.billType),
        amount: formData.amount,
        buyDate: convertStringToDate(formData.buyDate),
        registrationDate: convertStringToDate(formData.registrationDate),
        observations: formData.observations,
        supplies: supplies,
      },
    };

    post("receivableinvoices/create", data, () => {
      showSuccess("La factura se creó correctamente");
      navigate(-1);
    });
  };

  const askToGoBack = () => {
    if (formBill.isDirty === true || supplies.length > 0) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios sin guardar dentro de la página, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            navigate(-1);
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-create">
              <h1>Crear Factura</h1>
            </div>
            <div className="spacer20" />
            <Dialog
              header="Listado de articulos disponibles"
              visible={isShowingAvailableItems}
              modal={true}
              onHide={onHideAvailableItems}
            >
              <DataTable paginator={true} rows={5} value={providerSupplies}>
                <Column field="code" header="Codigo" />
                <Column field="description" header="Descripción" />
                <Column
                  body={({ amount }) => formatCurrencyValue(amount)}
                  header="Precio"
                />
                <Column
                  body={({ isInventoried }) => (isInventoried ? "Si" : "No")}
                  header="Inventariado"
                />
                <Column
                  body={(rowData) => (
                    <Button
                      type="button"
                      icon="pi pi-plus"
                      className="button-icon"
                      onClick={() => showAddItemOfSupply(rowData)}
                    />
                  )}
                  header="Agregar"
                />
              </DataTable>
              <div>
                <Button
                  label="Atrás"
                  icon="pi pi-times"
                  onClick={onHideAvailableItems}
                />
              </div>
            </Dialog>
            <Dialog
              header="Editar articulo"
              visible={rowSupplyItem !== SUPPLY_ITEM.NONE}
              modal={true}
              onHide={onHideEditItem}
            >
              <div className="grid">
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Codigo</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="number"
                    {...formAddingSupplyItem.register("code", {
                      required: true,
                    })}
                    className={classNames({
                      "p-invalid": !!formAddingSupplyItem.formState.errors.code,
                    })}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Descripción</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="text"
                    {...formAddingSupplyItem.register("description", {
                      required: true,
                    })}
                    className={classNames({
                      "p-invalid":
                        !!formAddingSupplyItem.formState.errors.description,
                    })}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Precio</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="number"
                    {...formAddingSupplyItem.register("amount")}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Cantidad</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="number"
                    {...formAddingSupplyItem.register("quantity", {
                      required: true,
                    })}
                    className={classNames({
                      "p-invalid":
                        !!formAddingSupplyItem.formState.errors.quantity,
                    })}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Registrar en el inventario</label>
                </div>
                <div className="col-12 md:col-4">
                  <Checkbox
                    onChange={(e) =>
                      formAddingSupplyItem.setValue("isInventoried", e.checked)
                    }
                    checked={isInventoried}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Consignación</label>
                </div>
                <div className="col-12 md:col-4">
                  <Checkbox
                    onChange={(e) =>
                      formAddingSupplyItem.setValue("isConsignment", e.checked)
                    }
                    checked={isConsignment}
                  />
                </div>
                <div>
                  <Button
                    label="Guardar"
                    icon="pi pi-pencil"
                    onClick={formAddingSupplyItem.handleSubmit(
                      saveSupply,
                      onError
                    )}
                  />
                  <Button
                    label="Atrás"
                    icon="pi pi-times"
                    onClick={onHideEditItem}
                  />
                </div>
              </div>
            </Dialog>
            <form
              onSubmit={formBill.handleSubmit(saveBill, onError)}
              className="grid"
            >
              <div className="col-12 md:col-2">
                <label htmlFor="input">Número</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="text"
                  {...formBill.register("number", {
                    required: true,
                  })}
                  className={classNames({
                    "p-invalid": !!formBill.formState.errors.number,
                  })}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Proveedor</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={formBill.control}
                  name="provider"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Dropdown
                      id="provider"
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Seleccione un proveedor"
                      {...field}
                      options={providers}
                      className={classNames({
                        "p-invalid": !!formBill.formState.errors.provider,
                      })}
                    />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Tipo de Factura</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={formBill.control}
                  name="billType"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Dropdown
                      id="billType"
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Seleccione un tipo"
                      {...field}
                      options={billTypes}
                      className={classNames({
                        "p-invalid": !!formBill.formState.errors.billType,
                      })}
                    />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Monto</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="number"
                  step=".01"
                  {...formBill.register("amount")}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Fecha de compra</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={formBill.control}
                  name="buyDate"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <GenericCalendar
                      id="buyDate"
                      {...field}
                      className={classNames({
                        "p-invalid": !!formBill.formState.errors.buyDate,
                      })}
                    />
                  )}
                />
              </div>

              <div className="col-12 md:col-2">
                <label htmlFor="input">Fecha de registro</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={formBill.control}
                  name="registrationDate"
                  render={({ field }) => (
                    <GenericCalendar id="registrationDate" {...field} />
                  )}
                />
              </div>
              <div className="md:col-12">
                <Button label="Guardar" />
                <Button type="button" label="Regresar" onClick={askToGoBack} />
                <Button
                  type="button"
                  label="Agregar articulo"
                  disabled={!provider}
                  onClick={() => setIsShowingAvailableItems(true)}
                />
              </div>
            </form>
            {supplies.length > 0 && (
              <div>
                <h2>Listado de articulos</h2>
                <DataTable paginator={true} rows={10} value={supplies}>
                  <Column field="code" header="Codigo" />
                  <Column field="description" header="Descripción" />
                  <Column field="quantity" header="Cantidad" />
                  <Column
                    body={(row) => formatCurrencyValue(row.amount)}
                    header="Precio"
                  />
                  <Column
                    body={(rowData, column) => {
                      return (
                        <div>
                          <Button
                            type="button"
                            icon="pi pi-pencil"
                            className="button-icon"
                            onClick={() =>
                              showAddItemOfSupply(rowData, column.rowIndex)
                            }
                          />
                          <Button
                            type="button"
                            className="p-button-danger button-icon"
                            icon="pi pi-trash"
                            onClick={() => deleteSupply(column.rowIndex)}
                          />
                        </div>
                      );
                    }}
                    header="Opciones"
                  />
                </DataTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillsToPayCreate;
