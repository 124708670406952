/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import { formatCurrencyValue } from "../../utils/format";

const ServiceDetail = () => {
  const [service, setService] = useState({});

  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();

  useEffect(() => {
    const data = {
      id: location.state.service.id,
    };
    post("service/get", data, ({ service }) => setService(service));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    return (
      <div className="p-fluid">
        <div className="grid">
          <div className="col-12">
            <div className="custom-card">
              <div className="custom-card-header-info">
                <h1>Detalle del Servicio</h1>
              </div>
              <div className="spacer20" />
                    <div className="grid">
                      <div className="md:col-4">
                        <label htmlFor="input">
                          Codigo GTI: {service.code}
                        </label>
                      </div>
                      <div className="md:col-4">
                        <label htmlFor="input">
                          Nombre: {service.name}
                        </label>
                      </div>
                      <div className="md:col-4">
                        <label htmlFor="input">
                          Precio: {formatCurrencyValue(service.price)}
                        </label>
                      </div>
                        <div className="md:col-4">
                          <label htmlFor="input">
                            Descripción: {service.description}
                          </label>
                        </div>
                      {service.applyAffiliate === true && (
                        <div className="md:col-4">
                          <label htmlFor="input">Servicio de Afiliado</label>
                        </div>
                      )}

                      {service.applyAffiliate === false && (
                        <div className="md:col-4">
                          <label htmlFor="input">Servicio de Particular</label>
                        </div>
                      )}

                      <div className="md:col-4">
                        <label htmlFor="input">
                          Impuestos: {formatCurrencyValue(service.tax)}
                        </label>
                      </div>

                      {service.components?.length > 0 && (
                        <div className="col-12">
                          <h2>Componentes</h2>
                          <DataTable
                            paginator={true}
                            rows={10}
                            value={service.components}
                          >
                            <Column field="component.name" header="Nombre" />
                            <Column field="component.number" header="Número" />
                            <Column
                            body={(row) => formatCurrencyValue(row.component.price)}  header="Precio" />
                          </DataTable>
                        </div>
                      )}
                    </div>
              <div className="spacer20" />
              <Button type="button" label="Regresar" onClick={() => navigate(-1)} />
            </div>
          </div>
        </div>
      </div>
    );
}


export default ServiceDetail;