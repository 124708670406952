/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/format";
import PermissionDetailTree from "../../components/PermissionTree/PermissionDetailTree";
import useService from "../../hooks/hook-service";

const UserDetail = () => {
  const [user, setUser] = useState({});

  const { post } = useService();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const data = {
      id: location.state.user.id,
    };
    post("user/get", data, ({ user }) => setUser(user));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Detalle del Usuario</h1>
            </div>
            <div className="spacer20" />

            <div className="grid">
              <div className="md:col-4">
                <label htmlFor="input">Cédula: {user.idCard}</label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">Nombre: {user.name}</label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">Apellidos: {user.lastName}</label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Fecha de Nacimiento: {formatDate(user.birthDate)}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">Correo Electrónico: {user.email}</label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">Número de Teléfono: {user.phone}</label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Rol: {user.role?.name ?? "Sin rol asignado"}
                </label>
              </div>
            </div>
            {user.role && (
              <PermissionDetailTree products={user.role?.products ?? []} />
            )}
            <div className="spacer20" />
            <Button
              type="button"
              label="Regresar"
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
