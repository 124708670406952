import React, { useEffect, useState } from "react";
import AssetForm from "./AssetForm";
import { useLocation, useNavigate } from "react-router-dom";
import { showSuccess } from "../../utils/message";
import useService from "../../hooks/hook-service";

const AssetEdit = () => {
  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();

  const [defualtAsset, setDefaultAsset] = useState({
    ...location.state.asset,
  });

  useEffect(() => {
    post(
      "asset/get",
      {
        id: location.state.asset.id,
      },
      ({ asset }) => {
        setDefaultAsset(asset);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editAsset = (asset) => {
    const data = {
      asset: { ...asset, id: location.state.asset.id },
    };

    post("asset/update", data, () => {
      showSuccess("El activo se ha actualizado correctamente");
      navigate(-1);
    });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <AssetForm
              headerClass="custom-card-header-edit"
              title="Editar Activo"
              titleButton="Editar"
              actionToExecute={editAsset}
              asset={defualtAsset}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetEdit;
