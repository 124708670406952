import { Navigate, useLocation } from "react-router-dom";

const CheckLocation = ({ children }) => {
  const location = useLocation();

  if (
    !location.state ||
    Object.getOwnPropertyNames(location.state).length === 0
  ) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return children;
};

export default CheckLocation;
