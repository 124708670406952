import React from "react";
import AffiliateHeader from "../AffiliateHeader";
import { formatDate } from "../../utils/format";

const ContractHeader = ({ contract }) => {
  return (
    <>
      <div className="md:col-4">
        <label htmlFor="input">
          Número de Contrato: {contract?.number || ""}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Tipo de Contrato: {contract?.type?.name || ""}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Estado de Contrato: {contract?.status?.name || ""}
        </label>
      </div>

      <AffiliateHeader
        label="del Asociado"
        affiliate={contract?.affiliate}
        onlyContractFields={true}
      />

      <div className="md:col-4">
        <label htmlFor="input">
          Fecha de Afiliación:
          {formatDate(contract?.creationDate)}
        </label>
      </div>
      {contract?.associatedContract !== 0 && (
        <div className="md:col-4">
          <label htmlFor="input">
            Contrato Asociado:
            {contract?.associatedContract}
          </label>
        </div>
      )}
    </>
  );
};

export default ContractHeader;
