/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import ActionToolbar from "../../components/ActionsToolbar";
import useService from "../../hooks/hook-service";
import { useLocation, useNavigate } from "react-router-dom";
import createExcel from "../../utils/createExcel";
import pdf from "../../utils/pdf";
import { useForm } from "react-hook-form";
import { showWarning } from "../../utils/message";
import { RadioButton } from "primereact/radiobutton";
import { formatCurrencyValue } from "../../utils/format";

const FeeList = () => {
  const ACTION_IDS = {
    EDIT: 2
  };

  const [fee, setFee] = useState({});
  const [value, setValue] = useState({});
  const [fees, setFees] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  const { post } = useService();
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const actions = location.state.actions;

  useEffect(() => {
    handleSubmit(search)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disableAction = (actionId) => {
    if (actionId === ACTION_IDS.EDIT && fee.year <= new Date().getFullYear()) {
      return true;
    }

    return Object.getOwnPropertyNames(fee).length === 0;
  };

  const createDetail = (detail) => {
    let details = "";
    for (let indexD = 0; indexD < detail.length; indexD++) {
      if(details !== ""){
        details += "\n";
      }
      details += `Monto: ${detail[indexD].amount}, Tipo de Cuota: ${detail[indexD].type.name} ${detail[indexD].contractType?.name ?? ""},`
    }
    return details;
  };

  const exportToExcel = () => {
    let headerColumns = [{ title: "Año", width: { width: 15 } }];
    let result = [];
    let detailColumns = [
      { title: "Tipo de cuota", width: { width: 20 } },
      { title: "Contrato", width: { width: 20 } },
      { title: "Cuota", width: { width: 20 } },
    ];

    for (
      let indexDataGrouped = 0;
      indexDataGrouped < fees.length;
      indexDataGrouped++
    ) {
      //get object rows
      let tempGroupedData = fees[indexDataGrouped];
      let hData = [];
      let dData = [];

      let objectHeader = tempGroupedData;
      let array1 = [];
      for (let indexH = 0; indexH < headerColumns.length; indexH++) {
        array1.push(objectHeader["year"] ?? "");
      }

      hData.push(array1);
      for (let indexD = 0; indexD < tempGroupedData.fees.length; indexD++) {
        dData.push([
          tempGroupedData.fees[indexD].type?.name ?? "",
          tempGroupedData.fees[indexD].contractType?.name ?? "",
          tempGroupedData.fees[indexD].amount ?? ""
        ]);
      }

      result.push(
        { columns: headerColumns, data: hData },
        { xSteps: 1, ySteps: 1, columns: detailColumns, data: dData }
      );
    }
    createExcel("Cuotas", result);
  };

  const exportToPdf = () => {
    const body = [];
    fees.forEach((element) => {
      const detail = createDetail(element.fees);
      const array = [element.year, detail];
      body.push(array);
    });
    const data = {
      head: [["Año", "Detalle"]],
      body: body,
      startY: 25,
      bodyStyles: { valign: "top" },
      tableWidth: "auto",
      styles: { cellWidth: "wrap", rowPageBreak: "auto" },
      columnStyles: { text: { cellWidth: "auto" } },
    };
    pdf.createPDF("l", data, "Cuotas.pdf", "Reporte de cuotas");
  };

  const redirect = (route) => {
    switch (route) {
      case "/feeCreate":
        navigate(route);
        break;
      case "/feeEdit":
      case "/feeDetail":
        navigate(route, {
          state: { fee: fee },
        });
        break;
      case "/exportExcel":
        exportToExcel();
        break;
      case "/exportPDF":
        exportToPdf();
        break;
      default:
        break;
    }
  };

  const search = (data) => {
    post("fee/search", data, ({ fees }) => {
      if (fees.length === 0) {
        showWarning("No se encontraron registros");
      }
      let result = Array.from(Map.groupBy(fees, fee => {
        return fee.year;
      }));
      result = result.map(x => {
        return {
          year:x[0],
          fees:x[1]
        }
      });
      setFees(result);
      setFee({});
    });
  };

  const select = (e, key) => {
    if (key !== value.key || e.year !== value.year) {
      setValue({ key: key, year: e.year });
      setFee(e);
    } else {
      setValue({});
      setFee({});
    }
  };

  const rowExpansionTemplate = (data) => {
    return (
      <>
        {data.fees.map((prop, key) => (
          <div key={prop.year+"-"+key} className="grid">
            <div className="col-12 md:col-2">
              <RadioButton
                onChange={(e) => select(prop, key)}
                checked={
                  value.key === key &&
                  value.year === prop.year
                }
              />
            </div>
            <div className="col-12 md:col-2">
              <label htmlFor="input">Monto: </label>
              {formatCurrencyValue(prop.amount)}
            </div>
            <div className="col-12 md:col-2">
              <label htmlFor="input">Tipo de cuota: </label> {prop.type.name}
            </div>
            {prop.contractType && (
              <div className="col-12 md:col-2">
                <label htmlFor="input">Contracto: </label>
                {prop.contractType.name}
              </div>
            )}
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Listado de Cuotas</h1>
            </div>
            <ActionToolbar
              actions={actions}
              checkIfHaveToDisable={disableAction}
              executeAction={redirect}
            />
            <div className="spacer10" />
            <label id="searchLabel" htmlFor="input">
              Buscar por
            </label>
            <div className="spacer20" />
            <div className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">Año de inicio</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="number"
                  {...register("startYear")}
                  min={1}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Año de finalización</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="number"
                  {...register("finalYear")}
                  min={1}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Cuota de inicio</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="number"
                  {...register("startAmount")}
                  min={1}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Cuota de finalización</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="number"
                  {...register("finalAmount")}
                  min={1}
                />
              </div>
              <div className="md:col-12">
                <Button label="Buscar" onClick={handleSubmit(search)} />
              </div>
              <DataTable
                className="col-12"
                paginator={true}
                rows={10}
                value={fees}
                expandedRows={expandedRows}
                 onRowToggle={(e) => {
                  setExpandedRows(e.data);
                 }}
                 emptyMessage="No se encontraron registros"
                rowExpansionTemplate={rowExpansionTemplate}
              >
                <Column expander={true} style={{ width: "4em" }} />
                <Column field="year" header="Año" />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeList;