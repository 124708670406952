import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import GenericCalendar from "../../components/GenericComponents/GenericCalendar";
import { useNavigate } from "react-router-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { showError, showWarning, showWarningDialog } from "../../utils/message";
import WayToPay from "../../components/WayToPayForm";
import useService from "../../hooks/hook-service";
import { convertStringToDate } from "../../utils/format";
import classNames from "classnames";

const AssetForm = ({
  headerClass,
  title,
  asset,
  actionToExecute,
  titleButton,
}) => {
  const [assetTypes, setAssetTypes] = useState([]);
  const [states, setStates] = useState([]);

  const navigate = useNavigate();
  const { manyPost } = useService();
  const formMethods = useForm();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isDirty, errors },
  } = formMethods;

  useEffect(() => {
    reset({
      ...asset,
      stateId: asset.state.id,
      typeId: asset.type.id,
      transferType: asset.transferType.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset]);

  useEffect(() => {
    const request = [
      {
        url: "catalog/list",
        body: {
          catalogType: 1003,
        },
      },
      {
        url: "catalog/list",
        body: {
          catalogType: 1002,
        },
      },
    ];
    manyPost(request, (result) => {
      if (result[0].catalogs.length === 0) {
        showWarning("No se encontraron estados");
      }
      setStates(result[0].catalogs);

      if (result[1].catalogs.length === 0) {
        showWarning("No se encontraron los tipos de activos");
      }
      setAssetTypes(result[1].catalogs);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBackToHome = () => {
    if (isDirty === true) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios sin guardar dentro de la página, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            navigate(-1);
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  const onSubmit = (formData) => {
    const asset = {
      plateNumber: formData.plateNumber,
      description: formData.description,
      localization: formData.localization,
      cost: formData.cost,
      sellPrice: formData.sellPrice,
      transferType: {
        id: formData.transferType,
      },
      billNumber: formData.billNumber,
      referenceNumber: formData.referenceNumber,
      boughtTime: convertStringToDate(formData.boughtTime),
      observation: formData.observation,
      type: {
        id: formData.typeId,
      },
      state: {
        id: formData.stateId,
      },
    };

    actionToExecute(asset);
  };

  const onError = (errors) => {
    showError("Debe completar los campos");
  };

  return (
    <>
      <div className={headerClass}>
        <h1>{title}</h1>
      </div>
      <div className="spacer20" />
      <form onSubmit={handleSubmit(onSubmit, onError)} className="grid">
        <div className="col-12 md:col-2">
          <label htmlFor="input">Número de placa</label>
        </div>
        <div className="col-12 md:col-4">
          <InputText
            type="text"
            {...register("plateNumber", {
              required: true,
            })}
            className={classNames({
              "p-invalid": !!errors.plateNumber,
            })}
          />
        </div>
        <div className="col-12 md:col-2">
          <label htmlFor="input">Descripción</label>
        </div>
        <div className="col-12 md:col-4">
          <InputText
            type="text"
            {...register("description", {
              required: true,
            })}
            className={classNames({
              "p-invalid": !!errors.description,
            })}
          />
        </div>
        <div className="col-12 md:col-2">
          <label htmlFor="input">Localización</label>
        </div>
        <div className="col-12 md:col-4">
          <InputText
            type="text"
            {...register("localization", {
              required: true,
            })}
            className={classNames({
              "p-invalid": !!errors.localization,
            })}
          />
        </div>
        <div className="col-12 md:col-2">
          <label htmlFor="input">Costo</label>
        </div>
        <div className="col-12 md:col-4">
          <InputText type="number" {...register("cost")} />
        </div>
        <div className="col-12 md:col-2">
          <label htmlFor="input">Precio de venta</label>
        </div>
        <div className="col-12 md:col-4">
          <InputText
            type="number"
            step="any"
            placeholder="0.00"
            min="0"
            {...register("sellPrice")}
          />
        </div>
        <div className="col-12 md:col-2">
          <label htmlFor="input">Número de factura</label>
        </div>
        <div className="col-12 md:col-4">
          <InputText type="text" {...register("billNumber")} />
        </div>
        <div className="col-12 md:col-2">
          <label htmlFor="input">Fecha de compra</label>
        </div>
        <div className="col-12 md:col-4">
          <Controller
            control={control}
            name="boughtTime"
            render={({ field }) => (
              <GenericCalendar id="boughtTime" {...field} />
            )}
          />
        </div>
        <div className="col-12 md:col-2">
          <label htmlFor="input">Observaciones</label>
        </div>
        <div className="col-12 md:col-4">
          <InputText type="text" {...register("observation")} />
        </div>
        <FormProvider {...formMethods}>
          <WayToPay requiredAmount={false} />
        </FormProvider>
        <div className="col-12 md:col-2">
          <label htmlFor="input">Tipo de Activo</label>
        </div>
        <div className="col-12 md:col-4">
          <Controller
            control={control}
            name="typeId"
            rules={{ required: true }}
            render={({ field }) => (
              <Dropdown
                id="typeId"
                optionLabel="name"
                optionValue="id"
                placeholder="Tipo de Activo"
                {...field}
                className={classNames({
                  "p-invalid": !!errors.typeId,
                })}
                options={assetTypes}
              />
            )}
          />
        </div>
        <div className="col-12 md:col-2">
          <label htmlFor="input">Estado</label>
        </div>
        <div className="col-12 md:col-4">
          <Controller
            control={control}
            rules={{ required: true }}
            name="stateId"
            render={({ field }) => (
              <Dropdown
                id="stateId"
                className={classNames({
                  "p-invalid": !!errors.stateId,
                })}
                optionLabel="name"
                optionValue="id"
                placeholder="Estado"
                {...field}
                options={states}
              />
            )}
          />
        </div>
        <div className="md:col-12">
          <Button label={titleButton} />
          <Button type="button" label="Regresar" onClick={goBackToHome} />
        </div>
      </form>
    </>
  );
};

export default AssetForm;
