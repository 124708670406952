import React from "react";
import { formatCurrencyValue, formatDate } from "../../utils/format";

const AffiliateHeader = ({
  affiliate,
  onlyContractFields = false,
  label = "",
}) => {
  if (label !== "") {
    label = " " + label;
  }

  return (
    <>
      {!onlyContractFields && (
        <div className="md:col-4">
          <label htmlFor="input">Número de Contrato: {affiliate?.number}</label>
        </div>
      )}
      <div className="md:col-4">
        <label htmlFor="input">
          Cédula{label}: {affiliate?.idCard}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Nombre{label}: {affiliate?.firstName}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Apellidos{label}: {affiliate?.lastName}
        </label>
      </div>
      {!onlyContractFields && (
        <div className="md:col-4">
          <label htmlFor="input">
            Fecha de proximo pago: {formatDate(affiliate?.payDate)}
          </label>
        </div>
      )}
      {!onlyContractFields && (
        <div className="md:col-4">
          <label htmlFor="input">
            Ultima fecha de pago: {formatDate(affiliate?.lastFeeDate)}
          </label>
        </div>
      )}
      {!onlyContractFields && (
        <div className="md:col-4">
          <label htmlFor="input">
            Cuotas pendientes: {affiliate?.lateQuotas}
          </label>
        </div>
      )}
      {!onlyContractFields && (
        <div className="md:col-4">
          <label htmlFor="input">
            Ahorro: {formatCurrencyValue(affiliate?.savingAmount)}
          </label>
        </div>
      )}
      <div className="md:col-4">
        <label htmlFor="input">
          Estado Civil{label}: {affiliate?.maritalStatus?.name}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Sexo{label}: {affiliate?.genre?.name}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Fecha de nacimiento{label}: {formatDate(affiliate?.birthDate)}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Teléfono{label}: {affiliate?.telephone}
        </label>
      </div>
      {affiliate?.email && (
        <div className="md:col-4">
          <label htmlFor="input">
            Correo Electrónico{label}: {affiliate?.email}
          </label>
        </div>
      )}
      {affiliate?.celphone && (
        <div className="md:col-4">
          <label htmlFor="input">
            Celular{label}:{affiliate?.celphone}
          </label>
        </div>
      )}
      {affiliate?.division && (
        <div className="md:col-4">
          <label htmlFor="input">Provincia: {affiliate?.division?.name}</label>
        </div>
      )}
      {affiliate?.division?.subDivision && (
        <div className="md:col-4">
          <label htmlFor="input">
            Canton: {affiliate?.division?.subDivision?.name}
          </label>
        </div>
      )}
      {affiliate?.division?.subDivision?.subDivision && (
        <div className="md:col-4">
          <label htmlFor="input">
            Distrito: {affiliate?.division?.subDivision?.subDivision?.name}
          </label>
        </div>
      )}
      <div className="md:col-4">
        <label htmlFor="input">Dirección: {affiliate?.address}</label>
      </div>
    </>
  );
};

export default AffiliateHeader;
