/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RoleForm from "./RoleForm";
import { showSuccess } from "../../utils/message";
import useService from "../../hooks/hook-service";

const RoleCreate = () => {
  const [allPermissions, setAllPermissions] = useState([]);

  const { post } = useService();

  useEffect(() => {
    post("product/permissions", {}, ({ products }) =>
      setAllPermissions(products)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const createRole = (formData) => {
    post("role/create", { role: formData }, () => {
      showSuccess("El rol se creó correctamente");
      navigate(-1);
    });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <RoleForm
              headerClass="custom-card-header-create"
              title="Crear Rol"
              titleButton="Guardar"
              actionToExecute={createRole}
              allPermissions={allPermissions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleCreate;
