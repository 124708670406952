/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import { classNames } from "primereact/utils";
import React, { useState } from "react";

const AppSubmenu = ({ onMenuItemClick, className, root, items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onItemClick = (event, item, index) => {
    //avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    //execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }

    //prevent hash change
    if (item.items || !item.url) {
      event.preventDefault();
    }

    if (index === activeIndex) setActiveIndex(null);
    else setActiveIndex(index);

    if (onMenuItemClick) {
      onMenuItemClick({
        originalEvent: event,
        item: item,
      });
    }
  };

  let menuItems =
    items &&
    items.map((item, i) => {
      let active = activeIndex === i;
      let styleClass = classNames(item.badgeStyleClass, {
        "active-menuitem": active,
      });
      let badge = item.badge && (
        <span className="menuitem-badge">{item.badge}</span>
      );
      let submenuIcon = item.items && (
        <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
      );

      return (
        <li className={styleClass} key={i}>
          {item.items && root === true && <div className="arrow"></div>}
          <a
            href={item.url}
            onClick={(e) => onItemClick(e, item, i)}
            target={item.target}
          >
            <i className={item.icon}></i>
            <span>{item.label}</span>
            {submenuIcon}
            {badge}
          </a>
          <AppSubmenu items={item.items} onMenuItemClick={onMenuItemClick} />
        </li>
      );
    });

  return menuItems ? <ul className={className}>{menuItems}</ul> : null;
};

export const AppMenu = ({ model, onMenuItemClick }) => {
  return (
    <div className="menu">
      <AppSubmenu
        items={model}
        className="layout-main-menu"
        onMenuItemClick={onMenuItemClick}
        root={true}
      />
    </div>
  );
};
