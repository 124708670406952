import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";

const ServiceEdit = ({
  title = "Agregar Servicio",
  service,
  onClose,
  onUpdate,
}) => {
  const [price, setPrice] = useState();
  useEffect(() => {
    if (service) {
      setPrice(service.price);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service]);

  const addService = () => {
    onUpdate({ ...service, price: price });
    onClose();
  };

  return (
    <Dialog
      style={{ width: "30vw" }}
      header={title}
      visible={!!service}
      modal={true}
      onHide={onClose}
    >
      <div className="grid">
        <div className="col-6">
          <label htmlFor="input">Precio</label>
        </div>
        <div className="col-6">
          <InputText
            type="number"
            required={true}
            value={price}
            onChange={(event) => setPrice(event.target.value)}
          />
        </div>
        <div>
          <Button label="Agregar" icon="pi pi-plus" onClick={addService} />
          <Button label="Atrás" icon="pi pi-times" onClick={onClose} />
        </div>
      </div>
    </Dialog>
  );
};

export default ServiceEdit;
