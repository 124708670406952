/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import GenericCalendar from "../../components/GenericComponents/GenericCalendar";
import { Paginator } from "primereact/paginator";
import pdf from "../../utils/pdf";
import {
  convertStringToDate,
  formatCurrencyValue,
  formatDate,
} from "../../utils/format";
import { Controller, useForm } from "react-hook-form";
import {
  showSuccess,
  showWarning,
  showWarningDialog,
} from "../../utils/message";
import { useLocation, useNavigate } from "react-router-dom";
import ActionToolbar from "../../components/ActionsToolbar";
import createExcel from "../../utils/createExcel";
import useService from "../../hooks/hook-service";

const ListReceipt = () => {
  const ACTION_IDS = {
    INACTIVE: 7,
  };

  const [receipts, setReceipts] = useState([]);
  const [receipt, setReceipt] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState(0);
  const [sizePage, setSizePage] = useState(10);

  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();
  const actions = location.state.actions;
  const defaultValues = {
    sizePage: sizePage,
  };
  const { register, control, getValues } = useForm({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    resfreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const doRequest = (request, callback) => {
    post("paymentReceived/search", request, ({ rows, paymentsReceived }) => {
      if (rows === 0) {
        showWarning("No se encontraron registros");
      }
      callback(rows, paymentsReceived);
    });
  };

  const resfreshData = () => {
    const startPayDate = getValues("startPayDate");
    const endPayDate = getValues("endPayDate");

    const request = {
      startPayDate: convertStringToDate(startPayDate),
      endPayDate: convertStringToDate(endPayDate),
      startNumberAffiliate: getValues("startNumberAffiliate") || null,
      endNumberAffiliate: getValues("endNumberAffiliate") || null,
      idAffiliate: getValues("idAffiliate") || null,
      sizePage: getValues("sizePage"),
      page: page,
    };

    doRequest(request, (rows, paymentsReceived) => {
      setReceipts(paymentsReceived);
      setTotalRecords(rows);
      setReceipt({});
    });
  };

  const exportToExcel = () => {
    const startPayDate = getValues("startPayDate");
    const endPayDate = getValues("endPayDate");

    const request = {
      startPayDate: convertStringToDate(startPayDate),
      endPayDate: convertStringToDate(endPayDate),
      startNumberAffiliate: getValues("startNumberAffiliate") || null,
      endNumberAffiliate: getValues("endNumberAffiliate") || null,
      idAffiliate: getValues("idAffiliate") || null,
      page: 0,
    };

    doRequest(request, (rows, paymentsReceived) => {
      if (rows === 0) return;

      let result = {
        columns: [
          { title: "Recibo", width: { width: 10 } },
          { title: "Cedula", width: { width: 15 } },
          { title: "Nombre", width: { width: 20 } },
          { title: "Apellidos", width: { width: 20 } },
          { title: "Fecha de creación", width: { width: 15 } },
          { title: "Monto", width: { width: 15 } },
          { title: "Estado", width: { width: 10 } },
        ],
        data: [],
      };

      paymentsReceived.forEach((element) => {
        result.data.push([
          element.number,
          element.affiliate.idCard ?? "",
          element.affiliate.firstName,
          element.affiliate.lastName,
          formatDate(element.creationDate),
          formatCurrencyValue(element.amountReceived),
          element.state.name,
        ]);
      });

      createExcel("Recibos", [result]);
    });
  };

  const exportToPdf = () => {
    const startPayDate = getValues("startPayDate");
    const endPayDate = getValues("endPayDate");

    const request = {
      startPayDate: convertStringToDate(startPayDate),
      endPayDate: convertStringToDate(endPayDate),
      startNumberAffiliate: getValues("startNumberAffiliate") || null,
      endNumberAffiliate: getValues("endNumberAffiliate") || null,
      idAffiliate: getValues("idAffiliate") || null,
      page: 0,
    };

    doRequest(request, (rows, paymentsReceived) => {
      if (rows === 0) return;

      const body = [];
      paymentsReceived.forEach((element) => {
        body.push([
          element.number,
          element.affiliate.idCard ?? "",
          element.affiliate.firstName,
          element.affiliate.lastName,
          formatDate(element.creationDate),
          formatCurrencyValue(element.amountReceived),
          element.state.name,
        ]);
      });

      const data = {
        head: [
          [
            "Recibo",
            "Cedula",
            "Nombre",
            "Apellidos",
            "Fecha de creación",
            "Monto",
            "Estado",
          ],
        ],
        body: body,
        startY: 25,
        styles: { overflow: "linebreak" },
      };

      pdf.createPDF("l", data, "Recibos.pdf", "Reporte de recibos");
    });
  };

  const select = (e) => {
    if (e.value) {
      setReceipt(e.value);
    } else {
      setReceipt({});
    }
  };

  const disableAction = (actionId) => {
    if (receipt.state?.id === 74 && actionId === ACTION_IDS.INACTIVE) {
      return true;
    }
    return Object.getOwnPropertyNames(receipt).length === 0;
  };

  const redirect = (route) => {
    switch (route) {
      case "/receiptPay":
        navigate("/receiptListAffiliate", {
          state: {
            title: "Listado de Asociados Para Pago de Recibos",
            isAffiliate: true,
            actions: [
              {
                name: "Pagar",
                route: route,
                isDependentOfElement: true,
                icon: "pi pi-money-bill",
              },
            ],
          },
        });
        break;
      case "/paymentArrangementCreate":
        navigate("/receiptListAffiliate", {
          state: {
            title: "Listado de Asociados Para Arreglo de Pago",
            isAffiliate: true,
            actions: [
              {
                name: "Arreglo de Pago",
                route: route,
                isDependentOfElement: true,
                icon: "pi pi-file",
              },
            ],
          },
        });
        break;
      case "/receiptDetail":
      case "/receiptChangePay":
        navigate(route, {
          state: { receipt: receipt },
        });
        break;
      case "/receiptCancel":
        invalidateReceipt();
        break;
      case "/exportExcel":
        exportToExcel();
        break;
      case "/exportPDF":
        exportToPdf();
        break;
      default:
        break;
    }
  };

  const invalidateReceipt = () => {
    showWarningDialog(
      {
        title: "Precaución",
        text: "¿Está seguro(a) de que desea  anular el recibo?",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      },
      {
        confirm: () => {
          const request = {
            id: receipt.id,
          };
          post("paymentReceived/invalidate", request, () => {
            showSuccess("Se anuló correctamente");
            search();
          });
        },
      }
    );
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page);
  };

  const search = () => {
    const size = getValues("sizePage");
    setSizePage(size);
    if (page === 0) {
      resfreshData();
    } else {
      setFirst(0);
      setPage(0);
    }
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Listado de Recibos</h1>
            </div>
            <ActionToolbar
              actions={actions}
              checkIfHaveToDisable={disableAction}
              executeAction={redirect}
            />
            <label id="searchLabel" htmlFor="input">
              Buscar por{" "}
            </label>
            <div className="spacer20" />
            <div className="grid">
              <div className="col-12 ">
                <label htmlFor="input">Fecha de pago</label>
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Desde</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="startPayDate"
                  render={({ field }) => (
                    <GenericCalendar id="startPayDate" {...field} />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">hasta</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="endPayDate"
                  render={({ field }) => (
                    <GenericCalendar id="endPayDate" {...field} />
                  )}
                />
              </div>
              <div className="col-12 ">
                <label htmlFor="input">Número de contrato</label>
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Desde</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="number"
                  {...register("startNumberAffiliate")}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">hasta</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="number" {...register("endNumberAffiliate")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Cédula del asociado</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="number" {...register("idAffiliate")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Cantidad de registros a mostrar</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="number" min={1} {...register("sizePage")} />
              </div>
              <div className="md:col-12">
                <Button label="Buscar" onClick={search} />
              </div>
            </div>
            <DataTable
              value={receipts}
              selection={receipt}
              onSelectionChange={select}
              emptyMessage="No se encontraron registros"
            >
              <Column selectionMode="single" style={{ width: "4em" }} />
              <Column field="number" header="Número de recibo" />
              <Column field="affiliate.idCard" header="Cedula del asociado" />
              <Column
                field="affiliate.firstName"
                header="Nombre del asociado"
              />
              <Column
                field="affiliate.lastName"
                header="Apellidos del asociado"
              />
              <Column
                body={(row) => formatDate(row.creationDate)}
                header="Fecha de creación"
              />
              <Column
                body={(row) => formatCurrencyValue(row.amountReceived)}
                field="amountReceived"
                header="Monto"
              />
              <Column field="state.name" header="Estado" />
            </DataTable>
            <Paginator
              first={first}
              totalRecords={totalRecords}
              rows={sizePage}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListReceipt;
