import React from "react";
import { createHash } from "../../utils/security";
import UserForm from "./UserForm";
import { useNavigate } from "react-router-dom";
import { showSuccess } from "../../utils/message";
import { convertStringToDate } from "../../utils/format";
import useService from "../../hooks/hook-service";

const UserCreate = () => {
  const defaultUser = {};

  const navigate = useNavigate();
  const { post } = useService();

  const createUser = (formData) => {
    let phoneNumber = formData.phone;
    phoneNumber = phoneNumber.replace("-", "");

    createHash(formData.password).then((result) => {
      const data = {
        user: {
          email: formData.email,
          username: formData.username,
          name: formData.name,
          status: formData.status,
          idCard: formData.idCard,
          lastName: formData.lastName,
          birthDate: convertStringToDate(formData.birthDate),
          phone: phoneNumber,
        },
        password: result,
      };

      post("user/create", data, () => {
        showSuccess("El usuario se ha creado correctamente");
        navigate(-1);
      });
    });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <UserForm
              headerClass="custom-card-header-create"
              title="Crear Usuario"
              titleButton="Guardar"
              isCreatedForm={true}
              actionToExecute={createUser}
              user={defaultUser}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCreate;
