import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { PickList } from "primereact/picklist";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import GenericCalendar from "../../components/GenericComponents/GenericCalendar";
import useService from "../../hooks/hook-service";
import { Controller, useForm } from "react-hook-form";
import { convertStringToDate } from "../../utils/format";
import { showError, showSuccess } from "../../utils/message";

const NotificationSend = () => {
  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();
  const notification = location.state.notification;

  const [types, setTypes] = useState([]);
  const [affiliates, setAffiliates] = useState([]);
  const [affiliatesShowed, setAffiliatesShowed] = useState([]);
  const [message, setMessage] = useState(notification.message);

  const { register, handleSubmit, watch, setValue, control } = useForm();
  const withSavingAmount = watch("withSavingAmount");

  useEffect(() => {
    post(
      "catalog/list",
      {
        catalogType: 1000,
      },
      ({ catalogs }) => {
        setTypes(catalogs);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const search = (formData) => {
    const data = {
      idCard: formData.idCard,
      firstName: formData.firstName,
      lastName: formData.lastName,
      initialAmount: formData.initialAmount,
      finalAmount: formData.finalAmount,
      startCreationDate: convertStringToDate(formData.startCreationDate),
      endCreationDate: convertStringToDate(formData.endCreationDate),
      startLastFeeDate: convertStringToDate(formData.startLastFeeDate),
      endLastFeeDate: convertStringToDate(formData.endLastFeeDate),
      withSavingAmount: formData.withSavingAmount ? true : null,
      startNumber: formData.number,
      endNumber: formData.number,
    };

    if (formData.type && formData.type !== "" && formData.type !== null) {
      data.type = {
        id: formData.type,
      };
    }

    post("affiliate/search", data, ({ affiliates }) => {
      if (affiliates.length === 0) {
        showError("No se encontraron registros");
      }
      setAffiliates(affiliates);
    });
  };

  const affiliateTemplate = (affiliate) => {
    return (
      <div className="p-clearfix">
        <div
          style={{ fontSize: "14px", float: "right", margin: "15px 5px 0 0" }}
        >
          {affiliate.idCard} - {affiliate.firstName} {affiliate.lastName}
        </div>
      </div>
    );
  };

  const onChange = (event) => {
    setAffiliates(event.source);
    setAffiliatesShowed(event.target);
  };

  const send = () => {
    if (affiliatesShowed.length === 0) {
      showError("Debe seleccionar al menos un asociado");
      return;
    }

    const data = {
      notification: { ...notification, message },
      affiliates: affiliatesShowed,
    };

    post("notification/send", data, () => {
      showSuccess("Se envio correctamente la notificacion");
      goBack();
    });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Enviar Notificación de {notification.name}</h1>
            </div>
            <div className="spacer20" />
            <div className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">Número de Contrato</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("number")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Número de Cédula</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("idCard")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Nombre</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("firstName")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Apellidos</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("lastName")} />
              </div>
              <div className="col-12">
                <label htmlFor="input">Fecha de afiliación</label>
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Desde </label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="startCreationDate"
                  render={({ field }) => (
                    <GenericCalendar id="startCreationDate" {...field} />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">hasta</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="endCreationDate"
                  render={({ field }) => (
                    <GenericCalendar id="endCreationDate" {...field} />
                  )}
                />
              </div>
              <div className="col-12">
                <label htmlFor="input">Fecha de ultima cuota</label>
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Desde </label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="startLastFeeDate"
                  render={({ field }) => (
                    <GenericCalendar id="startLastFeeDate" {...field} />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">hasta</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="endLastFeeDate"
                  render={({ field }) => (
                    <GenericCalendar id="endLastFeeDate" {...field} />
                  )}
                />
              </div>
              <div className="col-12">
                <label htmlFor="input">Monto</label>
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Inicial </label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="number" {...register("initialAmount")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Final</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="number" {...register("finalAmount")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Tipo de contrato</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="type"
                  render={({ field }) => (
                    <Dropdown
                      id="type"
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Todos"
                      {...field}
                      options={types}
                    />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Asociado con Ahorro</label>
              </div>
              <div className="col-12 md:col-4">
                <Checkbox
                  onChange={(e) => setValue("withSavingAmount", e.checked)}
                  checked={withSavingAmount}
                />
              </div>
              <div className="md:col-12">
                <Button label="Buscar" onClick={handleSubmit(search)} />
              </div>
              <div className="col-12">
                <InputTextarea
                  rows={5}
                  cols={30}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              {affiliates && (
                <div className="col-12">
                  <h4>Seleccione los asociados</h4>
                  <PickList
                    source={affiliates}
                    target={affiliatesShowed}
                    itemTemplate={affiliateTemplate}
                    onChange={onChange}
                    sourceHeader="Disponible"
                    targetHeader="Seleccionados"
                  />
                </div>
              )}
              <div className="md:col-12">
                <Button type="button" label="Enviar" onClick={send} />
                <Button type="button" label="Regresar" onClick={goBack} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSend;
