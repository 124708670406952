/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";

const ContractDownloadFile = () => {
  const [contract, setContract] = useState({});

  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();

  useEffect(() => {
    const data = {
      id: location.state.contract.id,
    };
    post("contract/get", data, ({ contract }) => setContract(contract));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const download = (name) => {
    const request = {
      name: name,
      contractId: location.state.contract.id,
    };
    post("contract/getDocument", request, (result) => {
      const linkSource = result.document.data;
      const downloadLink = document.createElement("a");
      const fileName = result.document.name;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  const actionDownload = (rowData, column) => {
    return (
      <Button
        className="button-icon"
        type="button"
        icon="pi pi-download"
        onClick={() => download(rowData.name)}
        tooltip="Descargar"
      />
    );
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Descargar Documentos del Contrato</h1>
            </div>
            <div className="spacer20" />
            {contract.affiliate && (
              <>
                <div className="grid">
                  <div className="md:col-4">
                    <label htmlFor="input">
                      Número de Contrato: {contract.number}
                    </label>
                  </div>
                </div>
                {contract.documents.length > 0 && (
                  <>
                    <h2>Documentos</h2>
                    <DataTable
                      value={contract.documents}
                      emptyMessage="No se encontraron registros"
                    >
                      <Column field="name" header="Nombre" />
                      <Column body={actionDownload} header="Descargar" />
                    </DataTable>
                  </>
                )}
              </>
            )}
            <div className="spacer20" />
            <Button type="button" label="Regresar" onClick={goBack} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractDownloadFile;
