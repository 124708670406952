/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useLocation, useNavigate } from "react-router-dom";
import { formatCurrencyValue, formatDate } from "../../utils/format";
import useService from "../../hooks/hook-service";

const BillsToPayDetail = () => {
  const [receivableInvoice, setReceivableInvoice] = useState();

  const { post } = useService();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    post(
      "receivableinvoices/get",
      {
        id: location.state.receivableInvoice.id,
      },
      ({ receivableInvoice }) => {
        setReceivableInvoice(receivableInvoice);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => navigate(-1);

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Detalle de la Factura por Pagar</h1>
            </div>
            <div className="spacer20" />
            <div className="grid">
              <div className="md:col-4">
                <label htmlFor="input">
                  Número: {receivableInvoice?.number}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Monto: {formatCurrencyValue(receivableInvoice?.amount)}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Fecha de compra:{" "}
                  {formatDate(receivableInvoice?.buyDate)}
                </label>
              </div>
              {receivableInvoice?.payDate && (
                <div className="md:col-4">
                  <label htmlFor="input">
                    Fecha que se pago la factura:{" "}
                    {formatDate(receivableInvoice?.payDate)}
                  </label>
                </div>
              )}
              {receivableInvoice?.registrationDate && (
                <div className="md:col-4">
                  <label htmlFor="input">
                    Fecha que se registro la factura:{" "}
                    {formatDate(receivableInvoice?.registrationDate)}
                  </label>
                </div>
              )}
              <div className="md:col-4">
                <label htmlFor="input">
                  Tipo de factura: {receivableInvoice?.type.name}
                </label>
              </div>
              {receivableInvoice?.transferType && (
                <div className="md:col-4">
                  <label htmlFor="input">
                    Tipo de transferencia:{" "}
                    {receivableInvoice?.transferType?.name}
                  </label>
                </div>
              )}
              {receivableInvoice?.referenceNumber && (
                <div className="md:col-4">
                  <label htmlFor="input">
                    Número de referencia: {receivableInvoice?.referenceNumber}
                  </label>
                </div>
              )}
              <div className="md:col-4">
                <label htmlFor="input">
                  Cedula del proveedor: {receivableInvoice?.provider?.idCard}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Nombre del proveedor: {receivableInvoice?.provider?.name}
                </label>
              </div>
              {receivableInvoice?.motive && (
                <div className="md:col-4">
                  <label htmlFor="input">
                    Motivo: {receivableInvoice?.motive}
                  </label>
                </div>
              )}
              {receivableInvoice?.observations && (
                <div className="md:col-4">
                  <label htmlFor="input">
                    Observaciones: {receivableInvoice?.observations}
                  </label>
                </div>
              )}

              {receivableInvoice?.supplies?.length > 0 && (
                <div className="col-12">
                  <h2>Articulos</h2>
                  <DataTable
                    paginator={true}
                    rows={10}
                    value={receivableInvoice?.supplies}
                  >
                    <Column field="code" header="Código" />
                    <Column field="description" header="Descripción" />
                    <Column
                      body={({ amount }) => formatCurrencyValue(amount)}
                      header="Precio"
                    />
                    <Column field="quantity" header="Cantidad" />
                  </DataTable>{" "}
                </div>
              )}
              <div className="spacer20" />
              <Button type="button" label="Regresar" onClick={goBack} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillsToPayDetail;
