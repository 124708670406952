import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatCurrencyValue } from "../../utils/format";

const ReceiptDetails = ({ title, details }) => {
  if (!details || (details && details.length === 0)) return null;

  return (
    <>
      {title || ""}
      <DataTable value={details}>
        <Column field="quantity" header="Cantidad" />
        <Column field="description" header="Descripción" />
        <Column
          body={(row) => formatCurrencyValue(row.unitPrice)}
          field="unitPrice"
          header="Unitario"
        />
        <Column
          body={(row) => formatCurrencyValue(row.totalPrice)}
          field="totalPrice"
          header="Monto"
        />
      </DataTable>
    </>
  );
};

export default ReceiptDetails;
