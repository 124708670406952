/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { showError, showWarningDialog } from "../../utils/message";
import classNames from "classnames";

const ProviderForm = ({
  headerClass,
  title,
  titleButton,
  actionToExecute,
  provider,
}) => {
  const STATES_ROW_INDEX = {
    CLOSE: -2,
    NEW: -1,
  };

  const [rowIndexItem, setRowIndexItem] = useState(STATES_ROW_INDEX.CLOSE);
  const [supplies, setSupplies] = useState([]);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm();

  const formAddItem = useForm();
  const isInventoried = formAddItem.watch("isInventoried");
  const isConsignment = formAddItem.watch("isConsignment");

  useEffect(() => {
    setSupplies(provider.supplies ?? []);
    reset({ ...provider });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  const goBackToHome = () => {
    if (isDirty === true) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios sin guardar dentro de la página, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            navigate(-1);
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  const onHideDialog = () => {
    setRowIndexItem(STATES_ROW_INDEX.CLOSE);
  };

  const addOrEditService = (formData) => {
    if (rowIndexItem === STATES_ROW_INDEX.NEW) {
      supplies.push(formData);
    } else {
      supplies[rowIndexItem] = formData;
    }

    setSupplies([...supplies]);
    onHideDialog();
  };

  const onSubmit = (formData) => {
    const providerToSave = {
      ...provider,
      name: formData.name,
      idCard: formData.idCard,
      email: formData.email,
      phone: formData.phone,
      supplies: supplies,
    };

    actionToExecute(providerToSave);
  };

  const editSupply = (supply, rowIndex) => {
    formAddItem.reset(supply);
    setRowIndexItem(rowIndex);
  };

  const addSupply = () => {
    formAddItem.reset({
      code: "",
      description: "",
      amount: "",
      isInventoried: false,
      isConsignment: false
    });
    setRowIndexItem(STATES_ROW_INDEX.NEW);
  };

  const deleteSupply = (rowIndex) => {
    supplies.splice(rowIndex, 1);
    setSupplies([...supplies]);
  }

  const onError = (errors) => {
    showError("Debe completar los campos");
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className={headerClass}>
              <h1>{title}</h1>
            </div>
            <div className="spacer20" />
            <Dialog
              header="Agregar articulo"
              visible={rowIndexItem !== STATES_ROW_INDEX.CLOSE}
              onHide={onHideDialog}
              modal={true}
            >
              <div className="grid">
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Codigo</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="number"
                    {...formAddItem.register("code", {
                      required: true,
                    })}
                    className={classNames({
                      "p-invalid": !!formAddItem.formState.errors.code,
                    })}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Descripción</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="text"
                    {...formAddItem.register("description", {
                      required: true,
                    })}
                    className={classNames({
                      "p-invalid": !!formAddItem.formState.errors.description,
                    })}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Precio</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="number"
                    {...formAddItem.register("amount", {
                      required: true,
                    })}
                    className={classNames({
                      "p-invalid": !!formAddItem.formState.errors.amount,
                    })}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Registrar en el inventario</label>
                </div>
                <div className="col-12 md:col-4">
                  <Checkbox
                    onChange={(e) => formAddItem.setValue("isInventoried", e.checked)}
                    checked={isInventoried}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Consignación</label>
                </div>
                <div className="col-12 md:col-4">
                  <Checkbox
                    onChange={(e) => formAddItem.setValue("isConsignment", e.checked)}
                    checked={isConsignment}
                  />
                </div>
                <div className="col-12">
                  <Button label="Guardar" icon="pi pi-plus" onClick={formAddItem.handleSubmit(addOrEditService, onError)} />
                  <Button label="Atrás" icon="pi pi-times" onClick={onHideDialog} />
                </div>
              </div>
            </Dialog>
            <form onSubmit={handleSubmit(onSubmit, onError)} className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">Cédula</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="text"
                  {...register("idCard", {
                    required: true,
                  })}
                  className={classNames({
                    "p-invalid": !!errors.idCard,
                  })}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Nombre</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="text"
                  {...register("name", {
                    required: true,
                  })}
                  className={classNames({
                    "p-invalid": !!errors.name,
                  })}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Teléfono</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="number"
                  {...register("phone")}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Correo Electrónico</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="email"
                  {...register("email")}
                />
              </div>
              <div className="col-12">
                <Button label={titleButton} />
                <Button
                  type="button"
                  label="Regresar"
                  onClick={goBackToHome}
                />
                <Button
                  type="button"
                  label="Agregar articulo"
                  onClick={(e) => addSupply()}
                />
              </div>
            </form>
            {supplies.length > 0 && (
              <div>
                <h2>Listado de articulos</h2>
                <DataTable
                  paginator={true}
                  rows={10}
                  value={supplies}
                >
                  <Column field="code" header="Codigo" />
                  <Column field="description" header="Descripción" />
                  <Column field="amount" header="Precio" />
                  <Column body={({ isConsignment }) => isConsignment ? "Consignación" : "Contado"} header="Tipo" />
                  <Column body={(rowData, column) => {
                    return <div>
                      <Button
                        type="button"
                        icon="pi pi-pencil"
                        className="button-icon"
                        onClick={() => editSupply(rowData, column.rowIndex)}
                      />
                      <Button
                        type="button"
                        className="p-button-danger button-icon"
                        icon="pi pi-trash"
                        onClick={() => deleteSupply(column.rowIndex)}
                      />
                    </div>
                  }} header="Opciones" />
                </DataTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderForm;