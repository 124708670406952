import React from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { createHash } from "../../utils/security";
import { showSuccess, showWarningDialog } from "../../utils/message";
import { useLocation, useNavigate } from "react-router-dom";
import { classNames } from "primereact/utils";
import { useForm } from "react-hook-form";
import useService from "../../hooks/hook-service";

export const Profile = () => {
  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();

  const user = location.state.user;
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
    },
  });

  const doChangeingPassword = async (formData) => {
    const resultOldPassword = await createHash(formData.oldPassword);
    const resultNewPassword = await createHash(formData.newPassword);

    const data = {
      user,
      oldPassword: resultOldPassword,
      newPassword: resultNewPassword,
    };
    post("user/changepassword", data, () => {
      showSuccess("La contraseña se ha actualizado correctamente");
      reset();
    });
  };

  const goBackToHome = () => {
    if (isDirty === true) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios sin guardar dentro de la página, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            navigate("/dashboard");
          },
        }
      );
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Mi perfil</h1>
            </div>
            <div className="spacer20" />

            <h5>
              Nombre: {user.name} {user.lastName}
            </h5>
            <h5>Cédula: {user.idCard}</h5>
            <h5>Correo Electrónico: {user.email}</h5>
            <hr />
            <h3>Cambiar mi contraseña</h3>
            <form onSubmit={handleSubmit(doChangeingPassword)} className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">Antigua contraseña</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="password"
                  {...register("oldPassword", { required: true })}
                  className={classNames({
                    "p-invalid": !!errors.oldPassword,
                  })}
                />
                {errors.oldPassword &&
                  errors.oldPassword.type === "required" && (
                    <small className="p-error">
                      La antigua contraseña es requerida
                    </small>
                  )}
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Nueva contraseña</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="password"
                  {...register("newPassword", { required: true })}
                  className={classNames({
                    "p-invalid": !!errors.newPassword,
                  })}
                />
                {errors.newPassword &&
                  errors.newPassword.type === "required" && (
                    <small className="p-error">
                      La nueva contraseña es requerida
                    </small>
                  )}
              </div>
              <div className="md:col-12">
                <Button type="submit" label="Guardar" />
                <Button type="button" label="Regresar" onClick={goBackToHome} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
