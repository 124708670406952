/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import { formatCurrencyValue, formatDate } from "../../utils/format";

const AssetDetail = () => {
  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();

  const [asset, setAsset] = useState();

  useEffect(() => {
    post(
      "asset/get",
      {
        id: location.state.asset.id,
      },
      ({ asset }) => {
        setAsset(asset);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Detalle del Activo</h1>
            </div>
            <div className="spacer20" />
            <div className="grid">
              <div className="md:col-4">
                <label htmlFor="input">
                  Número de placa: {asset?.plateNumber}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">Descripción: {asset?.description}</label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">Ubicación: {asset?.localization}</label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Número de factura: {asset?.billNumber}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Costo: {formatCurrencyValue(asset?.cost)}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Fecha de compra: {formatDate(asset?.boughtTime)}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Número de referencia: {asset?.referenceNumber}
                </label>
              </div>
              {asset?.observation && (
                <div className="md:col-4">
                  <label htmlFor="input">
                    Observaciones: {asset?.observation}
                  </label>
                </div>
              )}
              <div className="md:col-4">
                <label htmlFor="input">
                  Precio de venta: {formatCurrencyValue(asset?.sellPrice)}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Tipo de activo: {asset?.type?.name}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Tipo de transferencia: {asset?.transferType?.name}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">Estado: {asset?.state?.name}</label>
              </div>
            </div>
            <div className="spacer20" />
            <Button
              type="button"
              label="Regresar"
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetDetail;
