/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import Voucher from "../../components/Vouchers/PaymentVoucher";
import useService from "../../hooks/hook-service";
import { Dialog } from "primereact/dialog";
import { TabPanel, TabView } from "primereact/tabview";
import ReceiptHeader from "../../components/ReceiptHeader";
import ReceiptDetails from "../../components/ReceiptDetails";
import WayToPayDetails from "../../components/WayToPayDetails";

const ReceiptDetail = () => {
  const [isVisibleVoucher, setIsVisibleVoucher] = useState(false);
  const [receipt, setReceipt] = useState({});

  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();

  useEffect(() => {
    const request = {
      id: location.state.receipt.id,
    };
    post("paymentReceived/get", request, ({ paymentReceived }) => {
      setReceipt(paymentReceived);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Detalle del Recibo</h1>
            </div>
            <div className="spacer20" />
            <Dialog
              header="Información"
              modal={true}
              onHide={() => setIsVisibleVoucher(false)}
              visible={isVisibleVoucher}
            >
              <TabView activeIndex={0}>
                <TabPanel header="Recibo">
                  <Voucher title="Comprobante de Pago" payment={receipt} />
                </TabPanel>
              </TabView>
            </Dialog>

            <ReceiptHeader receipt={receipt} />

            <div className="spacer20" />
            <ReceiptDetails title="Detalle" details={receipt.details} />

            <div className="spacer20" />
            <WayToPayDetails title="Pagos" waysToPay={receipt.waysToPay} />

            <div className="spacer20" />
            <div className="md:col-12">
              <Button
                type="button"
                label="Regresar"
                onClick={() => navigate(-1)}
              />
              <Button
                type="button"
                onClick={() => setIsVisibleVoucher(true)}
                label="Imprimir"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiptDetail;
