import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { showError, showWarningDialog } from "../../utils/message";
import GenericCalendar from "../../components/GenericComponents/GenericCalendar";
import GenericHour from "../../components/GenericComponents/GenericHour";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { convertStringToDate, editDate } from "../../utils/format";

const CongressForm = ({
  headerClass,
  title,
  titleButton,
  actionToExecute,
  congress,
}) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isDirty, errors },
  } = useForm();

  useEffect(() => {
    reset({ ...congress, date: editDate(congress.date) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [congress]);

  const goBackToHome = () => {
    if (isDirty === true) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios sin guardar dentro de la página, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            navigate(-1);
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  const onError = (errors) => {
    showError("Debe completar los campos");
  };

  const onSubmit = (formData) => {
    const congressToSave = {
      ...congress,
      date: convertStringToDate(formData.date),
      time: formData.time,
      place: formData.place,
      description: formData.description,
    };
    actionToExecute(congressToSave);
  };
  return (
    <>
      <div className={headerClass}>
        <h1>{title}</h1>
      </div>
      <div className="spacer20" />
      <form onSubmit={handleSubmit(onSubmit, onError)} className="grid">
        <div className="col-12 md:col-2">
          <label htmlFor="input">Fecha</label>
        </div>
        <div className="col-12 md:col-4">
          <Controller
            control={control}
            name="date"
            rules={{ required: true }}
            render={({ field }) => (
              <GenericCalendar
                id="date"
                {...field}
                className={classNames({
                  "p-invalid": !!errors.date,
                })}
              />
            )}
          />
        </div>
        <div className="col-12 md:col-2">
          <label htmlFor="input">Hora</label>
        </div>
        <div className="col-12 md:col-4">
          <Controller
            control={control}
            name="time"
            rules={{ required: true }}
            render={({ field }) => (
              <GenericHour
                id="time"
                {...field}
                className={classNames({
                  "p-invalid": !!errors.time,
                })}
              />
            )}
          />
        </div>

        <div className="col-12 md:col-2">
          <label htmlFor="input">Descripción</label>
        </div>
        <div className="col-12 md:col-4">
          <InputText
            type="text"
            {...register("description", {
              required: true,
            })}
            className={classNames({
              "p-invalid": !!errors.description,
            })}
          />
        </div>
        <div className="col-12 md:col-2">
          <label htmlFor="input">Lugar</label>
        </div>
        <div className="col-12 md:col-4">
          <InputText
            type="text"
            {...register("place", {
              required: true,
            })}
            className={classNames({
              "p-invalid": !!errors.place,
            })}
          />
        </div>
        <div className="md:col-12">
          <Button label={titleButton} />
          <Button type="button" label="Regresar" onClick={goBackToHome} />
        </div>
      </form>
    </>
  );
};

export default CongressForm;
