import React from "react";
import ProviderForm from "./ProviderForm";
import { useNavigate } from "react-router-dom";
import { showSuccess } from "../../utils/message";
import useService from "../../hooks/hook-service";

const ProviderCreate = () => {
  const defaultProvider = {};

  const navigate = useNavigate();
  const { post } = useService();

  const createProvider = (provider) => {
      post("provider/create", { provider : provider}, () => {
        showSuccess("El proveedor se ha creado correctamente");
        navigate(-1);
      });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <ProviderForm
              headerClass="custom-card-header-create"
              title="Crear Proveedor"
              titleButton="Guardar"
              actionToExecute={createProvider}
              provider={defaultProvider}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderCreate;
