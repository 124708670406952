/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React from "react";

export const AppTopbar = ({ onToggleMenu }) => {
  return (
    <div className="layout-topbar clearfix">
      <button
        className="layout-menu-button"
        onClick={onToggleMenu}
        style={{
          backgroundColor: "transparent",
          backgroundRepeat: "no-repeat",
          border: "none",
          overflow: "hidden",
          outline: "none",
        }}
      >
        <span className="pi pi-bars" />
      </button>
    </div>
  );
};
