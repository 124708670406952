/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React from "react";
const Dashboard = () => {
  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Bienvenido</h1>
            </div>

            <div className="spacer10" />
            <img
              src={process.env.PUBLIC_URL + "/login.jpg"}
              alt="Welcome"
              className="welcome-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
