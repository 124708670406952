/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React from "react";
import { Button } from "primereact/button";
import { formatCurrencyValue } from "../../utils/format";
import { useLocation, useNavigate } from "react-router-dom";

const FeeDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const fee = location.state.fee;

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Detalle de la Cuota</h1>
            </div>
            <div className="spacer20" />
            <div className="grid">
              <div className="md:col-4">
                <label htmlFor="input">Año: {fee.year}</label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Monto: {formatCurrencyValue(fee.amount)}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Tipo de Cuota: {fee.type.name}
                </label>
              </div>

              {fee.contractType && (
                <div className="md:col-4">
                  <label htmlFor="input">
                    Tipo de Contracto: {fee.contractType.name}
                  </label>
                </div>
              )}
            </div>
            <div className="spacer20" />
            <Button type="button" label="Regresar" onClick={goBack} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeDetail;
