/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { showWarningDialog } from "../../utils/message";
import classNames from "classnames";
import useService from "../../hooks/hook-service";

const FeeForm = ({
  headerClass,
  title,
  titleButton,
  actionToExecute,
  isEditing,
  fee
}) => {
  const navigate = useNavigate();
  const { manyPost } = useService();
  const {
    register,
    control,
    handleSubmit,
    formState: { isDirty, errors },
    reset,
    watch
  } = useForm();

  const typeId = watch("typeId");
  const [types, setTypes] = useState([]);
  const [contractTypes, setContractTypes] = useState([]);

  useEffect(() => {
    const request = [
      {
        url: "catalog/list",
        body: { catalogType: "1000" },
      },
      {
        url: "catalog/list",
        body: { catalogType: "1010" },
      },
    ];

    manyPost(request, (result) => {
      setContractTypes(result[0].catalogs);
      setTypes(result[1].catalogs);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fee) {
      reset({
        year: fee.year,
        amount: fee.amount,
        typeId: fee.type?.id,
        contractTypeId: fee.contractType?.id
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fee]);

  const submit = (data) => {
    const fee = {
      year: data.year,
      amount: data.amount,
      type: {
        id: data.typeId
      }
    }

    if (data.contractTypeId) {
      fee.contractType = {
        id: data.contractTypeId
      }
    }

    actionToExecute(fee);
  };

  const goBackToHome = () => {
    if (isDirty === true) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios sin guardar dentro de la página, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            navigate(-1);
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className={headerClass}>
              <h1>{title}</h1>
            </div>
            <div className="spacer20" />
            <form onSubmit={handleSubmit(submit)} className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">Año</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="number"
                  disabled={isEditing}
                  min={1}
                  {...register("year", {
                    required: true,
                  })}
                  className={classNames({
                    "p-invalid": !!errors.year,
                  })}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Monto</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="number"
                  min={1}
                  {...register("amount", {
                    required: true,
                  })}
                  className={classNames({
                    "p-invalid": !!errors.amount,
                  })}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Tipo de Cuota</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="typeId"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Dropdown
                      id="typeId"
                      disabled={isEditing}
                      {...field}
                      className={classNames({
                        "p-invalid": !!errors.typeId,
                      })}
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Tipo"
                      options={types}
                    />
                  )}
                />
              </div>
              {typeId === 40 && (
                <>
                  <div className="col-12 md:col-2">
                    <label htmlFor="input">Tipo de Contrato</label>
                  </div>
                  <div className="col-12 md:col-4">
                    <Controller
                      control={control}
                      name="contractTypeId"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Dropdown
                          id="contractTypeId"
                          {...field}
                          disabled={isEditing}
                          className={classNames({
                            "p-invalid": !!errors.contractTypeId,
                          })}
                          optionLabel="name"
                          optionValue="id"
                          placeholder="Tipo de Contrato"
                          options={contractTypes}
                        />
                      )}
                    />
                  </div>
                </>
              )}
              <div className="md:col-12">
                <Button label={titleButton} />
                <Button
                  type="button"
                  label="Regresar"
                  onClick={goBackToHome}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeForm;