/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import _ from "lodash";
import { formatDate } from "../../utils/format";
import { useLocation, useNavigate } from "react-router-dom";
import createExcel from "../../utils/createExcel";
import AffiliateHeader from "../../components/AffiliateHeader";
import useService from "../../hooks/hook-service";
import BeneficiaryDetails from "../../components/BeneficiaryDetails";

const AffiliateDetail = () => {
  const [affiliate, setAffiliate] = useState({});

  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();

  useEffect(() => {
    const data = {
      id: location.state.affiliate.id,
    };
    post("affiliate/get", data, ({ affiliate }) => setAffiliate(affiliate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const exportHistoryStatus = () => {
    const data = {
      id: affiliate.id,
    };
    post("affiliate/status", data, (data) => {
      let result = [
        {
          columns: [
            { title: "Cédula", width: { width: 20 } },
            { title: "Nombre", width: { width: 25 } },
            { title: "Apellidos", width: { width: 25 } },
            { title: "Género", width: { width: 20 } },
            { title: "Teléfono", width: { width: 20 } },
            { title: "Estado", width: { width: 20 } },
            { title: "Tipo de Socio", width: { width: 30 } },
            { title: "Número de contrato", width: { width: 20 } },
          ],
          data: [],
        },
        {
          xSteps: 0,
          ySteps: 1,
          columns: [
            { title: "Número", width: { width: 20 } },
            { title: "Monto recibido", width: { width: 25 } },
            { title: "Monto pagado", width: { width: 25 } },
            { title: "Fecha de pago", width: { width: 20 } },
            { title: "Tipo de transferencia", width: { width: 20 } },
          ],
          data: [],
        },
      ];

      let data1 = [];

      data1.push({
        value: data.contract.affiliate.idCard ?? "",
      });
      data1.push({
        value: data.contract.affiliate.firstName ?? "",
      });
      data1.push({
        value: data.contract.affiliate.lastName ?? "",
      });
      data1.push({
        value: data.contract.affiliate.genre.name
          ? data.contract.affiliate.genre.name
          : "",
      });
      data1.push({
        value: data.contract.affiliate.celphone ?? "",
      });
      data1.push({
        value: data.contract.affiliate.status.name ?? "",
      });
      data1.push({
        value: data.contract.type.name ?? "",
      });
      data1.push({
        value: data.contract.affiliate.number ?? "",
      });

      result[0].data.push(data1);

      let totalAmountReceived = 0;
      let totalPaidAmount = 0;
      for (
        let index = 0;
        index < data.contract.affiliate.paymentReceiveds.length;
        index++
      ) {
        let tempArray = [];
        let element = data.contract.affiliate.paymentReceiveds[index];

        let payDate = formatDate(element.creationDate);

        tempArray.push({ value: element.number ?? "" });
        tempArray.push({
          value: element.amountReceived ?? "",
          style: { numFmt: "₡0.00" },
        });

        //sum of amountReceived
        totalAmountReceived += element.amountReceived;

        tempArray.push({
          value: element.paidAmount ?? "",
          style: { numFmt: "₡0.00" },
        });

        //sum of piadAmount
        totalPaidAmount += element.paidAmount;

        tempArray.push({ value: payDate });
        tempArray.push({
          value: element.transferType.name ? element.transferType.name : "",
        });
        result[1].data.push(tempArray);
      }

      let totalAmountsArray = [];
      totalAmountsArray.push(
        { value: "" },
        { value: totalAmountReceived },
        { value: totalPaidAmount }
      );
      result[1].data.push(totalAmountsArray);

      createExcel("Historial de Cuenta a Excel", result);
    });
  };

  const exportStatus = () => {
    const data = {
      id: affiliate.id,
    };
    post("affiliate/status", data, (data) => {
      let arrayByYear = [];
      var groupedByDateData = _.groupBy(
        data.contract.affiliate.paymentReceiveds.reverse(),
        function (item) {
          if (!arrayByYear.includes(item.payDate.substring(0, 4))) {
            arrayByYear.push(item.payDate.substring(0, 4));
          }

          return item.payDate.substring(0, 4);
        }
      );

      let result = [
        {
          columns: [
            { title: "Cédula", width: { width: 30 } },
            { title: "Nombre", width: { width: 25 } },
            { title: "Apellidos", width: { width: 25 } },
            { title: "Género", width: { width: 20 } },
            { title: "Teléfono", width: { width: 20 } },
            { title: "Estado", width: { width: 20 } },
            { title: "Tipo de Socio", width: { width: 30 } },
            { title: "Número de contrato", width: { width: 20 } },
          ],
          data: [],
        },
        {
          xSteps: 0,
          ySteps: 1,
          columns: [
            { title: "Fecha de pago", width: { width: 30 } },
            { title: "Monto pagado", width: { width: 25 } },
          ],
          data: [],
        },
      ];

      let data1 = [];

      data1.push({
        value: data.contract.affiliate.idCard ?? "",
      });
      data1.push({
        value: data.contract.affiliate.firstName ?? "",
      });
      data1.push({
        value: data.contract.affiliate.lastName ?? "",
      });
      data1.push({
        value: data.contract.affiliate.genre.name ?? "",
      });
      data1.push({
        value: data.contract.affiliate.celphone ?? "",
      });
      data1.push({
        value: data.contract.affiliate.status.name ?? "",
      });
      data1.push({
        value: data.contract.type.name ?? "",
      });
      data1.push({
        value: data.contract.affiliate.number ?? "",
      });

      result[0].data.push(data1);

      const monthNames = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Setiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      arrayByYear.forEach((element) => {
        let tempArrayByYear = groupedByDateData[element];
        let sum = 0;
        let dateName = "";
        let firstDate = "";
        let lastDate = "";
        let tempArray = [];
        for (let index = 0; index < tempArrayByYear.length; index++) {
          const x = tempArrayByYear[index];

          if (index === 0) {
            firstDate +=
              monthNames[new Date(x.payDate).getMonth()] + " " + element + "-";
          } else if (index === tempArrayByYear.length - 1) {
            lastDate +=
              monthNames[new Date(x.payDate).getMonth()] + " " + element;
          }
          sum += x.paidAmount;
        }
        dateName = firstDate.concat(lastDate);
        tempArray.push({ value: dateName ?? "" });
        tempArray.push({
          value: sum ?? "",
          style: { numFmt: "₡0.00" },
        });

        result[1].data.push(tempArray);
      });

      createExcel("Estado de Cuenta a Excel", result);
    });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Detalle del Asociado</h1>
            </div>
            <div className="spacer20" />
            <div className="grid">
              <div className="col-12 md:col-12">
                <Button
                  type="button"
                  label="Exportar Historial de Cuenta a Excel"
                  onClick={exportHistoryStatus}
                  style={{
                    marginBottom: "5px",
                    width: "auto",
                    padding: "8px",
                    backgroundColor: "#18f231",
                    borderColor: "#18f231",
                  }}
                />
              </div>
              <div className="col-12 md:col-12">
                <Button
                  type="button"
                  label="Exportar Estado de Cuenta a Excel"
                  onClick={exportStatus}
                  style={{
                    marginBottom: "5px",
                    width: "auto",
                    padding: "8px",
                    backgroundColor: "#18f231",
                    borderColor: "#18f231",
                  }}
                />
              </div>
              <AffiliateHeader affiliate={affiliate} />
            </div>
            <div className="spacer20" />
            <BeneficiaryDetails beneficiaries={affiliate.beneficiaries} />
            <div className="spacer20" />
            <Button type="button" label="Regresar" onClick={goBack} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffiliateDetail;
