import { Tree } from "primereact/tree";
import React, { useEffect, useState } from "react";

const PermissionDetailTree = ({ products }) => {
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const permissions = [];
    products.forEach((element) => {
      const children = [];
      element.options.forEach((option) => {
        const actions = [];
        if (option.actions.length > 0) {
          option.actions.forEach((act) => {
            const action = {
              key: act.actionId,
              label: act.name,
              data: "",
              icon: "",
            };
            actions.push(action);
          });
        }
        const child = {
          key: option.productId,
          label: option.name,
          data: "",
          icon: "",
          children: actions,
        };
        children.push(child);
      });
      const ele = {
        key: element.productId,
        label: element.name,
        data: "",
        icon: "",
        children: children,
      };
      permissions.push(ele);
    });
    setPermissions(permissions);
  }, [products]);

  return permissions.length === 0 ? (
    <center>
      <h5>No se encontraron permisos asignados</h5>
    </center>
  ) : (
    <Tree value={permissions} />
  );
};

export default PermissionDetailTree;
