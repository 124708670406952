/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import BillHeader from "../../components/BillHeader";
import {
  showError,
  showSuccess,
  showWarning,
  showWarningDialog,
} from "../../utils/message";

const BillReduceInventory = () => {
  const [bill, setBill] = useState({});
  const [inventoryList, setInventoryList] = useState([]);
  const [inventoryItems, setInventoryItems] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const { post, manyPost } = useService();

  useEffect(() => {
    const request = [
      {
        url: "bill/get",
        body: {
          id: location.state.bill.id,
        },
      },
      {
        url: "inventory/list",
        body: {
          providerName: null,
          description: null,
        },
      },
    ];

    manyPost(request, (result) => {
      setBill(result[0].bill);

      if (result[1].inventoryItems.length === 0) {
        showWarning("No se encontraron registros");
      }
      setInventoryList(result[1].inventoryItems);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addItem = (itemSelected, index) => {
    const item = inventoryItems.find((x) => x.id === itemSelected.id);
    if (item) {
      item.quantity = item.quantity + 1;
    } else {
      const newItem = { ...itemSelected };
      newItem.quantity = 1;
      inventoryItems.push(newItem);
    }
    setInventoryItems([...inventoryItems]);

    itemSelected.quantity = itemSelected.quantity - 1;
    inventoryList[index] = itemSelected;
    setInventoryList([...inventoryList]);
  };

  const deleteItem = (itemSelected, index) => {
    const itemL = inventoryList.find((x) => x.id === itemSelected.id);
    itemL.quantity = itemL.quantity + 1;
    if (itemSelected.quantity === 1) {
      inventoryItems.splice(index, 1);
    } else {
      itemSelected.quantity = itemSelected.quantity - 1;
      inventoryItems[index] = itemSelected;
    }
    setInventoryItems([...inventoryItems]);
  };

  const save = () => {
    if (inventoryItems.length === 0) {
      showError("Debe agregar al menos un articulo");
      return;
    }

    const request = {
      bill: bill,
      inventoryItems: inventoryItems,
    };

    post("bill/reduce", request, () => {
      showSuccess("Se agregaron los articulos correctamente");
      navigate(-1);
    });
  };

  const askToGoBack = () => {
    if (inventoryItems.length > 0) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios sin guardar dentro de la página, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            navigate(-1);
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Detalle de la Factura</h1>
            </div>
            <div className="spacer20" />
            <div className="grid">
              <BillHeader bill={bill} />
              <div className="spacer20" />
              <div className="col-12">
                <h2>Articulos para agregar</h2>
                <DataTable
                  paginator={true}
                  rows={10}
                  value={inventoryList}
                  emptyMessage="No se encontraron registros"
                >
                  <Column field="code" header="Código" />
                  <Column field="description" header="Descripción" />
                  <Column field="quantity" header="Cantidad" />
                  <Column
                    body={(rowData, column) => {
                      if (rowData.quantity >= 1) {
                        return (
                          <Button
                            type="button"
                            className="button-icon"
                            icon="pi pi-plus"
                            onClick={() => addItem(rowData, column.rowIndex)}
                            tooltip="Seleccionar"
                          />
                        );
                      }
                      return "";
                    }}
                    header="Agregar Articulo"
                  />
                </DataTable>
              </div>
              <div className="spacer20" />
              {inventoryItems.length > 0 && (
                <div className="col-12">
                  <h2>Articulos Agregados</h2>
                  <DataTable
                    paginator={true}
                    rows={10}
                    value={inventoryItems}
                    emptyMessage="No se encontraron registros"
                  >
                    <Column field="code" header="Código" />
                    <Column field="description" header="Descripción" />
                    <Column field="quantity" header="Cantidad" />
                    <Column
                      body={(rowData, column) => {
                        return (
                          <Button
                            type="button"
                            className="p-button-danger button-icon"
                            icon="pi pi-trash"
                            onClick={() => deleteItem(rowData, column.rowIndex)}
                            tooltip="Seleccionar"
                          />
                        );
                      }}
                      header="Eliminar Articulo"
                    />
                  </DataTable>
                </div>
              )}
            </div>
            <div className="spacer20" />
            <Button type="button" label="Guardar" onClick={save} />
            <Button type="button" label="Regresar" onClick={askToGoBack} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillReduceInventory;
