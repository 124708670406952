/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import { AffiliateFormAndUpdate } from "../../components/AffiliateForm";

const AffiliateEdit = () => {
  const { post } = useService();
  const [affiliate, setAffiliate] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    post(
      "affiliate/get",
      {
        id: location.state.affiliate.id,
      },
      ({ affiliate }) => {
        setAffiliate(affiliate);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBackToHome = () => {
    navigate(-1);
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Editar Información de Afiliado</h1>
            </div>
            <div className="spacer20" />
            <AffiliateFormAndUpdate
              goBack={goBackToHome}
              affiliate={affiliate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffiliateEdit;
