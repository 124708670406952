import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import { showSuccess } from "../../utils/message";
import CongressForm from "./CongressForm";

const CongressEdit = () => {
  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();
  const defaultCongress = {
    ...location.state.congress,
  };

  const editCongress = (congress) => {
    post("congress/update", { congress }, () => {
      showSuccess("La asamblea se ha actualizado correctamente");
      navigate(-1);
    });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <CongressForm
              headerClass="custom-card-header-edit"
              title="Editar Asamblea"
              titleButton="Editar"
              actionToExecute={editCongress}
              congress={defaultCongress}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CongressEdit;
