/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { showSuccess } from "../../utils/message";
import ServiceForm from "./ServiceForm";
import useService from "../../hooks/hook-service";

const ServiceEdit = () => {
  const [service, setService] = useState({});
  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();

  useEffect(() => {
    post("service/get", {
      id: location.state.service.id
    }, ({ service }) => {
      setService(service);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editService = (service) => {
    const data = {
      service: {
        id: location.state.service.id,
        ...service,
      },
    };

    post("service/update", data, () => {
      showSuccess("El Servicio se ha actualizado correctamente");
      navigate(-1);
    });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <ServiceForm
              headerClass="custom-card-header-edit"
              title="Editar Rol"
              titleButton="Editar"
              actionToExecute={editService}
              service={service}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceEdit;
