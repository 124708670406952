import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Controller, useForm } from "react-hook-form";
import { Paginator } from "primereact/paginator";
import {
  convertStringToDate,
  formatDate,
} from "../../utils/format";
import { Dropdown } from "primereact/dropdown";
import GenericCalendar from "../../components/GenericComponents/GenericCalendar";
import { showWarning } from "../../utils/message";
import useService from "../../hooks/hook-service";

const LogList = () => {
  const [logs, setLogs] = useState([]);
  const [users, setUsers] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState(0);
  const [sizePage, setSizePage] = useState(10);

  const { post } = useService();

  const defaultValues = {
    sizePage: sizePage,
    startDate: formatDate(new Date()),
    finalDate: formatDate(new Date()),
  };
  const { register, control, getValues } = useForm({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    post("user/search", {}, ({ users }) => setUsers(users));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resfreshData = () => {
    const startDate = getValues("startDate");
    const finalDate = getValues("finalDate");
    const idUser = getValues("idUser");
    const request = {
      sizePage: getValues("sizePage"),
      page: page,
      type: 2,
      startDate: convertStringToDate(startDate),
      finalDate: convertStringToDate(finalDate),
      user: {
        id: idUser,
      },
    };

    post("log/search", request, ({ logs, rows }) => {
      if (rows === 0) {
        showWarning("No se encontraron registros");
      }

      setLogs(logs);
      setTotalRecords(rows);
    });
  };

  useEffect(() => {
    resfreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page);
  };

  const search = () => {
    if (page === 0) {
      resfreshData();
    } else {
      setFirst(0);
      setPage(0);
      const size = getValues("sizePage");
      setSizePage(size);
    }
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Bitácora</h1>
            </div>
            <div className="spacer20" />

            <div className="spacer10" />
            <label id="searchLabel">Buscar por</label>
            <div className="spacer20" />
            <div className="col-12">
              <div className="grid">
                <label className="col-12 mb-2 md:col-2 md:mb-0">Usuarios</label>
                <div className="col-12 md:col-4">
                  <Controller
                    control={control}
                    name="idUser"
                    render={({ field }) => (
                      <Dropdown
                        id="idUser"
                        optionLabel="name"
                        optionValue="id"
                        {...field}
                        placeholder="Usuario"
                        options={users}
                      />
                    )}
                  />
                </div>
                <label className="col-12 mb-2 md:col-2 md:mb-0">
                  Fecha de inicio
                </label>
                <div className="col-12 md:col-4">
                  <Controller
                    control={control}
                    name="startDate"
                    render={({ field }) => (
                      <GenericCalendar id="startDate" {...field} />
                    )}
                  />
                </div>
                <label className="col-12 mb-2 md:col-2 md:mb-0">
                  Fecha de finalización
                </label>
                <div className="col-12 md:col-4">
                  <Controller
                    control={control}
                    name="finalDate"
                    render={({ field }) => (
                      <GenericCalendar id="finalDate" {...field} />
                    )}
                  />
                </div>
                <label className="col-12 mb-2 md:col-2 md:mb-0">
                  Cantidad de registros a mostrar
                </label>
                <div className="col-12 md:col-4">
                  <InputText type="number" min={1} {...register("sizePage")} />
                </div>
              </div>
              <div className="md:col-12">
                <Button label="Buscar" onClick={() => search()} />
              </div>
            </div>
            <DataTable value={logs} emptyMessage="No se encontraron registros">
              <Column body={(row) => formatDate(row.date)} header="Fecha" />
              <Column field="user.idCard" header="Cedula" />
              <Column field="user.name" header="Nombre" />
              <Column field="user.lastName" header="Apellidos" />
              <Column field="originIP" header="Ip de origen" />
              <Column
                field="description"
                header="Acción"
                className="text-column"
              />
            </DataTable>
            <Paginator
              first={first}
              totalRecords={totalRecords}
              rows={sizePage}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogList;
