import { Button } from "primereact/button";
import { formatCurrencyValue, formatDate } from "../../utils/format";
import React, { forwardRef, useRef } from "react";
import { useReactToPrint } from "react-to-print";

const ComponentToPrint = forwardRef(({ title, payment }, ref) => {
  const style = {
    table: {
      border: "1px solid black",
      borderCollapse: "collapse"
    },
    td: {
      border: "1px solid black",
      borderCollapse: "collapse"
    },
    th: {
      border: "1px solid black",
      borderCollapse: "collapse"
    },
  };

  const details = payment.details.map((detail) =>
    <tr><td style={{
      ...style.td,
      width: "40%"
    }}>
      {detail.description}
    </td>
      <td style={{
        ...style.td,
        width: "10%"
      }}>
        {detail.quantity}
      </td>
      <td style={{
        ...style.td,
        width: "25%"
      }}>
        {formatCurrencyValue(detail.unitPrice)}
      </td>
      <td style={{
        ...style.td,
        width: "25%"
      }}>
        {formatCurrencyValue(detail.totalPrice)}
      </td></tr>
  );

  const waysToPay = payment.waysToPay.map((wayToPay) =>
    <tr><td style={{
      ...style.td,
      width: "30%"
    }}>
      {formatCurrencyValue(wayToPay.amount)}
    </td>
      <td style={{
        ...style.td,
        width: "40%"
      }}>
        {wayToPay.transferType.name}
      </td>
      <td style={{
        ...style.td,
        width: "30%"
      }}>
        {wayToPay.referenceNumber}
      </td></tr>
  );

  return (
    <div ref={ref} style={{ width: "65mm" }}>
      <div className="grid grid-nogutter">
        <div className="col-12" style={{ textAlign: "center" }}>
          Asociación Funeraria Nuestra Señorra de Ujarrás
        </div>
        <div className="col-12" style={{ textAlign: "center" }}>
          <label htmlFor="input">Cédula Jurídica: </label>3002087625
        </div>
        <div className="col-12" style={{ textAlign: "center" }}>
          <label htmlFor="input">Correo Electrónico: </label>funerariaujarras@hotmail.com
        </div>
        <div className="col-12" style={{ textAlign: "center" }}>
          <label htmlFor="input">SINPE Móvil: </label> 8825-6667 Asociación Funeraria
        </div>
        <div className="col-12" style={{ textAlign: "center" }}>
          <label htmlFor="input">Teléfono Emergencias: </label> 8825-6667
        </div>
        <div className="col-12" style={{ textAlign: "center" }}>
          <label htmlFor="input">Oficina: </label>2574-4065 / 2574-6687
        </div>
        <div className="spacer10" />
      </div>
      <h1 className="title-receipt">{title}</h1>
      <div className="spacer10" />
      {payment.affiliate && (
        <div>
          <div className="grid">
            <div className="spacer10" />
            <div className="col-12">
              <label htmlFor="input">Fecha: </label> {formatDate(payment.creationDate, "DD-MM-YYYY, hh:mm:ss a")}
            </div>
            <div className="col-12">
              <label htmlFor="input">Realizado por: </label>
              {payment.userName}
            </div>
            {payment.appliedBy && (
              <div className="col-12">
                <label htmlFor="input">Recibido: </label>
                {payment.appliedBy}
              </div>
            )}
            <div className="col-12">
              <label htmlFor="input">N° Recibo: </label> {payment.number}
            </div>
            <div className="col-12">
              <label htmlFor="input">Cedula: </label>
              {payment.affiliate.idCard}
            </div>
            {payment.affiliate.number !== 0 && (
              <div className="col-12">
                <label htmlFor="input">N° Asociado: </label>
                {payment.affiliate.number}
              </div>
            )}
            <div className="col-12">
              <label htmlFor="input">Nombre: </label>
              {payment.affiliate.lastName} {payment.affiliate.firstName}
            </div>
            {payment.affiliate.amountOwed && (
              <div className="col-12">
                <label htmlFor="input">Monto Adeudado</label>
                {payment.affiliate.amountOwed}
              </div>
            )}
          </div>
          <div className="spacer20" />
          <table style={{ ...style.table, width: "65mm" }}>
            <thead>
              <th style={{
                ...style.th,
                width: "40%"
              }}>
                Describre
              </th>
              <th style={{
                ...style.th,
                width: "10%"
              }}>
                Can
              </th>
              <th style={{
                ...style.th,
                width: "25%"
              }}>
                Unitario
              </th>
              <th style={{
                ...style.th,
                width: "25%"
              }}>
                Total
              </th>
            </thead>
            <tbody>
              {details}
            </tbody>
          </table>

          <div className="spacer20" />
          Pagos
          <table style={{ ...style.table, width: "65mm" }}>
            <thead>
              <th style={{
                ...style.th,
                width: "30%"
              }}>
                Monto
              </th>
              <th style={{
                ...style.th,
                width: "40%"
              }}>
                T. Transf
              </th>
              <th style={{
                ...style.th,
                width: "30%"
              }}>
                N. Referencia
              </th>
            </thead>
            <tbody>
              {waysToPay}
            </tbody>
          </table>
          {payment.affiliate && (
            <>
              <div className="col-12">
                <label htmlFor="input">
                  Total Recibo: {formatCurrencyValue(payment.amountReceived)}
                </label>
              </div>
              {payment.observations && (
                <div className="col-12" style={{ textAlign: "center" }}>
                  {payment.observations}
                </div>
              )}
              <div className="col-12" style={{ textAlign: "center" }}>
                <b>Evite Penalidades</b>
                <p>
                  Los asociados que adeudan seis cuotas y requieran utilizar los servicios fúnebres indicados de esta Asociación,  deberán cancelar de forma conjunta las cuotas atrasadas y una penalización de ₡50.000 colones.
                  Reglamento. Asociación Funeraria Nuestra Señora de Ujarrás
                </p>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
});

const Voucher = (props) => {
  const componentRef = useRef();
  const pageStyle = `
  @page {
    size: 80mm 300mm;   /* auto is the initial value */
    margin: 0.2cm 0.2cm 0.2cm 0.2cm;  /* this affects the margin in the printer settings */
  }

  @media print {
  html, body {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}
`;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
  });

  return (
    <>
      <ComponentToPrint ref={componentRef} {...props} />
      <div className="spacer10" />
      <div className="grid">
        <Button
          className="col-2"
          type="button"
          label="Imprimir"
          icon="pi pi-print"
          onClick={handlePrint}
        />
      </div>
    </>
  );
};

export default Voucher;