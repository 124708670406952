/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { showSuccess } from "../../utils/message";
import FeeForm from "./FeeForm";
import useService from "../../hooks/hook-service";

const FeeEdit = () => {
  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();

  const editFee = (formData) => {
    const data = {
      fee: {
        ...formData,
      },
    };

    post("fee/update", data, () => {
      showSuccess("La couta se ha actualizado correctamente");
      navigate(-1);
    });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <FeeForm
              headerClass="custom-card-header-edit"
              title="Editar Couta"
              titleButton="Editar"
              actionToExecute={editFee}
              isEditing={true}
              fee={location.state.fee}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeEdit;
