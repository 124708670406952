import { Button } from "primereact/button";
import React, { forwardRef, useRef } from "react";
import { useReactToPrint } from "react-to-print";
const ComponentToPrint = forwardRef(({ document }, ref) => {
  return (
    <div ref={ref}>
      <div
        dangerouslySetInnerHTML={{
          __html: document,
        }}
      />
    </div>
  );
});

const DocumentToPrint = ({ document }) => {
  const componentRef = useRef();
  const pageStyle = `
  @page {
    size: A4;
    margin: 10mm;
  }

  @media print {
  html, body {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}
`;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
  });

  return (
    <>
      <ComponentToPrint ref={componentRef} document={document} />
      <div className="spacer10" />
      <div className="grid">
        <Button
          className="col-2"
          type="button"
          label="Imprimir"
          icon="pi pi-print"
          onClick={handlePrint}
        />
      </div>
    </>
  );
};

export default DocumentToPrint;
