/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Allan Moya <amoya@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { showError } from "../../utils/message";
import ActionToolbar from "../../components/ActionsToolbar";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";

const ReportList = () => {
  const [reports, setReports] = useState([]);
  const [report, setReport] = useState({});
  const [title, setTitle] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const { post } = useService();
  const actions = location.state.actions;

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = () => {
    let data = {
      title: title,
    };
    post("report/search", data, ({ reports }) => {
      if (reports.length === 0) showError("No se encontraron registros");

      setReports(reports);
    });
  };

  const disableAction = (actionId) => {
    return Object.getOwnPropertyNames(report).length === 0;
  };

  const select = (e) => {
    if (e.value) {
      setReport(e.value);
    } else {
      setReport({});
    }
  };

  const redirect = (route) => {
    // eslint-disable-next-line
    switch (route) {
      case "/reportDetail":
        navigate(route, {
          state: { report: report },
        });
        break;
    }
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Listado de Reportes</h1>
            </div>
            <ActionToolbar
              actions={actions}
              checkIfHaveToDisable={disableAction}
              executeAction={redirect}
            />
            <label id="searchLabel" htmlFor="input">
              Buscar por{" "}
            </label>
            <div className="spacer20" />
            <div className="grid">
              <div className="col-12 md:col-1">
                <label htmlFor="input">Título:</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="md:col-12">
                <Button label="Buscar" onClick={search} />
              </div>
            </div>
            {reports.length > 0 && (
              <div>
                <DataTable
                  paginator={true}
                  rows={10}
                  value={reports}
                  emptyMessage="No se encontraron registros"
                  selection={report}
                  onSelectionChange={select}
                >
                  <Column selectionMode="single" style={{ width: "4em" }} />
                  <Column field="title" header="Nombre" />
                  <Column field="subtitle" header="Descripción" />
                </DataTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportList;
