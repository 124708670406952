/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import BeneficiaryDetails from "../../components/BeneficiaryDetails";
import ContractHeader from "../../components/ContractHeader";

const ContractDetail = () => {
  const [contract, setContract] = useState({});

  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();

  useEffect(() => {
    const data = {
      id: location.state.contract.id,
    };
    post("contract/get", data, ({ contract }) => setContract(contract));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Detalle del Contrato</h1>
            </div>
            <div className="spacer20" />
            {contract.affiliate && (
              <>
                <div className="grid">
                  <ContractHeader contract={contract} />
                </div>
                <div className="spacer10" />
                <BeneficiaryDetails
                  beneficiaries={contract.affiliate.beneficiaries}
                  contractType={contract.type.id}
                />
              </>
            )}
            <div className="spacer20" />
            <Button type="button" label="Regresar" onClick={goBack} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractDetail;
