import { createSlice } from "@reduxjs/toolkit";

// auth Manager Slice

const loadingManagerSlice = createSlice({
  name: "loadingManager",
  initialState: {
    value: 0,
  },
  reducers: {
    showLoader: (state) => {
      state.value = state.value + 1;
    },
    hideLoader: (state) => {
      state.value = state.value - 1;
    },
  },
});

export const { showLoader, hideLoader } = loadingManagerSlice.actions;

export default loadingManagerSlice.reducer;
