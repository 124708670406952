/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Allan Moya <amoya@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import { useForm } from "react-hook-form";
import { showWarning } from "../../utils/message";
import ActionToolbar from "../../components/ActionsToolbar";
import { formatDate } from "../../utils/format";
import createExcel from "../../utils/createExcel";
import pdf from "../../utils/pdf";

const BeneficiaryList = () => {
  const [beneficiary, setBeneficiary] = useState({});
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState(0);
  const [sizePage, setSizePage] = useState(10);

  const defaultValues = {
    sizePage: sizePage,
  };
  const { register, getValues } = useForm({
    defaultValues: defaultValues,
  });

  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();
  const actions = location.state.actions;

  useEffect(() => {
    resfreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const doRequest = (request, callback) => {
    post("beneficiary/search", request, ({ rows, beneficiaries }) => {
      if (rows === 0) {
        showWarning("No se encontraron registros");
      }

      callback(rows, beneficiaries);
    });
  };

  const search = () => {
    if (page === 0) {
      resfreshData();
    } else {
      setFirst(0);
      setPage(0);
      const size = getValues("sizePage");
      setSizePage(size);
    }
  };

  const exportToExcel = () => {
    const request = {
      idCard: getValues("idCard"),
      firstName: getValues("firstName"),
      lastName: getValues("lastName"),
      page: 0,
    };

    doRequest(request, (rows, beneficiaries) => {
      if (rows === 0) return;

      let result = {
        columns: [
          { title: "Nombre", width: { width: 20 } },
          { title: "Apellidos", width: { width: 20 } },
          { title: "Cédula", width: { width: 15 } },
          { title: "Fecha de Nacimiento", width: { width: 20 } },
          { title: "Parentesco", width: { width: 20 } },
          { title: "Estado", width: { width: 20 } },
          { title: "Fecha de fallecimiento", width: { width: 20 } },
        ],
        data: [],
      };

      beneficiaries.forEach((element) => {
        result.data.push([
          element.firstName ?? "",
          element.lastName ?? "",
          element.idCard ?? "",
          formatDate(element.birthDate),
          element.kinship.name ?? "",
          element.state.name ?? "",
          formatDate(element.diedDate) ?? "",
        ]);
      });

      createExcel("Beneficiarios", [result]);
    });
  };

  const exportToPdf = () => {
    const request = {
      idCard: getValues("idCard"),
      firstName: getValues("firstName"),
      lastName: getValues("lastName"),
      page: 0,
    };

    doRequest(request, (rows, beneficiaries) => {
      if (rows === 0) return;

      const body = [];
      beneficiaries.forEach((element) => {
        body.push([
          element.firstName ?? "",
          element.lastName ?? "",
          element.idCard ?? "",
          formatDate(element.birthDate),
          element.kinship.name ?? "",
          element.state.name ?? "",
          formatDate(element.diedDate) ?? "",
        ]);
      });

      const data = {
        head: [
          [
            "Nombre",
            "Apellidos",
            "Cédula",
            "Fecha de nacimiento",
            "Parentesco",
            "Estado",
            "Fecha de fallecimiento",
          ],
        ],
        body: body,
        startY: 25,
        bodyStyles: { valign: "top" },
        styles: { overflow: "linebreak", cellWidth: "wrap" },
        columnStyles: {
          0: { cellWidth: 45 },
          1: { cellWidth: 45 },
        },
      };

      pdf.createPDF("l", data, "Beneficiarios.pdf", "Reporte de Beneficiarios");
    });
  };

  const resfreshData = () => {
    const request = {
      idCard: getValues("idCard"),
      firstName: getValues("firstName"),
      lastName: getValues("lastName"),
      sizePage: getValues("sizePage"),
      page: page,
    };

    doRequest(request, (rows, beneficiaries) => {
      setBeneficiaries(beneficiaries);
      setTotalRecords(rows);
      setBeneficiary({});
    });
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page);
  };

  const redirect = (route) => {
    switch (route) {
      case "/beneficiaryDetail":
      case "/beneficiaryEdit":
        navigate(route, {
          state: { beneficiary: beneficiary },
        });
        break;
      case "/exportExcel":
        exportToExcel();
        break;
      case "/exportPDF":
        exportToPdf();
        break;
      default:
        break;
    }
  };

  const disableAction = () => {
    return Object.getOwnPropertyNames(beneficiary).length === 0;
  };

  const select = (e) => {
    if (e.value) {
      setBeneficiary(e.value);
    } else {
      setBeneficiary({});
    }
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Listado de Beneficiarios</h1>
            </div>
            <ActionToolbar
              actions={actions}
              checkIfHaveToDisable={disableAction}
              executeAction={redirect}
            />
            <label id="searchLabel" htmlFor="input">
              Buscar por{" "}
            </label>
            <div className="spacer20" />
            <div className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">Número de Cédula</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("idCard")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Nombre</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("firstName")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Apellidos</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("lastName")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Cantidad de registros a mostrar</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="number" min={1} {...register("sizePage")} />
              </div>
              <div className="md:col-12">
                <Button label="Buscar" onClick={search} />
              </div>
            </div>
            <DataTable
              value={beneficiaries}
              selection={beneficiary}
              onSelectionChange={select}
              emptyMessage="No se encontraron registros"
            >
              <Column selectionMode="single" style={{ width: "4em" }} />
              <Column field="firstName" header="Nombre" />
              <Column field="lastName" header="Apellidos" />
              <Column field="idCard" header="Cédula" />
              <Column
                body={(row) => formatDate(row.birthDate)}
                header="Fecha de nacimiento"
              />
              <Column field="kinship.name" header="Parentesco" />
              <Column field="number" header="Contrato" />
              <Column
                body={(row) => formatDate(row.diedDate)}
                header="Fecha de fallecimiento"
              />
              <Column field="state.name" header="Estado" />
            </DataTable>
            <Paginator
              first={first}
              totalRecords={totalRecords}
              rows={sizePage}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneficiaryList;
