import React from "react";
import UserForm from "./UserForm";
import { useLocation, useNavigate } from "react-router-dom";
import { showSuccess } from "../../utils/message";
import { convertStringToDate, formatDate } from "../../utils/format";
import useService from "../../hooks/hook-service";

const UserEdit = () => {
  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();
  const defualtUser = {
    ...location.state.user,
    birthDate: formatDate(location.state.user.birthDate, "DD/MM/YYYY"),
  };
  const editUser = (formData) => {
    let phoneNumber = formData.phone;
    phoneNumber = phoneNumber.replace("-", "");

    const data = {
      user: {
        email: formData.email,
        username: formData.username,
        name: formData.name,
        status: formData.status,
        idCard: formData.idCard,
        lastName: formData.lastName,
        birthDate: convertStringToDate(formData.birthDate),
        phone: phoneNumber,
        id: location.state.user.id,
      },
    };

    post("user/update", data, () => {
      showSuccess("El usuario se ha actualizado correctamente");
      navigate(-1);
    });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <UserForm
              headerClass="custom-card-header-edit"
              title="Editar Usuario"
              titleButton="Editar"
              actionToExecute={editUser}
              user={defualtUser}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserEdit;
