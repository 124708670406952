import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import pdf from "../../utils/pdf";
import { useLocation, useNavigate } from "react-router-dom";
import {
  showSuccess,
  showWarning,
  showWarningDialog,
} from "../../utils/message";
import ActionToolbar from "../../components/ActionsToolbar";
import { Controller, useForm } from "react-hook-form";
import createExcel from "../../utils/createExcel";
import { formatDate } from "../../utils/format";
import useService from "../../hooks/hook-service";

const UserList = () => {
  const ACTION_IDS = {
    ACTIVE: 4,
    INACTIVE: 3,
  };
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});

  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();
  const actions = location.state.actions;

  const { register, handleSubmit, control } = useForm();

  const select = (e) => {
    if (e.value) {
      setUser(e.value);
    } else {
      setUser({});
    }
  };

  const disableAction = (actionId) => {
    if (actionId === ACTION_IDS.ACTIVE && user.status === 1) {
      return true;
    }

    if (actionId === ACTION_IDS.INACTIVE && user.status === 0) {
      return true;
    }

    return Object.getOwnPropertyNames(user).length === 0;
  };

  const exportToPdf = () => {
    const body = [];
    users.forEach((element) => {
      const array = [
        element.idCard,
        element.name,
        element.lastName,
        element.phone,
        element.email,
        formatDate(element.birthDate),
        element.username,
      ];
      body.push(array);
    });
    const data = {
      head: [
        [
          "Cedula",
          "Nombre",
          "Apellidos",
          "Teléfono",
          "Correo electrónico",
          "Fecha de nacimiento",
          "Nombre de usuario",
        ],
      ],
      body: body,
      startY: 25,
      bodyStyles: { valign: "top" },
      tableWidth: "auto",
      styles: { cellWidth: "wrap", rowPageBreak: "auto", halign: "justify" },
      columnStyles: { text: { cellWidth: "auto" } },
    };
    pdf.createPDF("l", data, "Usuarios.pdf", "Reporte de usuarios");
  };

  const exportToExcel = () => {
    let result = {
      columns: [
        { title: "Cédula", width: { width: 15 } },
        { title: "Nombre", width: { width: 20 } },
        { title: "Apellidos", width: { width: 20 } },
        { title: "Teléfono", width: { width: 15 } },
        { title: "Correo electrónico", width: { width: 30 } },
        { title: "Fecha de nacimiento", width: { width: 15 } },
        { title: "Nombre de usuario", width: { width: 20 } },
      ],
      data: [],
    };

    users.forEach((element) => {
      let strDate = formatDate(element.birthDate);

      result.data.push([
        element.idCard,
        element.name,
        element.lastName,
        element.phone,
        element.email,
        strDate,
        element.username,
      ]);
    });
    createExcel("Usuarios", [result]);
  };

  const activeUser = () => {
    const data = {
      user: {
        id: user.id,
        status: 1,
      },
    };
    post("user/activate", data, () => {
      showSuccess("El usuario se activó correctamente");
      handleSubmit(search)();
    });
  };

  const inactiveUser = () => {
    const data = {
      user: {
        id: user.id,
        status: 0,
      },
    };
    post("user/disable", data, () => {
      showSuccess("El usuario se desactivó correctamente");
      handleSubmit(search)();
    });
  };

  const showUserChangeStatus = () => {
    showWarningDialog(
      {
        title: "Cambio de estado",
        text:
          user.status === 1
            ? "¿Desea desactivar el usuario?"
            : "¿Desea activar el usuario?",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      },
      {
        confirm: () => {
          if (user.status === 1) {
            inactiveUser();
          } else {
            activeUser();
          }
        },
      }
    );
  };

  const search = (data) => {
    post("user/search", data, ({ users }) => {
      if (users.length === 0) {
        showWarning("No se encontraron registros");
      }
      setUsers(users);
      setUser({});
    });
  };

  const redirect = (route) => {
    switch (route) {
      case "/userCreate":
        navigate(route);
        break;
      case "/userEdit":
      case "/assignPermissions":
      case "/userDetail":
        navigate(route, {
          state: { user: user },
        });
        break;
      case "/userChangeStatus":
        showUserChangeStatus();
        break;
      case "/exportExcel":
        exportToExcel();
        break;
      case "/exportPDF":
        exportToPdf();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    handleSubmit(search)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statusCatalog = [
    { label: "Activo", value: 1 },
    { label: "Inactivo", value: 0 },
  ];

  const status = (rowData) => {
    if (rowData.status === 1) {
      return "Activo";
    }
    return "Inactivo";
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Listado de Usuarios</h1>
            </div>
            <ActionToolbar
              actions={actions}
              checkIfHaveToDisable={disableAction}
              executeAction={redirect}
            />
            <label id="searchLabel">Buscar por</label>
            <div className="spacer20" />
            <form onSubmit={handleSubmit(search)} className="col-12">
              <div className="grid">
                <label className="col-12 mb-2 md:col-2 md:mb-0">Nombre</label>
                <div className="col-12 md:col-4">
                  <InputText type="text" {...register("name")} />
                </div>
                <label className="col-12 mb-2 md:col-2 md:mb-0">
                  Nombre de Usuario
                </label>
                <div className="col-12 md:col-4">
                  <InputText type="text" {...register("username")} />
                </div>
                <label className="col-12 mb-2 md:col-2 md:mb-0">
                  Correo electrónico
                </label>
                <div className="col-12 md:col-4">
                  <InputText type="text" {...register("email")} />
                </div>
                <label className="col-12 mb-2 md:col-2 md:mb-0">Estado</label>
                <div className="col-12 md:col-4">
                  <Controller
                    control={control}
                    name="status"
                    render={({ field }) => (
                      <Dropdown
                        id="status"
                        {...field}
                        placeholder="Estado"
                        options={statusCatalog}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="md:col-12">
                <Button label="Buscar" type="submit" />
              </div>
            </form>
            <DataTable
              paginator={true}
              rows={10}
              value={users}
              selection={user}
              emptyMessage="No se encontraron registros"
              onSelectionChange={select}
            >
              <Column selectionMode="single" style={{ width: "4em" }} />
              <Column field="name" header="Nombre" />
              <Column field="username" header="Nombre de usuario" />
              <Column field="email" header="Correo electrónico" />
              <Column body={status} header="Estado" />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserList;
