/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatCurrencyValue, formatDate } from "../../utils/format";
import useService from "../../hooks/hook-service";
import { useLocation, useNavigate } from "react-router-dom";

const InventoryDetail = () => {
  const [item, setItem] = useState({});

  const { post } = useService();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const data = {
      id: location.state.inventoryItem.id,
    };
    post("inventory/get", data, ({ inventoryItem }) => setItem(inventoryItem));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Detalle de Inventario</h1>
            </div>
            <div className="spacer20" />
            <div className="grid">
              <div className="md:col-4">
                <label htmlFor="input">
                  Código: {item.code}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Description: {item.description}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Cantidad: {item.quantity}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Cédula del Proveedor:
                  {item.provider?.idCard ?? ""}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Nombre del Proveedor: {item.provider?.name ?? ""}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Email del Proveedor: {item.provider?.email ?? ""}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Teléfono del Proveedor:
                  {item.provider?.phone ?? ""}
                </label>
              </div>
              {item.details?.length > 0 && (
                <div className="col-12">
                  <h2>Detalles</h2>
                  <DataTable
                    paginator={true}
                    rows={10}
                    value={item.details}
                  >
                    <Column
                      field="billNumber"
                      header="Númerdo de factura"
                    />
                    <Column
                      body={({ amount }) => formatCurrencyValue(amount)}
                      header="Monto"
                    />
                    <Column field="quantity" header="Cantidad" />
                    <Column
                      body={({ date }) => formatDate(date)}
                      header="Fecha de creación de la factura"
                    />
                  </DataTable>
                </div>
              )}

              <Button type="button" label="Regresar" onClick={() => navigate(-1)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InventoryDetail;
