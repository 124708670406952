import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import GenericCalendar from "../../components/GenericComponents/GenericCalendar";
import ActionToolbar from "../../components/ActionsToolbar";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import { Controller, useForm } from "react-hook-form";
import { convertStringToDate } from "../../utils/format";
import { showWarning } from "../../utils/message";
import { RECEIPT_CONFIG } from "../Receipt/Pay";

const AdvanceServiceInvoiceList = () => {
  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();
  const actions = location.state.actions;
  const { register, control, handleSubmit } = useForm();

  const [reserveBill, setReserveBill] = useState({});
  const [reserveBills, setReserveBills] = useState([]);

  useEffect(() => {
    handleSubmit(search)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = (formData) => {
    const request = {
      clientId: formData.clientId,
      startDate: convertStringToDate(formData.startDate),
      endDate: convertStringToDate(formData.endDate),
    };

    post("reserveBill/search", request, ({ reserveBills }) => {
      if (reserveBills.length === 0) {
        showWarning("No se encontraron registros");
      }
      setReserveBills(reserveBills);
      setReserveBill({});
    });
  };

  const disableAction = (actionId) => {
    return Object.getOwnPropertyNames(reserveBill).length === 0;
  };

  const redirect = (route) => {
    switch (route) {
      case "/advanceServiceInvoiceCreate":
        navigate(route);
        break;
      case "/advanceServiceInvoiceDetail":
        navigate(route, {
          state: { reserveBill: reserveBill },
        });
        break;
      case "/advanceServiceInvoicePay":
        const request = {
          id: reserveBill.id,
        };

        post("reserveBill/get", request, ({ reserveBill }) => {
          navigate("/receiptPay", {
            state: {
              affiliate: {
                id: reserveBill.affiliate.id,
              },
              paymentArrangement: reserveBill.paymentArrangement,
              reserveBill,
              config: RECEIPT_CONFIG.ADVANCE_INVOICE,
            },
          });
        });
        break;
      default:
        break;
    }
  };

  const select = (e) => {
    if (e.value) {
      setReserveBill(e.value);
    } else {
      setReserveBill({});
    }
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Listado de Apartados de servicio</h1>
            </div>
            <div className="spacer20" />
            <ActionToolbar
              actions={actions}
              checkIfHaveToDisable={disableAction}
              executeAction={redirect}
            />
            <div className="spacer10" />
            <label id="searchLabel" htmlFor="input">
              Buscar por{" "}
            </label>
            <div className="spacer20" />
            <div className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">Número de Cedula del Cliente</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="number" {...register("clientId")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Fecha de Inicio</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field }) => (
                    <GenericCalendar id="startDate" {...field} />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Fecha de Finalización</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field }) => (
                    <GenericCalendar id="endDate" {...field} />
                  )}
                />
              </div>
              <div className="md:col-12">
                <Button label="Buscar" onClick={handleSubmit(search)} />
              </div>
              <DataTable
                className="col-12"
                paginator={true}
                rows={10}
                value={reserveBills}
                selection={reserveBill}
                onSelectionChange={select}
                emptyMessage="No se encontraron registros"
              >
                <Column selectionMode="single" style={{ width: "4em" }} />
                <Column field="client.id" header="Cedula de Cliente" />
                <Column field="client.name" header="Nombre de Cliente" />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvanceServiceInvoiceList;
