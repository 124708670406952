/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import { useLocation, useNavigate } from "react-router-dom";
import ActionToolbar from "../../../components/ActionsToolbar";
import { showWarning } from "../../../utils/message";
import { useForm } from "react-hook-form";
import { formatCurrencyValue, formatDate } from "../../../utils/format";
import useService from "../../../hooks/hook-service";
import { RECEIPT_CONFIG } from "../Pay";

const ReceiptAffiliateList = () => {
  const [affiliate, setAffiliate] = useState({});
  const [affiliates, setAffiliates] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState(0);
  const [sizePage, setSizePage] = useState(10);

  const navigate = useNavigate();
  const location = useLocation();
  const { post } = useService();
  const actions = location.state.actions;
  const isAffiliate = location.state.isAffiliate;
  const url = isAffiliate
    ? "affiliate/searchToPay"
    : "affiliate/searchNotAffiliateToPay";

  const defaultValues = {
    sizePage: sizePage,
  };
  const { register, getValues } = useForm({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    resfreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const resfreshData = () => {
    const request = {
      idCard: getValues("idCard"),
      firstName: getValues("firstName"),
      lastName: getValues("lastName"),
      number: getValues("number"),
      sizePage: getValues("sizePage"),
      page: page,
    };

    post(url, request, ({ affiliates, rows }) => {
      if (rows === 0) {
        showWarning("No se encontraron registros");
      }

      setAffiliates(affiliates);
      setTotalRecords(rows);
      setAffiliate({});
    });
  };

  const search = () => {
    if (page === 0) {
      resfreshData();
    } else {
      setFirst(0);
      setPage(0);
      const size = getValues("sizePage");
      setSizePage(size);
    }
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page);
  };

  const select = (e) => {
    if (e.value) {
      setAffiliate(e.value);
    } else {
      setAffiliate({});
    }
  };

  const disableAction = (actionId) => {
    return Object.getOwnPropertyNames(affiliate).length === 0;
  };

  const goBack = () => navigate(-1);

  const redirect = (route) => {
    switch (route) {
      case "/receiptPay":
        navigate(route, {
          state: {
            affiliate: affiliate,
            config: isAffiliate
              ? RECEIPT_CONFIG.NORMAL
              : RECEIPT_CONFIG.INVOICE_RECEIVALBLE,
          },
        });
        break;
      case "/paymentArrangementCreate":
        navigate(route, {
          state: {
            affiliate: affiliate,
          },
        });
        break;
      default:
        break;
    }
  };

  const infoOfPayment = (rowData, column) => {
    const lastPayDate = formatDate(rowData.lastFeeDate);
    const today = formatDate(new Date());
    return (
      "Debe " +
      formatCurrencyValue(rowData.amountOwed) +
      " de la fecha " +
      lastPayDate +
      " a la fecha " +
      today
    );
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>{location.state.title} </h1>
            </div>
            <ActionToolbar
              actions={actions}
              checkIfHaveToDisable={disableAction}
              executeAction={redirect}
            />
            <label id="searchLabel" htmlFor="input">
              Buscar por{" "}
            </label>
            <div className="spacer20" />
            <div className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">
                  {isAffiliate ? "Número de Contrato" : "Número de Factura"}
                </label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("number")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Número de Cédula</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("idCard")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Nombre</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("firstName")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Apellidos</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("lastName")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Cantidad de registros a mostrar</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="number" min={1} {...register("sizePage")} />
              </div>
              <div className="md:col-12">
                <Button label="Buscar" onClick={search} />
                <Button label="Regresar" onClick={goBack} />
              </div>
            </div>
            <DataTable
              value={affiliates}
              selection={affiliate}
              emptyMessage="No se encontraron registros"
              onSelectionChange={select}
            >
              <Column selectionMode="single" style={{ width: "4em" }} />
              {!isAffiliate && (
                <Column field="number" header="Número de factura" />
              )}
              <Column field="idCard" header="Número de cédula" />
              <Column field="firstName" header="Nombre" />
              <Column field="lastName" header="Apellidos" />
              {isAffiliate && (
                <Column
                  body={(row) => formatDate(row.lastFeeDate)}
                  header="Fecha del ultimo pago"
                />
              )}
              <Column
                body={(row) => formatDate(row.payDate)}
                header="Fecha del siguiente pago"
              />
              {isAffiliate && (
                <Column field="lateQuotas" header="Cuotas pendientes" />
              )}
              {isAffiliate && (
                <Column
                  body={(row) => formatCurrencyValue(row.amountOwed)}
                  header="Monto adeudado"
                />
              )}
              <Column
                body={(row) => formatCurrencyValue(row.amountArrangement)}
                header="Arreglo de pago"
              />
              {isAffiliate && (
                <Column
                  body={(row) => formatCurrencyValue(row.savingAmount)}
                  header="Ahorro"
                />
              )}
              {isAffiliate && (
                <Column body={infoOfPayment} header="Información" />
              )}
            </DataTable>
            <Paginator
              first={first}
              totalRecords={totalRecords}
              rows={sizePage}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiptAffiliateList;
