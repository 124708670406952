import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import useService from "../../hooks/hook-service";
import classNames from "classnames";

const WayToPay = ({ catalogPaymentType, addButton, requiredAmount = true }) => {
  const [paymentTypes, setPaymentTypes] = useState(catalogPaymentType ?? []);
  const {
    register,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { post } = useService();
  const transferType = watch("transferType");

  useEffect(() => {
    post("catalog/list", { catalogType: "1004" }, ({ catalogs }) =>
      setPaymentTypes(catalogs)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue("referenceNumber", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferType]);

  return (
    <>
      <div className="col-12 md:col-2">
        <label htmlFor="input">Tipo de Transferencia</label>
      </div>
      <div className="col-12 md:col-4">
        <Controller
          control={control}
          defaultValue={18}
          name="transferType"
          rules={{ required: true }}
          render={({ field }) => (
            <Dropdown
              id="transferType"
              optionLabel="name"
              optionValue="id"
              className={classNames({
                "p-invalid": !!errors.transferType,
              })}
              placeholder="Seleccione un tipo"
              {...field}
              options={paymentTypes}
            />
          )}
        />
      </div>
      {requiredAmount && (
        <>
          <div className="col-12 md:col-2">
            <label htmlFor="input">Monto</label>
          </div>
          <div className="col-12 md:col-4">
            <InputText type="number" {...register("amount")} />
            {addButton}
          </div>
        </>
      )}
      {transferType !== 18 && transferType !== 75 && (
        <>
          <div className="col-12 md:col-2">
            <label htmlFor="input">Número de Referencia</label>
          </div>
          <div className="col-12 md:col-4">
            <InputText
              className="reference-number"
              type="text"
              {...register("referenceNumber")}
            />
          </div>
        </>
      )}
    </>
  );
};

export default WayToPay;
