import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { showWarningDialog } from "../../utils/message";
import { useNavigate } from "react-router-dom";
import PermissionEditorTree from "../../components/PermissionTree/PermissionEditorTree";

const RoleForm = ({
  headerClass,
  title,
  titleButton,
  actionToExecute,
  role,
  allPermissions,
}) => {
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm();

  useEffect(() => {
    if (role) {
      reset({
        name: role.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  const goBackToHome = () => {
    if (isDirty === true) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios sin guardar dentro de la página, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            navigate(-1);
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  const submit = (data) => {
    data.products = selectedPermissions;
    actionToExecute(data);
  };

  return (
    <>
      <div className={headerClass}>
        <h1>{title}</h1>
      </div>
      <div className="spacer20" />
      <form onSubmit={handleSubmit(submit)} className="col-12">
        <div className="grid">
          <label className="col-12 mb-2 md:col-2 md:mb-0">Nombre</label>
          <div className="col-12 md:col-4">
            <InputText type="text" {...register("name")} />
          </div>
        </div>
        <PermissionEditorTree
          allPermissions={allPermissions}
          initialPermissions={role?.products ?? []}
          onChange={(value) => setSelectedPermissions(value)}
        />
        <div className="md:col-12">
          <Button label={titleButton} />
          <Button type="button" label="Regresar" onClick={goBackToHome} />
        </div>
      </form>
    </>
  );
};

export default RoleForm;
