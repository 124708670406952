import React from "react";
import { Dialog } from "primereact/dialog";
import Waiting from "../Waiting";

const WaitingDialog = ({ visible }) => {
  return (
    <Dialog
      header="Espere..."
      closable={false}
      visible={visible}
      className="dialog-waiting"
    >
      <center>
        <h5>Por favor espere...</h5>
        <Waiting />
      </center>
    </Dialog>
  );
};

export default WaitingDialog;
