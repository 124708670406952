/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import pdf from "../../utils/pdf";
import createExcel from "../../utils/createExcel";
import ActionToolbar from "../../components/ActionsToolbar";
import { useForm } from "react-hook-form";
import { showWarning } from "../../utils/message";

const InventoryList = () => {
  const [inventoryItem, setInventoryItem] = useState({});
  const [inventoryList, setInventoryList] = useState([]);

  const navigate = useNavigate();
  const { post } = useService();
  const { register, handleSubmit } = useForm();
  const location = useLocation();
  const actions = location.state.actions;

  useEffect(() => {
      handleSubmit(search)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disableAction = (actionId) => {
    return Object.getOwnPropertyNames(inventoryItem).length === 0;
  };

  const search = (formData) => {
    const request = {
      providerName: formData.providerName,
      description: formData.description
    };

    post("inventory/list", request, ({ inventoryItems }) => {
      if (inventoryItems.length === 0) {
        showWarning("No se encontraron registros");
      }
      setInventoryList(inventoryItems);
      setInventoryItem({});
    });
  };

  const exportToPdf = () => {
    const body = [];
    inventoryList.forEach((element) => {
      const array = [
        element.code ?? "",
        element.description ?? "",
        element.quantity ?? "",
      ];
      body.push(array);
    });
    const data = {
      head: [
        ["Codigo", "Descripción", "Cantidad"],
      ],
      body: body,
      startY: 25,
      bodyStyles: { valign: "top" },
      tableWidth: "auto",
      styles: { cellWidth: "wrap", rowPageBreak: "auto", halign: "justify" },
      columnStyles: { text: { cellWidth: "auto" } },
    };
    pdf.createPDF("l", data, "Inventario.pdf", "Inventario");
  };

  const exportToExcel = () => {
    let result = {
      columns: [
        { title: "Inventario", width: { width: 15 } },
        { title: "Descripción", width: { width: 25 } },
        { title: "Cantidad", width: { width: 20 } },
      ],
      data: [],
    };

    inventoryList.forEach((element) => {
      result.data.push([
        element.code ?? "",
        element.description ?? "",
        element.quantity ?? "",
      ]);
    });
    createExcel("Inventario", [result]);
  };

  const select = (e) => {
    if (e.value) {
      setInventoryItem(e.value);
    } else {
      setInventoryItem({});
    }
  };

  const redirect = (route) => {
    switch (route) {
      case "/inventoryDetail":
        navigate(route, {
          state: { inventoryItem: inventoryItem },
        });
        break;
      case "/exportExcel":
        exportToExcel();
        break;
      case "/exportPDF":
        exportToPdf();
        break;
      default:
        break;
    }
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Listado de Inventario</h1>
            </div>
            <ActionToolbar
              actions={actions}
              checkIfHaveToDisable={disableAction}
              executeAction={redirect}
            />
            <label id="searchLabel" htmlFor="input">
              Buscar por
            </label>
            <div className="spacer20" />
            <div className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">Nombre del proveedor</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="text"
                  {...register("providerName")}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Descripción</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="text"
                  {...register("description")}
                />
              </div>
              <div className="md:col-12">
                <Button label="Buscar" onClick={handleSubmit(search)} />
              </div>
              <DataTable
                className="col-12"
                paginator={true}
                rows={10}
                value={inventoryList}
                selection={inventoryItem}
                onSelectionChange={select}
                emptyMessage="No se encontraron registros"
              >
                <Column selectionMode="single" style={{ width: "4em" }} />
                <Column field="code" header="Código" />
                <Column field="description" header="Descripción" />
                <Column field="quantity" header="Cantidad" />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryList;
