/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import { Dialog } from "primereact/dialog";
import ReceiptHeader from "../../components/ReceiptHeader";
import { FormProvider, useForm } from "react-hook-form";
import WayToPay from "../../components/WayToPayForm";
import { showError, showSuccess } from "../../utils/message";
import ReceiptDetails from "../../components/ReceiptDetails";
import WayToPayDetails from "../../components/WayToPayDetails";

const ReceiptEdit = () => {
  const [receipt, setReceipt] = useState({});
  const [catalogPaymentType, setCatalogPaymentType] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [waysToPay, setWaysToPay] = useState([]);
  const [rowIndexOfWayToPay, setRowIndexOfWayToPay] = useState(-1);

  const navigate = useNavigate();
  const location = useLocation();
  const formMethods = useForm();
  const { manyPost, post } = useService();

  useEffect(() => {
    const request = [
      {
        url: "paymentReceived/get",
        body: {
          id: location.state.receipt.id,
        },
      },
      {
        url: "catalog/list",
        body: { catalogType: "1004" },
      },
    ];

    manyPost(request, (result) => {
      setReceipt(result[0].paymentReceived);
      setWaysToPay(result[0].paymentReceived.waysToPay);
      setCatalogPaymentType(result[1].catalogs);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToBack = () => {
    navigate(-1);
  };

  const addPay = () => {
    setRowIndexOfWayToPay(-1);
    setShowDialog(true);
  };

  const editPay = (pay, rowIndex) => {
    formMethods.reset({
      amount: pay.amount,
      transferType: pay.transferType.id,
      referenceNumber: pay.referenceNumber,
    });
    setRowIndexOfWayToPay(rowIndex);
    setShowDialog(true);
  };

  const actionTemplate = (rowData, column) => {
    return (
      <div>
        <Button
          type="button"
          className="button-icon"
          icon="pi pi-pencil"
          onClick={() => editPay(rowData, column.rowIndex)}
        />
      </div>
    );
  };

  const onHideDialog = () => {
    formMethods.reset({
      amount: 0,
      transferType: 18,
      referenceNumber: "",
    });

    setShowDialog(false);
  };

  const saveWayToPay = (formData) => {
    if (formData.amount) {
      const element = catalogPaymentType.find(
        (catalog) => catalog.id === formData.transferType
      );
      const pay = {
        referenceNumber: formData.referenceNumber,
        transferType: {
          id: formData.transferType,
          name: element.name,
        },
        amount: formData.amount,
      };
      if (rowIndexOfWayToPay === -1) {
        waysToPay.push(pay);
      } else {
        waysToPay[rowIndexOfWayToPay] = pay;
      }

      setWaysToPay([...waysToPay]);
      onHideDialog();
    } else {
      showError("Debe completar los campos");
    }
  };

  const save = () => {
    const data = {
      id: receipt.id,
      waysToPay: waysToPay,
    };
    post("paymentReceived/update", data, () => {
      showSuccess("El modificación se realizó correctamente");
      navigate(-1);
    });
  };

  let button = null;

  if (rowIndexOfWayToPay === -1) {
    button = (
      <Button
        label="Agregar"
        className="button-icon"
        icon="pi pi-plus"
        onClick={formMethods.handleSubmit(saveWayToPay)}
      />
    );
  } else {
    button = (
      <Button
        label="Editar"
        className="button-icon"
        icon="pi pi-pencil"
        onClick={formMethods.handleSubmit(saveWayToPay)}
      />
    );
  }
  const footer = (
    <div>
      {button}
      <Button label="Atrás" icon="pi pi-times" onClick={onHideDialog} />
    </div>
  );
  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-edit">
              <h1>Editar Pago del Recibo</h1>
            </div>
            <div className="spacer20" />
            <Dialog
              footer={footer}
              header="Cambiar Forma de Pago"
              visible={showDialog}
              modal={true}
              onHide={onHideDialog}
            >
              <FormProvider {...formMethods}>
                <div className="grid">
                  <WayToPay />
                </div>
              </FormProvider>
            </Dialog>
            {receipt.id && (
              <>
                <ReceiptHeader receipt={receipt} />

                <div className="spacer20" />
                <ReceiptDetails title="Detalle" details={receipt.details} />

                <div className="spacer20" />
                <WayToPayDetails title="Pagos" waysToPay={waysToPay}>
                  <Column body={actionTemplate} header="Acciones" />
                </WayToPayDetails>
              </>
            )}
            <div className="spacer20" />
            <Button
              type="button"
              label="Agregar forma de pago"
              onClick={addPay}
            />
            <Button type="button" label="Guardar" onClick={save} />
            <Button type="button" label="Regresar" onClick={goToBack} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiptEdit;
