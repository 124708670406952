import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import authManager from "./authManager";
import loadingManager from "./loadingManager";

const reducer = combineReducers({
  authManager,
  loadingManager,
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
