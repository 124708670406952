/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Allan Moya <amoya@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppInlineProfile } from "./AppInlineProfile";

import { ScrollPanel } from "primereact/scrollpanel";
import { getAuth } from "./../utils/security";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import WaitingDialog from "../components/GenericComponents/WaitingDialog";
import useService from "../hooks/hook-service";

export const AppLayout = (props) => {
  let menuClick = false;

  const isLoading = useSelector((state) => state.loadingManager.value);
  const [layoutMode] = useState("static");
  const [layoutColorMode] = useState("dark");
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [menu, setMenu] = useState([]);
  const isDesktop = () => window.innerWidth > 1024;

  const navigate = useNavigate();
  const { post } = useService();

  useEffect(() => {
    const redirect = (route, actions) =>
      navigate(route, {
        state: { actions: actions },
      });

    const createMenu = (products) => {
      const menu = [];
      menu.push({
        label: "Inicio",
        icon: "pi pi-fw pi-home",
        command: () => {
          redirect("/dashboard");
        },
      });

      products.forEach((element) => {
        let items = [];
        for (let i of element.options) {
          let item = {
            label: i.name,
            icon: "pi pi-fw pi-circle-off",
            command: () => {
              redirect(i.route, i.actions);
            },
          };
          items.push(item);
        }
        let menuItem = {
          label: element.name,
          icon: "pi pi-fw pi-file",
          items: items,
        };
        menu.push(menuItem);
      });

      setMenu(menu);
    };

    const auth = getAuth().user;
    const dataUser = {
      id: auth.id,
    };
    post("user/get", dataUser, (data) => {
      if (data.user.role && data.user.role.products) {
        createMenu(data.user.role.products);
      } else {
        createMenu([]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mobileMenuActive) addClass(document.body, "body-overflow-hidden");
    else removeClass(document.body, "body-overflow-hidden");
  });

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    menuClick = false;
  };

  const onToggleMenu = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        setOverlayMenuActive(!overlayMenuActive);
      } else if (layoutMode === "static") {
        setStaticMenuInactive(!staticMenuInactive);
      }
    } else {
      setMobileMenuActive(!mobileMenuActive);
    }

    event.preventDefault();
  };

  const onSidebarClick = (event) => {
    menuClick = true;
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  let wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
  });

  let sidebarClassName = classNames("layout-sidebar", {
    "layout-sidebar-dark": layoutColorMode === "dark",
  });

  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <AppTopbar onToggleMenu={onToggleMenu} {...props} />
      <div className={sidebarClassName} onClick={onSidebarClick}>
        <ScrollPanel style={{ height: "100%" }}>
          <div className="layout-sidebar-scroll-content">
            <div className="layout-logo">
              <span style={{ color: "white" }}>FNSU</span>
            </div>
            <AppInlineProfile {...props} />
            <AppMenu model={menu} onMenuItemClick={onMenuItemClick} />
          </div>
        </ScrollPanel>
      </div>
      <div className="layout-main">
        <WaitingDialog visible={isLoading !== 0} />
        {props.children}
      </div>
      <div className="layout-mask" />
      <AppFooter />
    </div>
  );
};
