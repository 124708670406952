/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import pdf from "../../utils/pdf";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  showSuccess,
  showWarning,
  showWarningDialog,
} from "../../utils/message";
import ActionToolbar from "../../components/ActionsToolbar";
import createExcel from "../../utils/createExcel";
import useService from "../../hooks/hook-service";

const RoleList = () => {
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState({});

  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();
  const actions = location.state.actions;

  const { register, handleSubmit } = useForm();

  useEffect(() => {
    handleSubmit(search)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = (data) => {
    post("role/search", data, ({ roles }) => {
      if (roles.length === 0) {
        showWarning("No se encontraron registros");
      }
      setRoles(roles);
      setRole({});
    });
  };

  const disableAction = (actionId) => {
    return Object.getOwnPropertyNames(role).length === 0;
  };

  const select = (e) => {
    if (e.value) {
      setRole(e.value);
    } else {
      setRole({});
    }
  };

  const redirect = (route) => {
    switch (route) {
      case "/roleCreate":
        navigate(route);
        break;
      case "/roleEdit":
      case "/roleDetail":
        navigate(route, {
          state: { role: role },
        });
        break;
      case "/roleDelete":
        removeRole();
        break;
      case "/exportExcel":
        exportToExcel();
        break;
      case "/exportPDF":
        exportToPdf();
        break;
      default:
        break;
    }
  };

  const exportToExcel = () => {
    let result = {
      columns: [{ title: "Nombre", width: { width: 20 } }],
      data: [],
    };

    roles.forEach((element) => {
      result.data.push([element.name]);
    });
    createExcel("Roles", [result]);
  };

  const exportToPdf = () => {
    const body = [];
    roles.forEach((element) => {
      const array = [element.name];
      body.push(array);
    });
    const data = {
      head: [["Nombre"]],
      body: body,
      startY: 25,
      bodyStyles: { valign: "top" },
      tableWidth: "auto",
      styles: { cellWidth: "wrap", rowPageBreak: "auto", halign: "justify" },
      columnStyles: { text: { cellWidth: "auto" } },
    };
    pdf.createPDF("l", data, "Roles.pdf", "Reporte de roles");
  };

  const removeRole = () => {
    showWarningDialog(
      {
        title: "Eliminar Rol",
        text: "¿Desea eliminar el rol seleccionado?",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      },
      {
        confirm: () => {
          const data = {
            id: role.id,
          };
          post("role/delete", data, () => {
            showSuccess("El rol ha sido eliminado");
            handleSubmit(search)();
          });
        },
      }
    );
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Listado de Roles</h1>
            </div>
            <ActionToolbar
              actions={actions}
              checkIfHaveToDisable={disableAction}
              executeAction={redirect}
            />
            <label id="searchLabel">Buscar por</label>
            <div className="spacer20" />
            <form onSubmit={handleSubmit(search)} className="col-12">
              <div className="grid">
                <label className="col-12 mb-2 md:col-2 md:mb-0">Nombre</label>
                <div className="col-12 md:col-4">
                  <InputText type="text" {...register("name")} />
                </div>
              </div>
              <div className="md:col-12">
                <Button label="Buscar" type="submit" />
              </div>
            </form>
            <DataTable
              paginator={true}
              rows={10}
              value={roles}
              selection={role}
              emptyMessage="No se encontraron registros"
              onSelectionChange={select}
            >
              <Column selectionMode="single" style={{ width: "4em" }} />
              <Column field="name" header="Nombre" />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleList;
