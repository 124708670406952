import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { forwardRef, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { formatCurrencyValue, formatDate } from "../../utils/format";
const ComponentToPrint = forwardRef(({ bill, user }, ref) => {
  return (
    <div ref={ref}>
      <div className="grid">
        <div className="col-12">
          {bill?.contract?.number && (
            <div style={{ float: "left" }}>
              Afiliación: {bill.contract.number}
            </div>
          )}
          {bill?.number && (
            <div style={{ float: "left" }}>Recibo: {bill.number}</div>
          )}
          <div style={{ float: "right" }}>
            Fecha: {formatDate(bill?.emissionDate ?? new Date())}
          </div>
        </div>
        {bill?.client?.id && (
          <div className="col-12">Cedula: {bill?.client?.id}</div>
        )}
        {bill?.client?.name && (
          <div className="col-12">Nombre: {bill?.client?.name}</div>
        )}
        {bill?.client?.address && (
          <div className="col-12">Dirección: {bill?.client?.address} </div>
        )}
        {bill?.client?.phone && (
          <div className="col-12">Teléfono: {bill?.client?.phone} </div>
        )}
        <div className="col-12">
          {bill?.details?.length > 0 && (
            <div>
              <DataTable value={bill.details}>
                <Column
                  body={({ quantity }) => quantity ?? 1}
                  header="Cantidad"
                  style={{ width: "15%" }}
                />
                <Column
                  body={({ service }) => service.description ?? service.name}
                  header="Descripción"
                />
                <Column
                  body={({ service }) => formatCurrencyValue(service.price)}
                  header="Precio"
                  style={{ width: "20%" }}
                />
              </DataTable>
            </div>
          )}
        </div>

        {bill?.person && (
          <div className="col-12">
            <label htmlFor="input">
              Persona Fallecida: {bill?.person.lastName}{" "}
              {bill?.person.firstName} CEDULA {bill?.person.idCard}
            </label>
          </div>
        )}
        <div className="col-12">
          {user && (
            <div style={{ float: "left" }}>
              Realizado por: {user.name + " " + user.lastName}
            </div>
          )}
          <div style={{ float: "right" }}>
            Total: {formatCurrencyValue(bill?.subtotal)}
            {bill?.transferType?.name && <> en {bill?.transferType?.name}</>}
          </div>
        </div>
      </div>
    </div>
  );
});

const BillToPrint = ({ bill, user, previewComponent = true }) => {
  const componentRef = useRef();
  const pageStyle = `
  @page {
    size: A4;
    margin: 10mm;
  }

  @media print {
  html, body {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}
`;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
  });

  const style = {};
  if (!previewComponent) {
    style.display = "none";
  }

  const button = (
    <Button
      className="col-2"
      type="button"
      label="Imprimir"
      icon="pi pi-print"
      onClick={handlePrint}
    />
  );

  return (
    <>
      <div style={style}>
        <ComponentToPrint ref={componentRef} bill={bill} user={user} />
        <div className="spacer10" />
      </div>
      {!previewComponent && button}
      {previewComponent && (
        <>
          <div className="grid">{button}</div>
        </>
      )}
    </>
  );
};

export default BillToPrint;
