import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useService from "../../hooks/hook-service";
import { showError, showWarning, showWarningDialog } from "../../utils/message";
import GenericInputId from "../../components/GenericComponents/GenericInputId";
import GenericCalendar from "../../components/GenericComponents/GenericCalendar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { convertStringToDate, editDate } from "../../utils/format";
import { classNames } from "primereact/utils";
import { CONTRACT_TYPES } from "../../constants";

const BeneficiaryForm = ({
  beneficiary,
  contractType,
  onBack,
  onChange,
  isEditing = false,
}) => {
  const [kinships, setKinships] = useState([]);

  const { post } = useService();

  const {
    register,
    control,
    setValue,
    getValues,
    watch,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();
  const isAdditional = watch("isAdditional");

  useEffect(() => {
    post("catalog/list", { catalogType: "1001" }, ({ catalogs }) => {
      setKinships(catalogs);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      reset({
        idKinship: beneficiary?.kinship?.id ?? "",
        idCard: beneficiary?.idCard ?? "",
        lastName: beneficiary?.lastName ?? "",
        firstName: beneficiary?.firstName ?? "",
        birthDate: editDate(beneficiary?.birthDate) ?? "",
        isAdditional:
        beneficiary?.isAdditional !== true && contractType === CONTRACT_TYPES.INDIVIDUAL
        ? true
        : beneficiary?.isAdditional
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [beneficiary]
  );

  const searchPerson = () => {
    const data = {
      idCard: getValues("idCard"),
    };

    post("persons/get", data, ({ person }) => {
      if (!person) {
        showWarning("No se encontro ninguna persona con esa cedula");
        return;
      }

      setValue("firstName", person.firstName);
      setValue("lastName", person.lastName);
    });
  };

  const askToGoBack = () => {
    if (isDirty === true) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios en el edición de un beneficiario, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            onBack();
          },
        }
      );
    } else {
      onBack();
    }
  };

  const onSubmit = (formData) => {
    const kinship = kinships.find((x) => x.id === formData.idKinship);
    const beneficiary = {
      idCard: formData.idCard,
      firstName: formData.firstName,
      birthDate: convertStringToDate(formData.birthDate),
      lastName: formData.lastName,
      kinship: kinship,
      isAdditional: isAdditional,
    };
    onChange(beneficiary);
    reset();
  };

  const onError = (errors) => {
    showError("Debe completar los campos");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className="grid">
      {!isEditing && (
        <>
          <div className="col-12 md:col-2">
            <label htmlFor="input">Cedula</label>
          </div>
          <div className="col-12 md:col-4">
            <Controller
              control={control}
              name="idCard"
              rules={{ required: true }}
              render={({ field }) => (
                <GenericInputId
                  id="idCard"
                  {...field}
                  className={classNames({
                    "p-invalid": !!errors.idCard,
                  })}
                />
              )}
            />
            <Button type="button" label="Buscar" onClick={searchPerson} />
          </div>
        </>
      )}
      <div className="col-12 md:col-2">
        <label htmlFor="input">Nombre</label>
      </div>
      <div className="col-12 md:col-4">
        <InputText
          type="text"
          {...register("firstName", {
            required: true,
          })}
          className={classNames({
            "p-invalid": !!errors.firstName,
          })}
        />
      </div>
      <div className="col-12 md:col-2">
        <label htmlFor="input">Apellidos</label>
      </div>
      <div className="col-12 md:col-4">
        <InputText
          type="text"
          {...register("lastName", {
            required: true,
          })}
          className={classNames({
            "p-invalid": !!errors.lastName,
          })}
        />
      </div>
      <div className="col-12 md:col-2">
        <label htmlFor="input">Fecha De Nacimiento</label>
      </div>
      <div className="col-12 md:col-4">
        <Controller
          rules={{ required: true }}
          control={control}
          name="birthDate"
          render={({ field }) => (
            <GenericCalendar
              id="birthDate"
              {...field}
              className={classNames({
                "p-invalid": !!errors.birthDate,
              })}
            />
          )}
        />
      </div>
      <div className="col-12 md:col-2">
        <label htmlFor="input">Parentesco</label>
      </div>
      <div className="col-12 md:col-4">
        <Controller
          control={control}
          name="idKinship"
          rules={{ required: true }}
          render={({ field }) => (
            <Dropdown
              id="idKinship"
              {...field}
              className={classNames({
                "p-invalid": !!errors.idKinship,
              })}
              optionLabel="name"
              optionValue="id"
              placeholder="Parentesco"
              options={kinships}
            />
          )}
        />
      </div>
      <div className="col-12 md:col-2">
        <label htmlFor="input">Beneficiario Adicional</label>
      </div>
      <div className="col-12 md:col-4">
        <Checkbox
          disabled={contractType === CONTRACT_TYPES.INDIVIDUAL}
          onChange={(e) => setValue("isAdditional", e.checked)}
          checked={isAdditional}
        />
      </div>

      <div className="md:col-12">
        <Button label="Guardar" />
        <Button type="button" label="Regresar" onClick={askToGoBack} />
      </div>
    </form>
  );
};

export default BeneficiaryForm;
