/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { Checkbox } from "primereact/checkbox";
import ActionToolbar from "../../components/ActionsToolbar";
import { showSuccess, showWarning, showWarningDialog } from "../../utils/message";
import { Controller, useForm } from "react-hook-form";
import useService from "../../hooks/hook-service";
import pdf from "../../utils/pdf";
import createExcel from "../../utils/createExcel";
import { formatCurrencyValue } from "../../utils/format";

const ServiceList = () => {
  const [services, setServices] = useState([]);
  const [service, setService] = useState({});

  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();
  const { register, handleSubmit, control, setValue, watch } = useForm();
  const isAffiliate = watch('isAffiliate');
  const actions = location.state.actions;

  useEffect(() => {
    handleSubmit(search)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = (data) => {
    post("service/search", data, ({ services }) => {
      if (services.length === 0) {
        showWarning("No se encontraron registros");
      }
      setServices(services);
      setService({});
    });
  };

  const disableAction = (actionId) => {
    return Object.getOwnPropertyNames(service).length === 0;
  };

  const showServiceChangeStatus = () => {
    showWarningDialog(
      {
        title: "Cambio de estado",
        text:
        service.state === 1
            ? "¿Desea desactivar el servicio?"
            : "¿Desea activar el servicio?",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      },
      {
        confirm: () => {
          if (service.state === 1) {
            inactiveService();
          } else {
            activeService();
          }
        },
      }
    );
  };

  const activeService = () => {
    const data = {
        id: service.id,
    };
    post("service/active", data, () => {
      showSuccess("El servicio se activó correctamente");
      handleSubmit(search)();
    });
  };

  const inactiveService = () => {
    const data = {
        id: service.id,
    };
    post("service/inactive", data, () => {
      showSuccess("El servicio se desactivó correctamente");
      handleSubmit(search)();
    });
  };

  const exportToPdf = () => {
    const body = [];
    services.forEach((element) => {
      const array = [
        element.code,
        element.name,
        element.description,
        element.price,
        element.tax,
        element.state === 1 ? "Activo" : "Inactivo",
      ];
      body.push(array);
    });
    const data = {
      head: [
        [
          "Código", "Nombre", "Descripción", "Precio", "Impuesto", "Estado"
        ],
      ],
      body: body,
      startY: 25,
      bodyStyles: { valign: "top" },
      tableWidth: "auto",
      styles: { cellWidth: "wrap", rowPageBreak: "auto", halign: "justify" },
      columnStyles: { text: { cellWidth: "auto" } },
    };
    pdf.createPDF("l", data, "Servicios.pdf", "Reporte de servicios");
  };

  const exportToExcel = () => {
    let result = {
      columns: [
        { title: "Código", width: { width: 15 } },
        { title: "Nombre", width: { width: 30 } },
        { title: "Descripción", width: { width: 35 } },
        { title: "Precio", width: { width: 15 } },
        { title: "Impuesto", width: { width: 15 } },
        { title: "Estado", width: { width: 15 } },
      ],
      data: [],
    };

    services.forEach((element) => {
      result.data.push([
        element.code,
        element.name,
        element.description,
        element.price,
        element.tax,
        element.state === 1 ? "Activo" : "Inactivo",
      ]);
    });
    createExcel("Servicios", [result]);
  };

  const redirect = (route) => {
    switch (route) {
      case "/servicesCreate":
        navigate(route);
        break;
      case "/servicesEdit":
      case "/servicesDetail":
        navigate(route, {
          state: { service: service },
        });
        break;
      case "/servicesChangeStatus":
        showServiceChangeStatus();
        break;
      case "/exportExcel":
        exportToExcel();
        break;
      case "/exportPDF":
        exportToPdf();
        break;
      default:
        break;
    }
  };

  const status = [
    { label: "Activo", value: 1 },
    { label: "Inactivo", value: 0 },
  ];

  const select = (e) => {
    if (e.value) {
      setService(e.value);
    } else {
      setService({});
    }
  };

    return (
      <div className="p-fluid">
        <div className="grid">
          <div className="col-12">
            <div className="custom-card">
              <div className="custom-card-header-info">
                <h1>Listado de Servicios</h1>
              </div>
              <ActionToolbar
              actions={actions}
              checkIfHaveToDisable={disableAction}
              executeAction={redirect}
            />
              <label id="searchLabel" htmlFor="input">
                Buscar por
              </label>
              <div className="spacer20" />
              <div className="grid">
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Codigo</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="text"
                    {...register("code")}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Nombre</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="text"
                    {...register("name")}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Precio de inicio</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="number"
                    {...register("startPrice")}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Precio de finalización</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="number"
                    {...register("endPrice")}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Estado</label>
                </div>
                <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="state"
                  render={({ field }) => (
                    <Dropdown
                      id="state"
                      {...field}
                      placeholder="Estado"
                      options={status}
                    />
                  )}
                />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Servicio para Afilados</label>
                </div>
                <div className="col-12 md:col-4">
                <Checkbox
                    onChange={(e) =>
                      setValue("isAffiliate", e.checked)
                    }
                    checked={isAffiliate}
                  />
                </div>
                <div className="md:col-12">
                  <Button label="Buscar" onClick={handleSubmit(search)} />
                </div>
                    <DataTable className="col-12"
                      paginator={true}
                      rows={10}
                      value={services}
                      selection={service}
                      onSelectionChange={select}
                    >
                      <Column selectionMode="single" style={{ width: "4em" }} />
                      <Column field="code" header="Código" />
                      <Column field="name" header="Nombre" />
                      <Column body={(rowData) => formatCurrencyValue(rowData.price)} header="Precio" />
                      <Column body={(rowData) => formatCurrencyValue(rowData.tax)} header="Impuesto" />
                      <Column body={({applyAffiliate}) => applyAffiliate? "Afiliado" : "Particular"} header="Tipo Servicio" />
                      <Column body={({state}) => state? "Activo" : "Inactivo"} header="Estado" />
                    </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default ServiceList;