import React from "react";

const Waiting = (props) => {
  return (
    <div className="spiner-container">
      <i className="pi pi-spin pi-spinner spiner"></i>
    </div>
  );
};

export default Waiting;
