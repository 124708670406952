/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Allan Moya <amoya@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import { formatDate } from "../../utils/format";

const BeneficiaryDetail = () => {
  const [beneficiary, setBeneficiary] = useState({});
  const [contract, setContract] = useState({});

  const navigate = useNavigate();
  const { manyPost } = useService();
  const location = useLocation();

  useEffect(() => {
    const request = [
      {
        url: "beneficiary/get",
        body: {
          id: location.state.beneficiary.idCard,
        },
      },
      {
        url: "contract/get",
        body: {
          id: location.state.beneficiary.idContract,
        },
      },
    ];
    manyPost(request, (result) => {
      setBeneficiary(result[0].beneficiary);
      setContract(result[1].contract);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Detalle del Beneficiario</h1>
            </div>
            <div className="spacer20" />
            <div className="grid">
              <div className="md:col-4">
                <label htmlFor="input">
                  Nombre: {beneficiary?.firstName ?? ""}{" "}
                  {beneficiary?.lastName ?? ""}
                </label>{" "}
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Cédula: {beneficiary?.idCard ?? ""}
                </label>{" "}
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Fecha de nacimiento: {formatDate(beneficiary?.birthDate)}
                </label>{" "}
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Parentesco: {beneficiary?.kinship?.name ?? ""}
                </label>{" "}
              </div>
              {contract?.type?.id !== 84 && (
                <div className="md:col-4">
                  <label htmlFor="input">
                    Beneficiario Adicional:{" "}
                    <Checkbox
                      checked={beneficiary?.isAdditional ?? false}
                      disabled={true}
                    />
                  </label>{" "}
                </div>
              )}
              <div className="spacer20" />
              <Button type="button" label="Regresar" onClick={goBack} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneficiaryDetail;
