import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import GenericCalendar from "../../components/GenericComponents/GenericCalendar";
import { Paginator } from "primereact/paginator";
import pdf from "../../utils/pdf";
import { convertStringToDate, formatDate } from "../../utils/format";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import { showWarning } from "../../utils/message";
import createExcel from "../../utils/createExcel";
import ActionToolbar from "../../components/ActionsToolbar";

const ContractList = () => {
  const ACTION_IDS = {
    TRANSFER: 2,
  };

  const [contract, setContract] = useState({});
  const [contracts, setContracts] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState(0);
  const [sizePage, setSizePage] = useState(10);

  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();
  const actions = location.state.actions;

  const defaultValues = {
    sizePage: sizePage,
  };
  const { register, control, getValues } = useForm({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    resfreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const search = () => {
    if (page === 0) {
      resfreshData();
    } else {
      setFirst(0);
      setPage(0);
      const size = getValues("sizePage");
      setSizePage(size);
    }
  };

  const resfreshData = () => {
    const startDate = getValues("startDate");
    const endDate = getValues("endDate");

    const request = {
      startDate: convertStringToDate(startDate),
      endDate: convertStringToDate(endDate),
      number: getValues("number"),
      sizePage: getValues("sizePage"),
      page: page,
    };

    doRequest(request, (rows, contracts) => {
      setContracts(contracts);
      setTotalRecords(rows);
      setContract({});
    });
  };

  const doRequest = (request, callback) => {
    post("contract/search", request, ({ rows, contracts }) => {
      if (rows === 0) {
        showWarning("No se encontraron registros");
      }

      callback(rows, contracts);
    });
  };

  const exportToExcel = () => {
    const startDate = getValues("startDate");
    const endDate = getValues("endDate");

    const request = {
      startDate: convertStringToDate(startDate),
      endDate: convertStringToDate(endDate),
      number: getValues("number"),
      page: 0,
    };

    doRequest(request, (rows, contracts) => {
      if (rows === 0) return;

      const result = {
        columns: [
          { title: "Número de contrato", width: { wpx: 90 } },
          { title: "Fecha de afiliación", width: { wpx: 90 } },
          { title: "Cédula del afiliado", width: { wpx: 90 } },
          { title: "Nombre del afiliado", width: { wpx: 90 } },
          { title: "Apellidos del afiliado", width: { wpx: 90 } },
          { title: "Tipo de contrato", width: { wpx: 90 } },
          { title: "Estado del contrato", width: { wpx: 90 } },
          { title: "Contrato original", width: { wpx: 90 } },
        ],
        data: [],
      };

      contracts.forEach((element) => {
        result.data.push([
          element.number ?? "",
          formatDate(element.creationDate),
          element.affiliate.idCard ?? "",
          element.affiliate.firstName ?? "",
          element.affiliate.lastName ?? "",
          element.type.name ?? "",
          element.status.name ?? "",
          element.associatedContract ?? "",
        ]);
      });

      createExcel("Contractos", [result]);
    });
  };

  const exportToPdf = () => {
    const startDate = getValues("startDate");
    const endDate = getValues("endDate");

    const request = {
      startDate: convertStringToDate(startDate),
      endDate: convertStringToDate(endDate),
      number: getValues("number"),
      page: 0,
    };

    doRequest(request, (rows, contracts) => {
      if (rows === 0) return;
      const body = [];
      contracts.forEach((element) => {
        const array = [
          element.number ? element.number : "",
          formatDate(element.creationDate),
          element.affiliate.idCard ? element.affiliate.idCard : "",
          element.affiliate.firstName ? element.affiliate.firstName : "",
          element.affiliate.lastName ? element.affiliate.lastName : "",
          element.type.name ? element.type.name : "",
          element.status.name ? element.status.name : "",
          element.associatedContract ?? "",
        ];
        body.push(array);
      });
      const data = {
        head: [
          [
            "Número de contrato",
            "Fecha de afiliación",
            "Cédula del afiliado",
            "Nombre del afiliado",
            "Apellidos del afiliado",
            "Tipo de contrato",
            "Estado del contrato",
            "Contrato original",
          ],
        ],
        body: body,
        startY: 25,
        styles: { overflow: "linebreak" },
      };
      pdf.createPDF("l", data, "Contratos.pdf", "Reporte de contratos");
    });
  };

  const select = (e) => {
    if (e.value) {
      setContract(e.value);
    } else {
      setContract({});
    }
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page);
  };

  const disableAction = (actionId) => {
    if (actionId === ACTION_IDS.TRANSFER && contract.type?.id === 8) {
      return true;
    }
    return Object.getOwnPropertyNames(contract).length === 0;
  };

  const associatedContract = (rowData, column) => {
    if (rowData.associatedContract === 0) {
      return "No tiene";
    }
    return rowData.associatedContract;
  };

  const redirect = (route) => {
    switch (route) {
      case "/contractCreate":
        navigate(route);
        break;
      case "/contractTransfer":
      case "/contractDetail":
      case "/contractUploadFile":
      case "/downloadFile":
      case "/editContract":
        navigate(route, {
          state: { contract: contract },
        });
        break;
      case "/exportExcel":
        exportToExcel();
        break;
      case "/exportPDF":
        exportToPdf();
        break;
      default:
        break;
    }
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Contratos</h1>
            </div>
            <ActionToolbar
              actions={actions}
              checkIfHaveToDisable={disableAction}
              executeAction={redirect}
            />
            <label id="searchLabel" htmlFor="input">
              Buscar por{" "}
            </label>
            <div className="spacer20" />
            <div className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">Fecha de inicio</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field }) => (
                    <GenericCalendar id="startDate" {...field} />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Fecha de finalización</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field }) => (
                    <GenericCalendar id="endDate" {...field} />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Número de contrato</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="number" {...register("number")} min={1} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Cantidad de registros a mostrar</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="number" min={1} {...register("sizePage")} />
              </div>
              <div className="md:col-12">
                <Button label="Buscar" onClick={search} />
              </div>
            </div>
            <DataTable
              value={contracts}
              selection={contract}
              emptyMessage="No se encontraron registros"
              onSelectionChange={select}
            >
              <Column selectionMode="single" style={{ width: "4em" }} />
              <Column field="number" header="Número de contrato" />
              <Column
                body={({ creationDate }) => formatDate(creationDate)}
                header="Fecha de Afiliación"
              />
              <Column field="affiliate.idCard" header="Cedula del Afiliado" />
              <Column
                field="affiliate.firstName"
                header="Nombre del Afiliado"
              />
              <Column
                field="affiliate.lastName"
                header="Apellidos del Afiliado"
              />
              <Column field="type.name" header="Tipo de contrato" />
              <Column field="status.name" header="Estado del contrato" />
              <Column body={associatedContract} header="Contrato Original" />
            </DataTable>
            <Paginator
              first={first}
              totalRecords={totalRecords}
              rows={sizePage}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractList;
