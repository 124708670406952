/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import ContractToPrint from "../../components/Vouchers/ContractToPrint";
import ContractHeader from "../../components/ContractHeader";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import BeneficiaryDetails from "../../components/BeneficiaryDetails";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";
import { Column } from "jspdf-autotable";
import { showError, showSuccess, showWarningDialog } from "../../utils/message";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import { AffiliateOtherInformationForm } from "../../components/AffiliateForm";
import BeneficiaryForm from "../../components/BeneficiaryForm";
import { convertStringToDate } from "../../utils/format";

const ContractTransfer = () => {
  const [newContract, setNewContract] = useState();
  const [contract, setContract] = useState();
  const [beneficiary, setBeneficiary] = useState();
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [isEditingBeneficiary, setIsEditingBeneficiary] = useState(false);
  const [isAddingNewBeneficiary, setIsAddingNewBeneficiary] = useState(false);
  const [beneficiaryStates, setBeneficiaryStates] = useState([]);
  const [isEditingAffiliate, setIsEditingAffiliate] = useState(false);

  const navigate = useNavigate();
  const { post, manyPost } = useService();
  const location = useLocation();

  const formEditingBeneficiary = useForm();
  const formEditingAffiliate = useForm();
  useEffect(() => {
    const request = [
      {
        url: "catalog/list",
        body: { catalogType: "1011" },
      },
      {
        url: "contract/get",
        body: {
          id: location.state.contract.id,
        },
      },
    ];

    manyPost(request, (result) => {
      setBeneficiaryStates(result[0].catalogs);

      setContract(result[1].contract);
      setBeneficiaries(
        result[1].contract.affiliate.beneficiaries.map((x) => {
          return { ...x };
        })
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showEditBeneficiary = (rowData, index) => {
    formEditingBeneficiary.reset({
      index,
      idStatus: rowData.state.id,
      observations: rowData.observations,
    });
    setIsEditingBeneficiary(true);
  };

  const actionTemplate = (rowData, column) => {
    return (
      <>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="button-icon"
          onClick={() => showEditBeneficiary(rowData, column.rowIndex)}
        />
      </>
    );
  };

  const onSelectBeneficiary = (e) => {
    if (e.value) {
      setBeneficiary(e.value);
    } else {
      setBeneficiary({});
    }
  };

  const goOnTransfer = () => {
    if (Object.getOwnPropertyNames(beneficiary).length === 0) {
      showError("Debe seleccionar un beneficiario");
      return;
    }

    setIsEditingAffiliate(true);
  };

  const askToGoBack = () => {
    if (
      JSON.stringify(beneficiaries) !==
      JSON.stringify(contract.affiliate.beneficiaries)
    ) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios sin guardar dentro de la página, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            navigate(-1);
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  const onError = (errors) => {
    showError("Debe completar los campos");
  };

  const onSubmitEditBeneficiary = (formData) => {
    let beneficiary = beneficiaries[formData.index];
    beneficiary.state = beneficiaryStates.find(
      (x) => x.id === formData.idStatus
    );
    beneficiary.observations = formData.observations;
    setBeneficiaries([...beneficiaries]);
    setIsEditingBeneficiary(false);
  };

  const showAddBeneficiary = () => {
    setIsAddingNewBeneficiary(true);
  };

  const addNewBeneficiary = (benef) => {
    benef.state = beneficiaryStates.find((x) => x.id === 43);
    beneficiaries.push(benef);
    setBeneficiaries([...beneficiaries]);
    setIsAddingNewBeneficiary(false);
  };

  const transferContract = (formData) => {
    let division = {
      id: formData.idProvince,
      subDivision: {
        id: formData.idCounty,
        subDivision: {
          id: formData.idDistrict,
        },
      },
    };

    const beneficiariesArray = [...beneficiaries];
    const index = beneficiariesArray
      .map((x) => {
        return x.idCard;
      })
      .indexOf(beneficiary.idCard);
    beneficiariesArray.splice(index, 1);

    const request = {
      contract: {
        type: contract.type,
        lengthRegisterQuota: 1,
        affiliate: {
          idCard: beneficiary.idCard,
          firstName: beneficiary.firstName,
          lastName: beneficiary.lastName,
          address: formData.address,
          division: division,
          maritalStatus: {
            id: formData.idMaritalStatus,
          },
          genre: {
            id: formData.idGender,
          },
          email: formData.email,
          celphone: formData.celphone
            ? formData.celphone.replace("-", "")
            : null,
          telephone: formData.telephone
            ? formData.telephone.replace("-", "")
            : null,

          birthDate: convertStringToDate(formData.birthDate),
          beneficiaries: beneficiariesArray,
        },
        associatedContract: contract.id,
      },
    };

    post("contract/transfer", request, ({ contract }) => {
      showSuccess("Se realizo correctamente el traspaso");
      setNewContract(contract);
    });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-edit">
              <h1>Traspaso de Contrato</h1>
            </div>
            <div className="spacer20" />
            <Dialog
              header="Información"
              visible={!!newContract}
              modal={true}
              onHide={() => navigate(-1)}
            >
              <TabView>
                <TabPanel header="Contrato">
                  <ContractToPrint contract={newContract} />
                </TabPanel>
              </TabView>
            </Dialog>

            <Dialog
              header="Editar Beneficiario"
              visible={isEditingBeneficiary}
              modal={true}
              onHide={() => setIsEditingBeneficiary(false)}
            >
              <form
                onSubmit={formEditingBeneficiary.handleSubmit(
                  onSubmitEditBeneficiary,
                  onError
                )}
                className="grid"
              >
                <div className="col-12">
                  <label htmlFor="input">Estado</label>
                </div>
                <div className="col-12">
                  <Controller
                    control={formEditingBeneficiary.control}
                    name="idStatus"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Dropdown
                        id="idStatus"
                        {...field}
                        className={classNames({
                          "p-invalid":
                            !!formEditingBeneficiary.formState.errors.idStatus,
                        })}
                        optionLabel="name"
                        optionValue="id"
                        options={beneficiaryStates}
                        placeholder="Estado"
                      />
                    )}
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="input">Observaciones</label>
                </div>
                <div className="col-12">
                  <InputText
                    type="text"
                    {...formEditingBeneficiary.register("observations")}
                  />
                </div>
                <div className="md:col-12">
                  <Button label="Guardar" />
                  <Button
                    type="button"
                    label="Regresar"
                    onClick={() => setIsEditingBeneficiary(false)}
                  />
                </div>
              </form>
            </Dialog>

            <Dialog
              header="Agregar Beneficiario"
              visible={isAddingNewBeneficiary}
              modal={true}
              onHide={() => setIsAddingNewBeneficiary(false)}
            >
              <BeneficiaryForm
                onChange={addNewBeneficiary}
                onBack={() => setIsAddingNewBeneficiary(false)}
              />
            </Dialog>

            <Dialog
              header="Completar información"
              visible={isEditingAffiliate}
              modal={true}
              onHide={() => setIsEditingAffiliate(false)}
            >
              <FormProvider {...formEditingAffiliate}>
                <form
                  onSubmit={formEditingAffiliate.handleSubmit(
                    transferContract,
                    onError
                  )}
                  className="grid"
                >
                  <AffiliateOtherInformationForm />
                  <div className="md:col-12">
                    <Button label="Transferir" icon="pi pi-plus" />
                    <Button
                      type="button"
                      label="Regresar"
                      onClick={() => setIsEditingAffiliate(false)}
                    />
                  </div>
                </form>
              </FormProvider>
            </Dialog>

            <div className="grid">
              <ContractHeader contract={contract} />
            </div>
            <div className="spacer10" />

            <BeneficiaryDetails
              beneficiaries={beneficiaries}
              beneficiary={beneficiary}
              onSelectionChange={onSelectBeneficiary}
            >
              <Column field="state.name" header="Estado" />
              <Column body={actionTemplate} header="Opciones" />
            </BeneficiaryDetails>
            <div className="spacer20" />

            <div className="md:col-12">
              <Button label="Guardar" onClick={goOnTransfer} />
              <Button type="button" label="Regresar" onClick={askToGoBack} />
              <Button
                type="button"
                label="Agregar Beneficiarios"
                onClick={showAddBeneficiary}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractTransfer;
