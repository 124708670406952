/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import GenericInputPhone from "../../../components/GenericComponents/GenericInputPhone";
import { Panel } from "primereact/panel";
import { Column } from "primereact/column";
import { Tree } from "primereact/tree";
import {
  showError,
  showSuccess,
  showWarning,
  showWarningDialog,
} from "../../../utils/message";
import "./billAffiliate.css";
import useService from "../../../hooks/hook-service";
import ContractHeader from "../../../components/ContractHeader";
import BeneficiaryDetails from "../../../components/BeneficiaryDetails";
import { formatCurrencyValue } from "../../../utils/format";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { classNames } from "primereact/utils";
import BillToPrint from "../../../components/Vouchers/BillToPrint";
import { useSelector } from "react-redux";

const BillCreateAffiliate = () => {
  const auth = useSelector((state) => state.authManager.value);
  const [contractNumber, setContractNumber] = useState();
  const [servicesTreeView, setServicesTreeView] = useState([]);
  const [servicesTreeData, setServicesTreeData] = useState([]);
  const [selectedServices, setSelectedServices] = useState();
  const [subtotal, setSubtotal] = useState(0);
  const [bill, setBill] = useState({});

  const navigate = useNavigate();
  const { post } = useService();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isDirty, errors },
  } = useForm();
  const idCard = watch("idCard");
  const name = watch("name");
  const email = watch("email");
  const address = watch("address");
  const phone = watch("phone");
  const observations = watch("observations");
  const number = watch("number");

  useEffect(() => {
    const billTemp = {
      number: number,
      client: {
        id: idCard,
        name: name,
        email: email,
        address: address,
        phone: phone?.replace("-", ""),
      },
      subtotal: subtotal,
      observations: observations,
    };

    setBill({ ...bill, ...billTemp });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCard, name, email, address, phone, subtotal, observations]);

  useEffect(() => {
    const data = {
      code: null,
      name: null,
      startPrice: null,
      endPrice: null,
      state: 1,
      isAffiliate: true,
    };
    post("service/search", data, ({ services }) => {
      const groupBy = (list, keyGetter) => {
        const map = new Map();
        list.forEach((item) => {
          const key = keyGetter(item);
          const collection = map.get(key);
          if (!collection) {
            map.set(key, [item]);
          } else {
            collection.push(item);
          }
        });
        return map;
      };

      let treeView = [];
      let i = 0;
      let treeData = groupBy(services, (service) => service.name);
      treeData.forEach((element) => {
        let items = [];
        let name = "";
        for (let [index, value] of element.entries()) {
          let item = {
            key: i + "-" + index,
            label: value.description,
          };
          items.push(item);
          name = value.name;
        }
        let treeItem = {
          key: i,
          label: name,
          children: items,
        };
        treeView.push(treeItem);
        i++;
      });

      setServicesTreeView(treeView);
      setServicesTreeData(treeData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addBeneficiary = (row) => {
    bill.person = row;
    setBill({ ...bill });
  };

  const searchContract = () => {
    if (!contractNumber || contractNumber === "") {
      showError("Debe agregar un número de contracto");
      return;
    }

    const data = {
      id: contractNumber,
    };

    post("contract/getByNumber", data, ({ contract }) => {
      if (!contract) {
        showError("No se encontro un contrato con ese número");
        return;
      }

      if (contract.affiliate.lateQuotas > 1) {
        showWarning("El afiliado no se encuentra al día con sus pagos");
        return;
      }

      bill.contract = contract;
      setBill({ ...bill });
    });
  };

  const searchPerson = () => {
    const data = {
      idCard: getValues("idCard"),
    };

    post("persons/get", data, ({ person }) => {
      if (!person) {
        showWarning("No se encontro ninguna persona con esa cedula");
        return;
      }
      setValue("name", `${person.firstName} ${person.lastName}`);
    });
  };

  const addAffiliateAsClient = () => {
    setValue("idCard", bill.contract.affiliate.idCard);
    setValue(
      "name",
      `${bill.contract.affiliate.firstName} ${bill.contract.affiliate.lastName}`
    );
    setValue("phone", bill.contract.affiliate.telephone);
    setValue("address", bill.contract.affiliate.address);
    setValue("email", bill.contract.affiliate.email);
  };

  const applyService = () => {
    bill.person = bill.contract.affiliate;
    setBill({ ...bill });
  };

  const onChangeServices = (event) => {
    let selected = event;
    let servicesTemp = servicesTreeData;
    let selectedKeys = [];
    Object.keys(selected).forEach((key) => {
      selectedKeys.push(key);
    });
    selectedKeys = selectedKeys.sort();
    let subtotal = 0;
    let itemsTemp = [];
    let i = 0;
    let j = 0;
    servicesTemp.forEach((element) => {
      if (j < selectedKeys.length && selectedKeys[j] === i + "") {
        j++;
        for (let value of element) {
          let item = value;
          subtotal += item.price;
          itemsTemp.push({
            service: item,
            price: item.price,
            quantity: 1,
            tax: 0,
            total: item.price,
          });
        }
      }
      i++;
    });
    setSubtotal(subtotal);
    setSelectedServices(event);
    bill.details = itemsTemp;
    setBill({ ...bill });
  };

  const goaskToGoBack = () => {
    if (isDirty === true || bill.person || selectedServices) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios sin guardar dentro de la página, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            navigate(-1);
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  const onSubmit = () => {
    if (!selectedServices) {
      showError("Debe seleccionar un servicio");
      return;
    }

    let data = { bill };

    post("bill/affiliate", data, () => {
      showSuccess("Se creo correctamente la factura");
      navigate(-1);
    });
  };

  const onError = () => {
    showError("Debe completar los campos");
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-create">
              <h1>Crear factura de Asociado</h1>
            </div>
            <div className="spacer20" />
            <div className="grid">
              <Panel
                header="Contrato"
                className="col-12 panel-contract"
                toggleable={true}
              >
                <div className="grid">
                  <div className="col-12 md:col-2">
                    <label htmlFor="input">Número de Contracto</label>
                  </div>
                  <div className="col-12 md:col-2">
                    <InputText
                      type="number"
                      required={true}
                      value={contractNumber}
                      onChange={(e) => setContractNumber(e.target.value)}
                    />
                    <Button
                      type="button"
                      label="Buscar"
                      onClick={searchContract}
                    />
                  </div>
                  {bill.contract?.id && (
                    <div className="col-12 md:col-2">
                      <Button
                        className="button-icon"
                        type="button"
                        icon="pi pi-plus"
                        onClick={addAffiliateAsClient}
                        tooltip="Agregar como responsable"
                      />
                      <Button
                        type="button"
                        className="button-icon"
                        icon="pi pi-plus"
                        onClick={applyService}
                        tooltip="Aplicar Servicio"
                      />
                    </div>
                  )}
                  {bill.contract && (
                    <div className="grid">
                      <ContractHeader contract={bill.contract} />
                      <div className="col-12">
                        <BeneficiaryDetails
                          beneficiaries={bill.contract.affiliate?.beneficiaries}
                        >
                          <Column
                            body={(row) => (
                              <Button
                                type="button"
                                className="button-icon"
                                icon="pi pi-plus"
                                onClick={() => addBeneficiary(row)}
                                tooltip="Aplicar Servicio"
                              />
                            )}
                            header="Aplicar servicio"
                          />
                        </BeneficiaryDetails>
                      </div>
                    </div>
                  )}
                </div>
              </Panel>

              <Panel
                header="Responsable"
                className="col-12 panel-client"
                toggleable={true}
              >
                <div className="grid">
                  <div className="col-12 md:col-2">
                    <label htmlFor="input">Cedula del Responsable</label>
                  </div>
                  <div className="col-12 md:col-4">
                    <InputText
                      type="idCard"
                      className={classNames({
                        "p-invalid": !!errors.idCard,
                      })}
                      {...register("idCard", {
                        required: true,
                      })}
                    />
                    <Button
                      type="button"
                      label="Buscar"
                      onClick={searchPerson}
                    />
                  </div>
                  <div className="col-12 md:col-2">
                    <label htmlFor="input">Nombre del Responsable</label>
                  </div>
                  <div className="col-12 md:col-4">
                    <InputText
                      type="text"
                      className={classNames({
                        "p-invalid": !!errors.name,
                      })}
                      {...register("name", {
                        required: true,
                      })}
                    />
                  </div>
                  <div className="col-12 md:col-2">
                    <label htmlFor="input">Correo del Responsable</label>
                  </div>
                  <div className="col-12 md:col-4">
                    <InputText type="email" {...register("email")} />
                  </div>
                  <div className="col-12 md:col-2">
                    <label htmlFor="input">Dirección del Cliente</label>
                  </div>
                  <div className="col-12 md:col-4">
                    <InputText
                      type="text"
                      className={classNames({
                        "p-invalid": !!errors.address,
                      })}
                      {...register("address", {
                        required: true,
                      })}
                    />
                  </div>
                  <div className="col-12 md:col-2">
                    <label htmlFor="input">Teléfono del Cliente</label>
                  </div>
                  <div className="col-12 md:col-4">
                    <Controller
                      control={control}
                      name="phone"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <GenericInputPhone
                          id="phone"
                          {...field}
                          className={classNames({
                            "p-invalid": !!errors.phone,
                          })}
                        />
                      )}
                    />
                  </div>
                </div>
              </Panel>
              {bill?.person?.idCard && (
                <Panel
                  header="Persona que se le va aplicar el servicio"
                  className="col-12 panel-benef"
                  toggleable={true}
                >
                  <div className="grid">
                    <div className="md:col-4">
                      <label htmlFor="input">
                        Cédula: {bill?.person.idCard}
                      </label>
                    </div>
                    <div className="md:col-4">
                      <label htmlFor="input">
                        Nombre: {bill?.person.firstName}
                      </label>
                    </div>
                    <div className="md:col-4">
                      <label htmlFor="input">
                        Apellidos: {bill?.person.lastName}
                      </label>
                    </div>
                  </div>
                </Panel>
              )}

              <div className="col-12 md:col-2">
                <label htmlFor="input">Número de factura</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="number"
                  className={classNames({
                    "p-invalid": !!errors.number,
                  })}
                  {...register("number", {
                    required: true,
                  })}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Observaciones</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("observations")} />
              </div>
              <div className="col-12" />
              <div className="col-6 col-offset-6">
                Total: {formatCurrencyValue(subtotal)}
              </div>
              <div className="col-12">
                <h4>Seleccione los servicios</h4>
                <Tree
                  value={servicesTreeView}
                  selectionMode="checkbox"
                  selectionKeys={selectedServices}
                  onSelectionChange={(event) => onChangeServices(event.value)}
                />
              </div>
              <div className="md:col-12">
                <Button
                  label="Guardar"
                  onClick={handleSubmit(onSubmit, onError)}
                />
                <Button
                  type="button"
                  label="Regresar"
                  onClick={goaskToGoBack}
                />
                <BillToPrint
                  previewComponent={false}
                  bill={bill}
                  user={auth.user}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillCreateAffiliate;
