/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import PermissionDetailTree from "../../components/PermissionTree/PermissionDetailTree";
import useService from "../../hooks/hook-service";

const RoleDetail = () => {
  const [role, setRole] = useState({});

  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();

  useEffect(() => {
    const data = {
      id: location.state.role.id,
    };
    post("role/get", data, ({ role }) => setRole(role));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Detalle del Rol</h1>
            </div>
            <div className="spacer20" />

            <div className="grid">
              <div className="md:col-4">
                <label htmlFor="input">Nombre: {role.name}</label>
              </div>
            </div>
            <PermissionDetailTree products={role.products ?? []} />
            <div className="spacer20" />
            <Button
              type="button"
              label="Regresar"
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleDetail;
