/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React from "react";

export const AppFooter = () => {
  const today = new Date();
  return (
    <div className="layout-footer">
      <span className="footer-text" style={{ marginRight: "5px" }}>
        © {today.getFullYear()} Funeraria Nuestra Señora de Ujarras.
      </span>
      <span className="footer-text" style={{ marginLeft: "5px" }}>
        Desarrollado por Costa Code
      </span>
    </div>
  );
};
