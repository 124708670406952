import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { Checkbox } from "primereact/checkbox";
import GenericCalendar from "../../components/GenericComponents/GenericCalendar";
import ActionToolbar from "../../components/ActionsToolbar";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import { Controller, useForm } from "react-hook-form";
import {
  showSuccess,
  showWarning,
  showWarningDialog,
} from "../../utils/message";
import { convertStringToDate, formatDate } from "../../utils/format";
import pdf from "../../utils/pdf";
import createExcel from "../../utils/createExcel";

const BillList = () => {
  const ACTION_IDS = {
    REDUCE_INVENTORY: 8,
  };

  const [bill, setBill] = useState({});
  const [bills, setBills] = useState([]);

  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();
  const actions = location.state.actions;

  const { register, control, setValue, watch, handleSubmit } = useForm();
  const isAffiliate = watch("isAffiliate");

  useEffect(() => {
    handleSubmit(search)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disableAction = (actionId) => {
    if (
      bill.alreadyReducedInventory &&
      actionId === ACTION_IDS.REDUCE_INVENTORY
    ) {
      return true;
    }

    return Object.getOwnPropertyNames(bill).length === 0;
  };

  const exportToExcel = () => {
    let result = {
      columns: [
        { title: "Número de Factura", width: { width: 15 } },
        { title: "Cédula del Cliente", width: { width: 20 } },
        { title: "Nombre del Cliente", width: { width: 30 } },
        { title: "Cédula de la Compañía", width: { width: 15 } },
        { title: "Nombre de la Compañía", width: { width: 30 } },
        { title: "Fecha de emisión", width: { width: 15 } },
      ],
      data: [],
    };

    bills.forEach((element) => {
      result.data.push([
        element.number ?? "",
        element.client?.id ?? "",
        element.client?.name ?? "",
        element.company?.id ?? "",
        element.company?.name ?? "",
        formatDate(element.emissionDate),
      ]);
    });
    createExcel("Facturas-Particulares", [result]);
  };

  const exportToPdf = () => {
    const body = [];
    bills.forEach((element) => {
      const array = [
        element.number ?? "",
        element.client.id ?? "",
        element.client.name ?? "",
        element.client.email ?? "",
        formatDate(element.emissionDate),
      ];
      body.push(array);
    });
    const data = {
      head: [
        [
          "Número de Factura",
          "Cédula del Cliente",
          "Nombre del Cliente",
          "Correo del Cliente",
          "Fecha de emisión",
        ],
      ],
      body: body,
      startY: 25,
      bodyStyles: { valign: "top" },
      tableWidth: "auto",
      styles: {
        cellWidth: "wrap",
        rowPageBreak: "auto",
        halign: "justify",
      },
      columnStyles: { text: { cellWidth: "auto" } },
    };
    pdf.createPDF(
      "l",
      data,
      "Facturas-Particulares.pdf",
      "Reporte de facturas de particulares"
    );
  };

  const desireDeleteBill = () => {
    showWarningDialog(
      {
        title: "Eliminar Factura",
        text: "¿Desea eliminar la factura seleccionada?",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      },
      {
        confirm: () => {
          const request = {
            id: bill.id,
          };

          post("bill/delete", request, () => {
            showSuccess("La factura ha sido eliminada");
            handleSubmit(search)();
          });
        },
      }
    );
  };

  const redirect = (route) => {
    switch (route) {
      case "/billCreate":
      case "/billAffiliate":
        navigate(route);
        break;
      case "/invoicesReceivable":
        navigate("/receiptListAffiliate", {
          state: {
            title: "Listado de Facturas por Cobrar",
            isAffiliate: false,
            actions: [
              {
                name: "Pagar",
                route: "/receiptPay",
                isDependentOfElement: true,
                icon: "pi pi-money-bill",
              },
            ],
          },
        });
        break;
      case "/billDetail":
      case "/billReduce":
        navigate(route, {
          state: { bill: bill },
        });
        break;
      case "/exportExcel":
        exportToExcel();
        break;
      case "/exportPDF":
        exportToPdf();
        break;
      case "/billDelete":
        desireDeleteBill();
        break;
      default:
        break;
    }
  };

  const search = (formData) => {
    const request = {
      receptionId: formData.receptionId,
      startDate: convertStringToDate(formData.startDate),
      endDate: convertStringToDate(formData.endDate),
      isAffiliate: !formData.isAffiliate,
    };

    post("bill/search", request, ({ bills }) => {
      if (bills.length === 0) {
        showWarning("No se encontraron registros");
      }
      setBills(bills);
      setBill({});
    });
  };

  const select = (e) => {
    if (e.value) {
      setBill(e.value);
    } else {
      setBill({});
    }
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Listado de Facturas de Servicios</h1>
            </div>
            <ActionToolbar
              actions={actions}
              checkIfHaveToDisable={disableAction}
              executeAction={redirect}
            />
            <div className="spacer10" />
            <label id="searchLabel" htmlFor="input">
              Buscar por{" "}
            </label>
            <div className="spacer20" />
            <div className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">Número de Cedula del Cliente</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="number" {...register("receptionId")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Fecha de Inicio</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field }) => (
                    <GenericCalendar id="startDate" {...field} />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Fecha de Finalización</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field }) => (
                    <GenericCalendar id="endDate" {...field} />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Factura digital</label>
              </div>
              <div className="col-12 md:col-4">
                <Checkbox
                  onChange={(e) => setValue("isAffiliate", e.checked)}
                  checked={isAffiliate}
                />
              </div>
              <div className="md:col-12">
                <Button label="Buscar" onClick={handleSubmit(search)} />
              </div>
            </div>
            <DataTable
              paginator={true}
              rows={10}
              value={bills}
              selection={bill}
              onSelectionChange={select}
              emptyMessage="No se encontraron registros"
            >
              <Column selectionMode="single" style={{ width: "4em" }} />
              <Column field="number" header="Número de Factura" />
              <Column field="client.id" header="Cedula de Cliente" />
              <Column field="client.name" header="Nombre de Cliente" />
              <Column
                body={(rowData) =>
                  rowData.alreadyReducedInventory ? "Si" : "No"
                }
                header="Aplico articulos del inventario"
              />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillList;
