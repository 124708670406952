/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import ActionToolbar from "../../components/ActionsToolbar";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import { useForm } from "react-hook-form";
import { showSuccess, showWarning, showWarningDialog } from "../../utils/message";
import pdf from "../../utils/pdf";
import createExcel from "../../utils/createExcel";

const ProviderList = () => {
  const [provider, setProvider] = useState({});
  const [providers, setProviders] = useState([]);

  const navigate = useNavigate();
  const { post } = useService();
  const { register, handleSubmit } = useForm();
  const location = useLocation();
  const actions = location.state.actions;

  useEffect(() => {
    handleSubmit(search)();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

const disableAction = (actionId) => {
  return Object.getOwnPropertyNames(provider).length === 0;
};

const search = (formData) => {
  const request = {
    name: formData.name,
    idCard: formData.idCard,
  };

  post("provider/search", request, ({ providers }) => {
    if (providers.length === 0) {
      showWarning("No se encontraron registros");
    }
    setProviders(providers);
    setProvider({});
  });
};

const exportToPdf = () => {
  const body = [];
  providers.forEach((element) => {
    const array = [
      element.idCard ?? "",
      element.name ?? "",
      element.email ?? "",
      element.phone ?? "",
    ];
    body.push(array);
  });
  const data = {
    head: [
      ["Número de cédula", "Nombre", "Correo electrónico", "Teléfono"],
    ],
    body: body,
    startY: 25,
    bodyStyles: { valign: "top" },
    tableWidth: "auto",
    styles: { cellWidth: "wrap", rowPageBreak: "auto", halign: "justify" },
    columnStyles: { text: { cellWidth: "auto" } },
  };
  pdf.createPDF("l", data, "Proveedores.pdf", "Proveedores");
};

const exportToExcel = () => {
  let result = {
    columns: [
      { title: "Número de cédula", width: { width: 15 } },
      { title: "Nombre", width: { width: 30 } },
      { title: "Correo electrónico", width: { width: 30 } },
      { title: "Teléfono", width: { width: 20 } },
    ],
    data: [],
  };

  providers.forEach((element) => {
    result.data.push([
      element.idCard ?? "",
      element.name ?? "",
      element.email ?? "",
      element.phone ?? "",
    ]);
  });
  createExcel("Proveedores", [result]);
};

const select = (e) => {
  if (e.value) {
    setProvider(e.value);
  } else {
    setProvider({});
  }
};

const redirect = (route) => {
  switch (route) {
    case "/providerCreate":
      navigate(route);
      break;
    case "/providerEdit":
    case "/providerDetail":
      navigate(route, {
        state: { provider: provider },
      });
      break;
    case "/providerDelete":
      showWarningDialog(
        {
          title: "Eliminar Proveedor",
          text: "¿Desea eliminar el proveedor?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            const request = {
              id: provider.id,
            };
  
            post("provider/delete", request, () => {
              showSuccess("El proveedor se eliminó correctamente");
              handleSubmit(search)();
            });
          },
        }
      );
      break;
    case "/exportExcel":
      exportToExcel();
      break;
    case "/exportPDF":
      exportToPdf();
      break;
    default:
      break;
  }
};

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Listado de Proveedores</h1>
            </div>
            <ActionToolbar
              actions={actions}
              checkIfHaveToDisable={disableAction}
              executeAction={redirect}
            />
            <label id="searchLabel" htmlFor="input">
              Buscar por
            </label>
            <div className="spacer20" />
            <div className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">Cédula</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="number"
                  {...register("idcard")}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Nombre</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="text"
                  {...register("name")}
                />
              </div>

              <div className="md:col-12">
                <Button label="Buscar" onClick={handleSubmit(search)} />
              </div>
              <DataTable className="col-12"
                paginator={true}
                rows={10}
                value={providers}
                selection={provider}
                onSelectionChange={select}
                emptyMessage="No se encontraron registros"
              >
                <Column selectionMode="single" style={{ width: "4em" }} />
                <Column field="idCard" header="Cédula" />
                <Column field="name" header="Nombre" />
                <Column field="email" header="Correo Electrónico" />
                <Column field="phone" header="Teléfono" />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderList;
