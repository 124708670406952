/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { showWarningDialog } from "../../utils/message";
import classNames from "classnames";

const ComponentForm = ({
  headerClass,
  title,
  titleButton,
  actionToExecute,
  component
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (component) {
      reset({
        name: component.name,
        number: component.number,
        price: component.price,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component]);

  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
    reset,
  } = useForm();

  const goBackToHome = () => {
    if (isDirty === true) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios sin guardar dentro de la página, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            navigate(-1);
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  const submit = (data) => {
    actionToExecute(data);
  };

    return (
      <div className="p-fluid">
        <div className="grid">
          <div className="col-12">
            <div className="custom-card">
              <div className={headerClass}>
                <h1>{title}</h1>
              </div>
              <div className="spacer20" />
              <form onSubmit={handleSubmit(submit)} className="grid">
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Nombre</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="text"
                    {...register("name", {
                      required: true,
                    })}
                    className={classNames({
                      "p-invalid": !!errors.name,
                    })}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Número</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="number"
                    {...register("number", {
                      required: true,
                    })}
                    className={classNames({
                      "p-invalid": !!errors.number,
                    })}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Precio</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="number"
                    {...register("price", {
                      required: true,
                    })}
                    className={classNames({
                      "p-invalid": !!errors.price,
                    })}
                  />
                </div>
                <div className="md:col-12">
                  <Button label={titleButton} />
                  <Button
                    type="button"
                    label="Regresar"
                    onClick={goBackToHome}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default ComponentForm;