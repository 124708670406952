import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ActionToolbar from "../../components/ActionsToolbar";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import GenericCalendar from "../../components/GenericComponents/GenericCalendar";
import {
  convertStringToDate,
  formatDate,
  formatHour,
} from "../../utils/format";
import useService from "../../hooks/hook-service";
import {
  showSuccess,
  showWarning,
  showWarningDialog,
} from "../../utils/message";

const CongressList = () => {
  const ACTION_IDS = {
    ADD_AFFILIATE: 4,
    CLOSE: 5,
  };

  const { post } = useService();
  const location = useLocation();
  const navigate = useNavigate();
  const actions = location.state.actions;
  const [congress, setCongress] = useState({});
  const [congresses, setCongresses] = useState([]);
  const { handleSubmit, control } = useForm();

  const disableAction = (actionId) => {
    if (
      (actionId === ACTION_IDS.ADD_AFFILIATE ||
        actionId === ACTION_IDS.CLOSE) &&
      congress.state?.id === 65
    ) {
      return true;
    }

    return Object.getOwnPropertyNames(congress).length === 0;
  };

  useEffect(() => {
    handleSubmit(search)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const select = (e) => {
    if (e.value) {
      setCongress(e.value);
    } else {
      setCongress({});
    }
  };

  const closeCongress = () => {
    showWarningDialog(
      {
        title: "Cerrar Asamblea",
        text: "¿Desea continuar?",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      },
      {
        confirm: () => {
          const data = {
            id: congress.id,
          };
          post("congress/close", data, () => {
            showSuccess("Se cerró la asamblea correctamente");
            handleSubmit(search)();
          });
        },
      }
    );
  };

  const redirect = (route) => {
    switch (route) {
      case "/congressCreate":
        navigate(route);
        break;
      case "/congressEdit":
      case "/congressDetail":
      case "/congressAffiliate":
        navigate(route, {
          state: {
            congress: congress,
          },
        });
        break;
      case "/congressClose":
        closeCongress();
        break;
      default:
        break;
    }
  };

  const search = (formData) => {
    const data = {
      startDate: convertStringToDate(formData.startDate),
      endDate: convertStringToDate(formData.endDate),
    };

    post("congress/search", data, ({ congresses }) => {
      if (congresses.length === 0) {
        showWarning("No se encontraron registros");
      }
      setCongresses(congresses);
      setCongress({});
    });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Asambleas</h1>
            </div>
            <ActionToolbar
              actions={actions}
              checkIfHaveToDisable={disableAction}
              executeAction={redirect}
            />
            <label id="searchLabel" htmlFor="input">
              Buscar por{" "}
            </label>
            <div className="spacer20" />
            <div className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">Fecha de inicio</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field }) => (
                    <GenericCalendar id="startDate" {...field} />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Fecha de finalización</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field }) => (
                    <GenericCalendar id="endDate" {...field} />
                  )}
                />
              </div>
              <div className="md:col-12">
                <Button label="Buscar" onClick={handleSubmit(search)} />
              </div>
            </div>
            <DataTable
              value={congresses}
              paginator={true}
              rows={10}
              emptyMessage="No se encontraron registros"
              selection={congress}
              onSelectionChange={select}
            >
              <Column selectionMode="single" style={{ width: "4em" }} />
              <Column
                body={(row) => formatDate(row.date)}
                header="Fecha de la asamblea"
              />
              <Column body={(row) => formatHour(row.time)} header="Hora" />
              <Column field="description" header="Descripción" />
              <Column field="place" header="Lugar" />
              <Column field="state.name" header="Estado" />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CongressList;
