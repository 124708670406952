/**
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import useService from "../../hooks/hook-service";
import { useLocation, useNavigate } from "react-router-dom";
import createExcel from "../../utils/createExcel";
import { formatCurrencyValue } from "../../utils/format";

const ProviderDetail = () => {
  const [provider, setProvider] = useState({});

  const { post } = useService();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const data = {
      id: location.state.provider.id,
    };
    post("provider/get", data, ({ provider }) => setProvider(provider));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const executeReport = () => {
    let result = [
      {
        columns: [
          { title: "Cédula", width: { width: 20 } },
          { title: "Nombre", width: { width: 40 } },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          { title: "Código", width: { width: 20 } },
          { title: "Descrición", width: { width: 40 } },
          { title: "Precio", width: { width: 20 } },
          { title: "Tipo", width: { width: 20 } },
        ],
        data: [],
      },
    ];

    let data1 = [];

    data1.push({
      value: provider.idCard ?? "",
    });
    data1.push({
      value: provider.name ?? "",
    });

    result[0].data.push(data1);

    for (let index = 0; index < provider.supplies.length; index++) {
      let tempArray = [];
      let element = provider.supplies[index];

      tempArray.push({ value: element.code ?? "" });
      tempArray.push({ value: element.description ?? "" });

      tempArray.push({
        value: element.amount ?? "",
        style: { numFmt: "₡0.00" },
      });

      if (element.isConsignment) {
        tempArray.push({ value: "Consignación" });
      } else {
        tempArray.push({ value: "Contado" });
      }

      result[1].data.push(tempArray);
    }

    createExcel("Proveedor", result);
  }

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Detalle del Proveedor</h1>
            </div>
            <div className="spacer20" />
            <div className="grid">
              <div className="col-12 md:col-12">
                <Button
                  type="button"
                  label="Exportar Información Proveedor"
                  onClick={executeReport}
                  style={{
                    marginBottom: "5px",
                    width: "auto",
                    padding: "8px",
                    backgroundColor: "#18f231",
                    borderColor: "#18f231",
                  }}
                />
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Cédula: {provider.idCard}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Nombre: {provider.name}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Teléfono: {provider.phone}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Correo Electrónico: {provider.email}
                </label>
              </div>
              {provider.supplies?.length > 0 && (
                <div className="col-12">
                  <h2>Articulos</h2>
                  <DataTable
                    paginator={true}
                    rows={10}
                    value={provider.supplies}
                  >
                    <Column field="code" header="Codigo" />
                    <Column field="description" header="Descripción" />
                    <Column body={(rowData) => formatCurrencyValue(rowData.amount)} header="Precio" />
                    <Column body={({ isConsignment }) => isConsignment ? "Consignación" : "Contado"} header="Tipo" />
                  </DataTable>
                </div>
              )}
            </div>
            <div className="spacer20" />
            <Button type="button" label="Regresar" onClick={() => navigate(-1)} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProviderDetail;