/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React from "react";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import { formatCurrencyValue } from "../../utils/format";

const ComponentDetail = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    navigate(-1);
  };

    return (
      <div className="p-fluid">
        <div className="grid">
          <div className="col-12">
            <div className="custom-card">
              <div className="custom-card-header-info">
                <h1>Detalle del Componente</h1>
              </div>
              <div className="spacer20" />
                <div className="grid">
                  <div className="md:col-4">
                    <label htmlFor="input">
                      Nombre: {location.state.component.name}
                    </label>
                  </div>
                  <div className="md:col-4">
                    <label htmlFor="input">
                      Número: {location.state.component.number}
                    </label>
                  </div>
                  <div className="md:col-4">
                    <label htmlFor="input">
                      Precio: {formatCurrencyValue(location.state.component.price)}
                    </label>
                  </div>
                </div>
              <div className="spacer20" />
              <Button type="button" label="Regresar" onClick={goBack} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default ComponentDetail;