/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Allan Moya <amoya@costacodecr.com>
 */

import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import "./styles/layout.scss";

//Layouts
import { AppLayout } from "./layouts/AppLayout";

//Components
import RequireAuth from "./components/RequireAuth";
import CheckLocation from "./components/CheckLocation";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";

import UserProfile from "./pages/Profile";
import UserList from "./pages/User/List";
import UserEdit from "./pages/User/Edit";
import UserCreate from "./pages/User/Create";
import UserAssignRole from "./pages/User/AssignRole";
import UserDetail from "./pages/User/Detail";
import RoleList from "./pages/Rol/List";
import RoleCreate from "./pages/Rol/Create";
import RoleEdit from "./pages/Rol/Edit";
import RoleDetail from "./pages/Rol/Detail";
import LogList from "./pages/Log/List";
import ReceiptList from "./pages/Receipt/List";
import ReceiptDetail from "./pages/Receipt/Detail";
import AffiliateList from "./pages/Affiliate/List";
import AffiliateDetail from "./pages/Affiliate/Detail";
import AffiliateEdit from "./pages/Affiliate/Edit";
import ReceiptAffiliateList from "./pages/Receipt/Affiliate/List";
import ReceiptPay from "./pages/Receipt/Pay";
import ReceiptPaymentArrangmentForm from "./pages/Receipt/PaymentArragement/PaymentArrangement";
import ReportList from "./pages/Reports/List";
import ReportDetail from "./pages/Reports/Detail";
import ReceiptEdit from "./pages/Receipt/Edit";
import ContractList from "./pages/Contract/List";
import ContractCreate from "./pages/Contract/Create";
import ContractDetail from "./pages/Contract/Detail";
import ContractEdit from "./pages/Contract/Edit";
import ContractTransfer from "./pages/Contract/Transfer";
import ContractUploadFile from "./pages/Contract/UploadFile";
import ContractDownloadFile from "./pages/Contract/DownloadFile";
import BeneficiaryList from "./pages/Beneficiary/List";
import BeneficiaryDetail from "./pages/Beneficiary/Detail";
import BeneficiaryEdit from "./pages/Beneficiary/Edit";
import NotificationList from "./pages/Notification/List";
import NotificationSend from "./pages/Notification/Send";
import CongressList from "./pages/Congress/List";
import CongressCreate from "./pages/Congress/Create";
import CongressEdit from "./pages/Congress/Edit";
import CongressDetail from "./pages/Congress/Detail";
import CongressAddAffiliate from "./pages/Congress/AddAffiliate";
import BillList from "./pages/Bill/List";
import BillCreate from "./pages/Bill/Create";
import BillCreateAffiliate from "./pages/Bill/CreateAffiliate";
import BillDetail from "./pages/Bill/Detail";
import BillReduceInventory from "./pages/Bill/ReduceInventory";
import AdvanceServiceInvoiceList from "./pages/AdvanceServiceInvoice/List";
import AdvanceServiceInvoiceCreate from "./pages/AdvanceServiceInvoice/Create";
import AdvanceServiceInvoiceDetail from "./pages/AdvanceServiceInvoice/Detail";
import AssetList from "./pages/Asset/List";
import AssetCreate from "./pages/Asset/Create";
import AssetEdit from "./pages/Asset/Edit";
import AssetDetail from "./pages/Asset/Detail";
import BillsToPayList from "./pages/BillsToPay/List";
import BillsToPayCreate from "./pages/BillsToPay/Create";
import BillsToPayDetail from "./pages/BillsToPay/Detail";
import ComponentList from "./pages/Component/List";
import ComponentDetail from "./pages/Component/Detail";
import ComponentCreate from "./pages/Component/Create";
import ComponentEdit from "./pages/Component/Edit";
import DocumentList from "./pages/Document/List";
import ElectoralVoters from "./pages/ElectoralVoter";
import FeeList from "./pages/Fee/List";
import FeeDetail from "./pages/Fee/Detail";
import FeeCreate from "./pages/Fee/Create";
import FeeEdit from "./pages/Fee/Edit";
import InventoryList from "./pages/Inventory/List";
import InventoryDetail from "./pages/Inventory/Detail";
import ProviderList from "./pages/Provider/List";
import ProviderDetail from "./pages/Provider/Detail";
import ProviderCreate from "./pages/Provider/Create";
import ProviderEdit from "./pages/Provider/Edit";
import ServiceList from "./pages/Service/List";
import ServiceDetail from "./pages/Service/Detail";
import ServiceCreate from "./pages/Service/Create";
import ServiceEdit from "./pages/Service/Edit";

class App extends Component {
  createPageAuthenticateAndCheckLocation = (component) => {
    return (
      <RequireAuth>
        <CheckLocation>
          <AppLayout>{component}</AppLayout>
        </CheckLocation>
      </RequireAuth>
    );
  };

  createPageAuthenticate = (component) => {
    return (
      <RequireAuth>
        <AppLayout>{component}</AppLayout>
      </RequireAuth>
    );
  };

  render() {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route
          path="dashboard"
          element={this.createPageAuthenticate(<Dashboard />)}
        />
        <Route
          path="profile"
          element={this.createPageAuthenticateAndCheckLocation(<UserProfile />)}
        />
        <Route
          path="userList"
          element={this.createPageAuthenticateAndCheckLocation(<UserList />)}
        />
        <Route
          path="userCreate"
          element={this.createPageAuthenticate(<UserCreate />)}
        />
        <Route
          path="userEdit"
          element={this.createPageAuthenticateAndCheckLocation(<UserEdit />)}
        />
        <Route
          path="assignPermissions"
          element={this.createPageAuthenticateAndCheckLocation(
            <UserAssignRole />
          )}
        />
        <Route
          path="userDetail"
          element={this.createPageAuthenticateAndCheckLocation(<UserDetail />)}
        />

        <Route
          path="roleList"
          element={this.createPageAuthenticateAndCheckLocation(<RoleList />)}
        />
        <Route
          path="roleDetail"
          element={this.createPageAuthenticateAndCheckLocation(<RoleDetail />)}
        />
        <Route
          path="roleCreate"
          element={this.createPageAuthenticate(<RoleCreate />)}
        />
        <Route
          path="roleEdit"
          element={this.createPageAuthenticateAndCheckLocation(<RoleEdit />)}
        />

        <Route
          path="log"
          element={this.createPageAuthenticateAndCheckLocation(<LogList />)}
        />

        <Route
          path="receiptList"
          element={this.createPageAuthenticateAndCheckLocation(<ReceiptList />)}
        />

        <Route
          path="receiptDetail"
          element={this.createPageAuthenticateAndCheckLocation(
            <ReceiptDetail />
          )}
        />
        <Route
          path="receiptListAffiliate"
          element={this.createPageAuthenticateAndCheckLocation(
            <ReceiptAffiliateList />
          )}
        />

        <Route
          path="receiptPay"
          element={this.createPageAuthenticateAndCheckLocation(<ReceiptPay />)}
        />

        <Route
          path="receiptChangePay"
          element={this.createPageAuthenticateAndCheckLocation(<ReceiptEdit />)}
        />

        <Route
          path="paymentArrangementCreate"
          element={this.createPageAuthenticateAndCheckLocation(
            <ReceiptPaymentArrangmentForm />
          )}
        />

        <Route
          path="affiliateList"
          element={this.createPageAuthenticateAndCheckLocation(
            <AffiliateList />
          )}
        />

        <Route
          path="affiliateDetail"
          element={this.createPageAuthenticateAndCheckLocation(
            <AffiliateDetail />
          )}
        />

        <Route
          path="editAffiliate"
          element={this.createPageAuthenticateAndCheckLocation(
            <AffiliateEdit />
          )}
        />

        <Route
          path="reportsList"
          element={this.createPageAuthenticateAndCheckLocation(<ReportList />)}
        />
        <Route
          path="reportDetail"
          element={this.createPageAuthenticateAndCheckLocation(
            <ReportDetail />
          )}
        />

        <Route
          path="contractList"
          element={this.createPageAuthenticateAndCheckLocation(
            <ContractList />
          )}
        />
        <Route
          path="contractCreate"
          element={this.createPageAuthenticate(<ContractCreate />)}
        />
        <Route
          path="contractDetail"
          element={this.createPageAuthenticateAndCheckLocation(
            <ContractDetail />
          )}
        />
        <Route
          path="editContract"
          element={this.createPageAuthenticateAndCheckLocation(
            <ContractEdit />
          )}
        />
        <Route
          path="contractTransfer"
          element={this.createPageAuthenticateAndCheckLocation(
            <ContractTransfer />
          )}
        />
        <Route
          path="downloadFile"
          element={this.createPageAuthenticateAndCheckLocation(
            <ContractDownloadFile />
          )}
        />

        <Route
          path="beneficiariesList"
          element={this.createPageAuthenticateAndCheckLocation(
            <BeneficiaryList />
          )}
        />
        <Route
          path="beneficiaryDetail"
          element={this.createPageAuthenticateAndCheckLocation(
            <BeneficiaryDetail />
          )}
        />
        <Route
          path="beneficiaryEdit"
          element={this.createPageAuthenticateAndCheckLocation(
            <BeneficiaryEdit />
          )}
        />
        <Route
          path="congressCreate"
          element={this.createPageAuthenticate(<CongressCreate />)}
        />
        <Route
          path="congressEdit"
          element={this.createPageAuthenticateAndCheckLocation(
            <CongressEdit />
          )}
        />
        <Route
          path="componentList"
          element={this.createPageAuthenticateAndCheckLocation(
            <ComponentList />
          )}
        />
        <Route
          path="componentCreate"
          element={this.createPageAuthenticate(<ComponentCreate />)}
        />
        <Route
          path="componentEdit"
          element={this.createPageAuthenticateAndCheckLocation(<ComponentEdit />)}
        />
        <Route
          path="providerList"
          element={this.createPageAuthenticateAndCheckLocation(
            <ProviderList />
          )}
        />
        <Route
          path="providerCreate"
          element={this.createPageAuthenticate(<ProviderCreate />)}
        />
        <Route
          path="providerEdit"
          element={this.createPageAuthenticateAndCheckLocation(<ProviderEdit />)}
        />
        <Route
          path="billsToPayList"
          element={this.createPageAuthenticateAndCheckLocation(
            <BillsToPayList />
          )}
        />
        <Route
          path="billsToPayCreate"
          element={this.createPageAuthenticate(<BillsToPayCreate />)}
        />
        <Route
          path="feeList"
          element={this.createPageAuthenticateAndCheckLocation(<FeeList />)}
        />
        <Route
          path="feeCreate"
          element={this.createPageAuthenticate(<FeeCreate />)}
        />
        <Route
          path="feeEdit"
          element={this.createPageAuthenticateAndCheckLocation(<FeeEdit />)}
        />
        <Route
          path="assetList"
          element={this.createPageAuthenticateAndCheckLocation(<AssetList />)}
        />
        <Route
          path="assetCreate"
          element={this.createPageAuthenticate(<AssetCreate />)}
        />
        <Route
          path="assetEdit"
          element={this.createPageAuthenticateAndCheckLocation(<AssetEdit />)}
        />
        <Route
          path="servicesList"
          element={this.createPageAuthenticateAndCheckLocation(
            <ServiceList />
          )}
        />
        <Route
          path="servicesCreate"
          element={this.createPageAuthenticate(
            <ServiceCreate />
          )}
        />
        <Route
          path="servicesEdit"
          element={this.createPageAuthenticateAndCheckLocation(
            <ServiceEdit/>
          )}
        />
        <Route
          path="inventoryList"
          element={this.createPageAuthenticateAndCheckLocation(
            <InventoryList />
          )}
        />
        <Route
          path="inventoryDetail"
          element={this.createPageAuthenticateAndCheckLocation(
            <InventoryDetail />
          )}
        />
        <Route
          path="billList"
          element={this.createPageAuthenticateAndCheckLocation(<BillList />)}
        />
        <Route
          path="billCreate"
          element={this.createPageAuthenticate(<BillCreate />)}
        />
        <Route
          path="billDetail"
          element={this.createPageAuthenticateAndCheckLocation(<BillDetail />)}
        />
        <Route
          path="contractUploadFile"
          element={this.createPageAuthenticateAndCheckLocation(
            <ContractUploadFile />
          )}
        />
        <Route
          path="notificationList"
          element={this.createPageAuthenticateAndCheckLocation(
            <NotificationList />
          )}
        />
        <Route
          path="notificationSend"
          element={this.createPageAuthenticateAndCheckLocation(
            <NotificationSend />
          )}
        />
        <Route
          path="electoralVoters"
          element={this.createPageAuthenticateAndCheckLocation(
            <ElectoralVoters />
          )}
        />
        <Route
          path="documentList"
          element={this.createPageAuthenticateAndCheckLocation(
            <DocumentList />
          )}
        />
        <Route
          path="feeDetail"
          element={this.createPageAuthenticateAndCheckLocation(<FeeDetail />)}
        />
        <Route
          path="providerDetail"
          element={this.createPageAuthenticateAndCheckLocation(
            <ProviderDetail />
          )}
        />
        <Route
          path="componentDetail"
          element={this.createPageAuthenticateAndCheckLocation(
            <ComponentDetail />
          )}
        />
        <Route
          path="servicesDetail"
          element={this.createPageAuthenticateAndCheckLocation(
            <ServiceDetail />
          )}
        />
        <Route
          path="assetDetail"
          element={this.createPageAuthenticateAndCheckLocation(<AssetDetail />)}
        />
        <Route
          path="billsToPayDetail"
          element={this.createPageAuthenticateAndCheckLocation(
            <BillsToPayDetail />
          )}
        />
        <Route
          path="billAffiliate"
          element={this.createPageAuthenticate(<BillCreateAffiliate />)}
        />
        <Route
          path="congressList"
          element={this.createPageAuthenticateAndCheckLocation(
            <CongressList />
          )}
        />
        <Route
          path="congressAffiliate"
          element={this.createPageAuthenticateAndCheckLocation(
            <CongressAddAffiliate />
          )}
        />
        <Route
          path="congressDetail"
          element={this.createPageAuthenticateAndCheckLocation(
            <CongressDetail />
          )}
        />

        <Route
          path="billReduce"
          element={this.createPageAuthenticateAndCheckLocation(
            <BillReduceInventory />
          )}
        />
        <Route
          path="advanceServiceInvoiceList"
          element={this.createPageAuthenticateAndCheckLocation(
            <AdvanceServiceInvoiceList />
          )}
        />
        <Route
          path="advanceServiceInvoiceCreate"
          element={this.createPageAuthenticate(<AdvanceServiceInvoiceCreate />)}
        />
        <Route
          path="advanceServiceInvoiceDetail"
          element={this.createPageAuthenticateAndCheckLocation(
            <AdvanceServiceInvoiceDetail />
          )}
        />
        <Route path="*" element={<Home />} />
      </Routes>
    );
  }
}

export default App;
