import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatCurrencyValue, formatDate } from "../../utils/format";

const AdvanceServiceInvoiceHeader = ({ reserveBill }) => {
  return (
    <>
      <div className="md:col-4">
        <label htmlFor="input">
          Fecha de creación:
          {formatDate(reserveBill?.emissionDate)}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Nombre del Responsable: {reserveBill?.client?.name}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Cédula del Responsable: {reserveBill?.client?.id}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Teléfono del Responsable: {reserveBill?.client?.phone}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Correo Electrónico del Responsable:
          {reserveBill?.client?.email}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Subtotal de la factura:
          {formatCurrencyValue(reserveBill?.subtotal)}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Total de la factura: {formatCurrencyValue(reserveBill?.subtotal)}
        </label>
      </div>
      {reserveBill?.details?.length > 0 && (
        <div className="col-12">
          <h2>Articulos</h2>
          <DataTable paginator={true} rows={10} value={reserveBill?.details}>
            <Column
              body={(rowData) => formatCurrencyValue(rowData.price)}
              field="price"
              header="Precio"
            />
            <Column field="quantity" header="Cantidad" />
            <Column field="service.name" header="Servicio" />
          </DataTable>
        </div>
      )}
    </>
  );
};

export default AdvanceServiceInvoiceHeader;
