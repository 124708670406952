/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatDate, formatHour } from "../../utils/format";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";

const CongressDetail = () => {
  const [congress, setCongress] = useState({});

  const location = useLocation();
  const navigate = useNavigate();
  const { post } = useService();

  useEffect(() => {
    post(
      "congress/get",
      {
        id: location.state.congress.id,
      },
      ({ congress }) => {
        setCongress(congress);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Detalle de la asamblea</h1>
            </div>
            <div className="spacer20" />
            <div className="grid">
              <div className="md:col-4">
                <label htmlFor="input">
                  Fecha: {formatDate(congress?.date)}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Hora: {formatHour(congress?.time)}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Descripción: {congress?.description}
                </label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">Lugar: {congress?.place}</label>
              </div>
              <div className="md:col-4">
                <label htmlFor="input">Estado: {congress?.state?.name}</label>
              </div>
            </div>
            <div className="spacer20" />
            <h2>Participantes</h2>
            <DataTable
              paginator={true}
              rows={10}
              value={congress?.affiliates}
              emptyMessage="No se encontraron registros"
            >
              <Column field="idCard" header="Cédula" />
              <Column field="firstName" header="Nombre" />
              <Column field="lastName" header="Apellidos" />
            </DataTable>
            <div className="spacer20" />
            <Button type="button" label="Regresar" onClick={goBack} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CongressDetail;
