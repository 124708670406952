import React from "react";
import { formatCurrencyValue, formatDate } from "../../utils/format";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const BillHeader = ({ bill }) => {
  const generateTotal = () => {
    let sales = 0;
    let otherTaxes = 0;
    if (bill.salesTax > 0) {
      const percentSale = bill.salesTax / 100;
      sales = bill.subtotal * percentSale;
    }
    if (bill.otherTaxes > 0) {
      const percentOther = bill.otherTaxes / 100;
      otherTaxes = bill.subtotal * percentOther;
    }
    return bill.subtotal + otherTaxes + sales;
  };

  return (
    <>
      <div className="md:col-4">
        <label htmlFor="input">Número de Factura: {bill.number}</label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Fecha de creación: {formatDate(bill.emissionDate)}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Nombre del Responsable: {bill?.client?.name}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Cédula del Responsable: {bill?.client?.id}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Teléfono del Responsable: {bill?.client?.phone}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Correo Electrónico del Responsable: {bill?.client?.email}
        </label>
      </div>
      {bill?.person && (
        <div className="md:col-4">
          <label htmlFor="input">
            Cédula del Fallecido: {bill?.person?.idCard}
          </label>
        </div>
      )}
      {bill?.person && (
        <div className="md:col-4">
          <label htmlFor="input">
            Nombre del Fallecido: {bill?.person?.firstName}
          </label>
        </div>
      )}
      {bill?.person && (
        <div className="md:col-4">
          <label htmlFor="input">
            Apellidos del Fallecido: {bill?.person?.lastName}
          </label>
        </div>
      )}
      {bill.paymentType?.name && (
        <div className="md:col-4">
          <label htmlFor="input">
            Condiccion de Venta: {bill.paymentType.name}
          </label>
        </div>
      )}
      {bill.observations && (
        <div className="md:col-4">
          <label htmlFor="input">Observaciones: {bill.observations}</label>
        </div>
      )}
      {bill.salesTax > 0 && (
        <div className="md:col-4">
          <label htmlFor="input">Impuesto de ventas: {bill.salesTax}</label>
        </div>
      )}
      {bill.otherTaxes > 0 && (
        <div className="md:col-4">
          <label htmlFor="input">Otros impuestos: {bill.otherTaxes}</label>
        </div>
      )}
      <div className="md:col-4">
        <label htmlFor="input">
          Subtotal de la factura:
          {formatCurrencyValue(bill.subtotal)}
        </label>
      </div>
      <div className="md:col-4">
        <label htmlFor="input">
          Total de la factura: {formatCurrencyValue(generateTotal())}
        </label>
      </div>
      {bill?.details?.length > 0 && (
        <div className="md:col-12">
          <h2>Articulos</h2>
          <DataTable paginator={true} rows={10} value={bill.details}>
            <Column
              body={(rowData) => formatCurrencyValue(rowData.price)}
              field="price"
              header="Precio"
            />
            <Column field="quantity" header="Cantidad" />
            <Column field="service.name" header="Servicio" />
          </DataTable>
        </div>
      )}
    </>
  );
};

export default BillHeader;
