/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./index.css";

import { createHash } from "../../utils/security";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import WaitingDialog from "../../components/GenericComponents/WaitingDialog";
import { showSuccess, showWarningDialog } from "../../utils/message";
import { useNavigate } from "react-router-dom";
import { setAuthentication } from "../../store/authManager";
import { useDispatch } from "react-redux";
import useService from "../../hooks/hook-service";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, insecurePost } = useService({ useStore: false });

  const loginHandler = () => {
    createHash(password).then((result) => {
      const request = {
        username: username,
        password: result,
      };
      insecurePost("user/login", request, (data) => {
        const currentTime = new Date();
        currentTime.setMinutes(currentTime.getMinutes() + 10);

        dispatch(
          setAuthentication({
            token: data.token,
            user: data.user,
            checkingDate: currentTime,
          })
        );
        navigate("/dashboard");
      });
    });
  };

  const showSwal = () =>
    showWarningDialog(
      {
        title: "Precaución",
        text: "¿Desea recuperar su contraseña?",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      },
      {
        confirm: () => {
          const request = {
            userName: username,
          };

          insecurePost("user/forgotPassword", request, () => {
            showSuccess(
              "La contraseña ha sido enviada a su correo electrónico"
            );
          });
        },
      }
    );

  return (
    <div className="login-height imageLogin">
      <WaitingDialog visible={isLoading} />

      <Card
        title="Inicio de Sesión"
        style={{ textAlign: "center", marginTop: "200px" }}
        className="form"
      >
        <div className="grid">
          <div className="col-12">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className="pi pi-user" />
              </span>
              <InputText
                className="all-width"
                placeholder="Nombre de usuario"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon ">
                <i className="pi pi-lock" />
              </span>
              <InputText
                className="all-width"
                placeholder="Contraseña"
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
          </div>
          <div className="col-12">
            <Button label="Iniciar Sessión" onClick={loginHandler} />
          </div>
          <span className="col-12">
            <a id="forgotPass" onClick={showSwal} style={{ cursor: "pointer" }}>
              <label htmlFor="forgotPass">¿Olvidó su contraseña?</label>
            </a>
          </span>
        </div>
      </Card>
    </div>
  );
};

export default Login;
