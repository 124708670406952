import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatDate } from "../../utils/format";

const BeneficiaryDetails = ({
  beneficiaries,
  children,
  contractType,
  onSelectionChange,
  beneficiary,
}) => {
  if (!beneficiaries || (beneficiaries && beneficiaries.length === 0))
    return null;

  return (
    <>
      <h2 className=" md:col-12">Listado de Beneficiarios</h2>
      <DataTable className=" md:col-12"
        value={beneficiaries}
        onSelectionChange={onSelectionChange}
        selection={beneficiary}
      >
        {onSelectionChange && (
          <Column selectionMode="single" style={{ width: "4em" }} />
        )}
        <Column field="idCard" header="Cédula" />
        <Column field="firstName" header="Nombre" />
        <Column field="lastName" header="Apellidos" />
        <Column
          body={({ birthDate }) => formatDate(birthDate)}
          header="Fecha de Nacimiento"
        />
        <Column field="kinship.name" header="Parentesco" />
        {contractType && contractType !== 84 && (
          <Column
            body={(rowData) => (rowData.isAdditional ? "Si" : "No")}
            header="Adicional"
          />
        )}
        {children}
      </DataTable>
    </>
  );
};

export default BeneficiaryDetails;
