import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../store/loadingManager";
import axios from "axios";
import { showError } from "../utils/message";
import { removeAuthentication } from "../store/authManager";
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const useService = (
  config = {
    useStore: true,
  }
) => {
  const auth = useSelector((state) => state.authManager.value);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const getHeaders = () => {
    let token = auth.token;
    return { headers: { Authorization: token } };
  };

  const post = useCallback(async (url, body, callback) => {
    setIsLoading(true);

    if (config?.useStore) {
      dispatch(showLoader());
    }

    instance
      .post(url, body, getHeaders())
      .then(({ data }) => {
        const { errors, result, ...response } = data;

        if (!errors[0] && callback) {
          callback(response);
          return;
        }

        if (errors[0].code === 2) {
          showError("La sesión expiró, por favor vuelva ingresar");
          if (config?.useStore) {
            dispatch(removeAuthentication());
          }
          return;
        }

        showError(errors[0].message);
      })
      .catch(async (error) => {
        if (error.response?.status === 502) {
          await delay(3000);
          post(url, body, callback);
        } else {
          console.error(error);
          const message =
            "Ha ocurrido un error, intentelo de nuevo o comuníquese con soporte técnico";
          showError(message);
          dispatch(removeAuthentication());
        }
      })
      .finally(() => {
        setIsLoading(false);

        if (config?.useStore) dispatch(hideLoader());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const insecurePost = useCallback(async (url, body, callback) => {
    setIsLoading(true);

    if (config?.useStore) {
      dispatch(showLoader());
    }

    instance
      .post(url, body)
      .then(({ data }) => {
        const { errors, result, ...response } = data;

        if (!errors[0] && callback) {
          callback(response);
          return;
        }

        showError(errors[0].message);
      })
      .catch(async (error) => {
        if (error.response?.status === 502) {
          await delay(5000);
          insecurePost(url, body, callback);
        } else {
          console.error(error);
          const message =
            "Ha ocurrido un error, intentelo de nuevo o comuníquese con soporte técnico";
          showError(message);
        }
      })
      .finally(() => {
        setIsLoading(false);

        if (config?.useStore) dispatch(hideLoader());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const manyPost = useCallback(async (requests, callback) => {
    setIsLoading(true);

    if (config?.useStore) {
      dispatch(showLoader());
    }

    Promise.all(
      requests.map(({ url, body }) => {
        return instance.post(url, body, getHeaders());
      })
    )
      .then((values) => {
        const result = [];
        for (let i = 0; i < values.length; i++) {
          let data = values[i].data;

          if (!data.errors[0]) {
            result.push(data);
            continue;
          }

          if (data.errors[0].code === 2) {
            showError("La sesión expiró, por favor vuelva ingresar");
            if (config?.useStore) {
              dispatch(removeAuthentication());
            }
            return;
          }

          showError(data.errors[0].message);
          return;
        }

        callback(result);
      })
      .catch(async (error) => {
        if (error.response?.status === 502) {
          await delay(5000);
          manyPost(requests, callback);
        } else {
          const message =
            "Ha ocurrido un error, intentelo de nuevo o comuníquese con soporte técnico";
          showError(message);
          dispatch(removeAuthentication());
        }
      })
      .finally(() => {
        setIsLoading(false);

        if (config?.useStore) dispatch(hideLoader());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading, post, insecurePost, manyPost };
};

export default useService;
