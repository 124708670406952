import { Tree } from "primereact/tree";
import React, { useEffect, useState } from "react";

const PermissionEditorTree = ({
  allPermissions,
  initialPermissions,
  onChange,
}) => {
  const [treeView, setTreeView] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    if (allPermissions) {
      createTreeView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPermissions]);

  useEffect(() => {
    if (initialPermissions && initialPermissions.length > 0) {
      loadSelectedTreeElements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPermissions]);

  const createTreeView = () => {
    let treeView = [];
    allPermissions.forEach((element) => {
      let options = [];
      for (let option of element.options) {
        let actions = [];
        for (let action of option.actions) {
          let actionItem = {
            key: `${element.productId}-${option.optionId}-${action.actionId}`,
            label: action.name,
            icon: "pi pi-fw pi-cog",
          };
          actions.push(actionItem);
        }
        let optionItem = {
          key: `${element.productId}-${option.optionId}`,
          label: option.name,
          children: actions,
          icon: "pi pi-fw pi-cog",
        };
        options.push(optionItem);
      }
      let treeItem = {
        key: `${element.productId}`,
        label: element.name,
        children: options,
        icon: "pi pi-fw pi-cog",
      };
      treeView.push(treeItem);
    });
    setTreeView(treeView);
  };

  const areAllActionsChecked = (productId, optionId, length) => {
    let product = allPermissions.find((x) => x.productId === productId);

    if (!product) return false;

    let result = product.options.find((x) => x.optionId === optionId);
    return result.actions.length === length;
  };

  const areAllOptionsChecked = (productId, length) => {
    let result = allPermissions.find((x) => x.productId === productId);
    return result && result.options.length === length;
  };

  const getSelectedTreeElements = (selectedPermissions) => {
    let permissions = allPermissions;
    let selectedKeys = [];
    Object.keys(selectedPermissions).forEach((key) => {
      selectedKeys.push(key);
    });

    let productsTemp = [];

    permissions.forEach((element) => {
      let options = [];
      for (let option of element.options) {
        let actions = [];
        for (let action of option.actions) {
          let selectkey = selectedKeys.find(
            (x) =>
              x === `${element.productId}-${option.optionId}-${action.actionId}`
          );

          if (selectkey) {
            actions.push(action);
          }
        }
        if (actions.length > 0) {
          options.push({ ...option, actions: actions });
        }
      }

      if (options.length > 0) {
        productsTemp.push({ ...element, options: options });
      }
    });
    return productsTemp;
  };

  const loadSelectedTreeElements = () => {
    let treeView = {};
    initialPermissions.forEach((element) => {
      let partialFlag = false;
      for (let option of element.options) {
        for (let action of option.actions) {
          let key = `${element.productId}-${option.optionId}-${action.actionId}`;
          let prop = { checked: true, partialChecked: false };
          treeView[key] = prop;
        }
        let key = `${element.productId}-${option.optionId}`;
        let prop = { checked: null, partialChecked: null };
        if (
          areAllActionsChecked(
            element.productId,
            option.optionId,
            option.actions.length
          )
        ) {
          prop.checked = true;
          prop.partialChecked = false;
        } else {
          prop.checked = false;
          prop.partialChecked = true;
          partialFlag = true;
        }
        treeView[key] = prop;
      }
      let key = `${element.productId}`;
      let prop = { checked: null, partialChecked: null };
      if (areAllOptionsChecked(element.productId, element.options.length)) {
        prop.checked = true;
        prop.partialChecked = false;
        if (partialFlag) {
          prop.checked = false;
          prop.partialChecked = true;
        }
      } else {
        prop.checked = false;
        prop.partialChecked = true;
      }
      treeView[key] = prop;
    });
    setSelectedPermissions(treeView);
  };

  return (
    <div className="col-12">
      <div className="card card-w-title">
        <div className="grid">
          <div className="col-12 md:col-6">
            <h2>Permisos de acceso</h2>
            <Tree
              value={treeView}
              selectionMode="checkbox"
              selectionKeys={selectedPermissions}
              onSelectionChange={(event) => {
                setSelectedPermissions(event.value);
                if (onChange) {
                  onChange(getSelectedTreeElements(event.value));
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermissionEditorTree;
