/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatCurrencyValue, formatDate } from "../../../utils/format";
import { Checkbox } from "primereact/checkbox";
import Voucher from "../../../components/Vouchers/PaymentVoucher";
import { showSuccess, showWarningDialog } from "../../../utils/message";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../../hooks/hook-service";
import WayToPay from "../../../components/WayToPayForm";
import AffiliateHeader from "../../../components/AffiliateHeader";
import { FormProvider, useForm } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { AffiliateFormAndUpdate } from "../../../components/AffiliateForm";
import { TabPanel, TabView } from "primereact/tabview";
import DocumentToPrint from "../../../components/Vouchers/DocumentToPrint";

const ReceiptPaymentArrangement = () => {
  const [affiliate, setAffiliate] = useState({});
  const [contractToPrint, setContractToPrint] = useState();
  const [payment, setPayment] = useState();
  const [receiptTem, setReceiptTem] = useState({});
  const [desireToEditAffiliate, setDesireToEditAffiliate] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const formMethods = useForm({
    defaultValues: {
      showExtraAmount: false,
    },
  });
  const { handleSubmit, watch, register, getValues, setValue } = formMethods;
  const showExtraAmount = watch("showExtraAmount");
  const { post } = useService();

  useEffect(() => {
    post(
      "affiliate/get",
      {
        id: location.state.affiliate.id,
      },
      ({ affiliate }) => {
        setAffiliate(affiliate);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createArrangement = (formData) => {
    let data = {
      paymentArrangement: {
        affiliate: affiliate,
        amountToPay: formData.amountToPay,
      },
    };

    if (showExtraAmount) {
      data.extraordinaryAmount = formData.amount;
      data.transferType = {
        id: formData.transferType,
      };
      data.referenceNumber = formData.referenceNumber;
    }
    post("paymentArrangement/create", data, ({ paymentReceived, document }) => {
      setPayment(paymentReceived);
      setContractToPrint(document.data);
      showSuccess("El arreglo de pago se creó correctamente");
    });
  };

  const goToBack = () => {
    if (receiptTem && Object.getOwnPropertyNames(receiptTem).length !== 0) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios sin guardar dentro de la página, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            navigate(-1);
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  const changeAmount = () => {
    const amountToPay = getValues("amountToPay");
    const amount = getValues("amount");
    if (amountToPay > 0) {
      let data = {
        paymentArrangement: {
          affiliate: affiliate,
          amountToPay: amountToPay,
        },
      };
      if (amount) {
        data.extraordinaryAmount = amount;
      }

      post("paymentArrangement/calcule", data, ({ paymentArrangement }) =>
        setReceiptTem(paymentArrangement)
      );
    }
  };

  const closeEditAffiliate = (isUpdateRequired) => {
    setDesireToEditAffiliate(false);

    if (isUpdateRequired) {
      post(
        "affiliate/get",
        {
          id: location.state.affiliate.id,
        },
        ({ affiliate }) => {
          setAffiliate(affiliate);
        }
      );
    }
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-create">
              <h1>Arreglo por Cuotas Atrasadas</h1>
            </div>
            <div className="spacer20" />
            <Dialog
              header="Información de Afiliado"
              visible={desireToEditAffiliate}
              modal={true}
              onHide={() => setDesireToEditAffiliate(false)}
            >
              <AffiliateFormAndUpdate
                goBack={closeEditAffiliate}
                affiliate={affiliate}
              />
            </Dialog>
            <Dialog
              header="Información"
              modal={true}
              onHide={() => navigate(-2)}
              visible={!!contractToPrint}
            >
              <TabView activeIndex={0}>
                <TabPanel header="Documento">
                  <DocumentToPrint document={contractToPrint} />
                </TabPanel>
                {payment && (
                  <TabPanel header="Recibo">
                    <Voucher
                      title="Recibo de pago de cuotas"
                      payment={payment}
                    />
                  </TabPanel>
                )}
              </TabView>
            </Dialog>
            <div className="grid">
              <AffiliateHeader affiliate={affiliate} />
            </div>

            <div className="spacer20" />
            <FormProvider {...formMethods}>
              <div className="grid">
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Cédula</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="text"
                    value={affiliate.idCard || ""}
                    readOnly
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Nombre de afiliado</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="text"
                    value={
                      (affiliate.firstName ?? "") +
                      " " +
                      (affiliate.lastName ?? "")
                    }
                    readOnly
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Monto por pagar</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="number"
                    step="1"
                    min="0"
                    {...register("amountToPay")}
                  />
                  <Button
                    type="button"
                    label="Calcular"
                    onClick={changeAmount}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Agregar monto extraordinario</label>
                </div>
                <div className="col-12 md:col-4">
                  <Checkbox
                    onChange={(e) => setValue("showExtraAmount", e.checked)}
                    checked={showExtraAmount}
                  />
                </div>
                {showExtraAmount && <WayToPay />}
              </div>
            </FormProvider>
            {receiptTem.amount && (
              <>
                <div className="spacer20" />
                <div className="grid">
                  <h2>Información de Pagaré</h2>
                  <div className="col-12">
                    <label htmlFor="input">Fecha:</label>{" "}
                    {formatDate(new Date())}
                  </div>
                  <div className="col-12 md:col-2">
                    <label htmlFor="input">RECIBIMOS DE</label>
                  </div>
                  <div className="col-12 md:col-2">
                    {receiptTem.affiliate.lastName}{" "}
                    {receiptTem.affiliate.firstName}
                  </div>
                  <div className="col-12 md:col-2">
                    <label htmlFor="input">ASOCIADO #</label>{" "}
                    {receiptTem.affiliate.number}
                  </div>
                  <div className="col-12 md:col-2">
                    <label htmlFor="input">MONTO ADEUDADO</label>{" "}
                    {formatCurrencyValue(receiptTem.amount)}
                  </div>
                  <div className="col-12 md:col-2">
                    <label htmlFor="input">CEDULA ASOCIADO </label>{" "}
                    {receiptTem.affiliate.idCard}
                  </div>
                  {receiptTem.details.length > 0 && (
                    <DataTable
                      className="col-12"
                      paginator={true}
                      rows={10}
                      emptyMessage="No se encontraron registros"
                      value={receiptTem.details}
                    >
                      <Column
                        body={({ payDate }) => formatDate(payDate)}
                        header="Fecha de pago"
                      />
                      <Column
                        body={({ amount }) => formatCurrencyValue(amount)}
                        header="Monto"
                      />
                    </DataTable>
                  )}
                </div>
              </>
            )}
            <div className="md:col-12">
              <Button
                label="Generar"
                type="button"
                onClick={() => {
                  handleSubmit(createArrangement)();
                }}
              />
              <Button type="button" label="Regresar" onClick={goToBack} />
              <Button
                type="button"
                label="Editar Afiliado"
                onClick={(e) => setDesireToEditAffiliate(true)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiptPaymentArrangement;
