/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";
import Voucher from "../../components/Vouchers/PaymentVoucher";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { AffiliateForm } from "../../components/AffiliateForm";
import useService from "../../hooks/hook-service";
import { showWarningDialog } from "../../utils/message";
import { useNavigate } from "react-router-dom";
import { classNames } from "primereact/utils";
import { convertStringToDate } from "../../utils/format";
import ContractToPrint from "../../components/Vouchers/ContractToPrint";
import ContractTypeAndBeneficiaries from "../../components/ContractTypeAndBeneficiaries";

const ContractCreate = () => {
  const [contract, setContract] = useState();
  const [payment, setPayment] = useState();
  const [paymentTypes, setPaymentTypes] = useState([]);

  const navigate = useNavigate();
  const { post } = useService();
  const formMethods = useForm();
  const {
    control,
    register,
    watch,
    formState: { isDirty, errors },
  } = formMethods;
  const contractType = watch("contractType");

  useEffect(() => {
    const request = { catalogType: "1004" };

    post("catalog/list", request, ({catalogs}) => {
      setPaymentTypes(catalogs);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const askToGoBack = () => {
    if (isDirty === true) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios sin guardar dentro de la página, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            navigate(-1);
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  const saveContract = (formData) => {
    let data = {
      contract: {
        type: {
          id: formData.contractType,
        },
        lengthRegisterQuota: formData.lengthRegisterQuota,
        affiliate: {
          idCard: formData.idCard,
          firstName: formData.firstName,
          lastName: formData.lastName,
          genre: {
            id: formData.idGender,
          },
          maritalStatus: {
            id: formData.idMaritalStatus,
          },
          division: {
            id: formData.idProvince,
            subDivision: {
              id: formData.idCounty,
              subDivision: {
                id: formData.idDistrict,
              },
            },
          },
          birthDate: convertStringToDate(formData.birthDate),
          telephone: formData.telephone?.replace("-", ""),
          celphone: formData.celphone?.replace("-", ""),
          address: formData.address,
          email: formData.email,
        },
      },
      transferType: {
        id: formData.transferType,
      },
    };
    if (formData.beneficiaries.length > 0) {
      data.contract.affiliate.beneficiaries = formData.beneficiaries;
    }

    post("contract/create", data, ({ contract, paymentReceived }) => {
      setContract(contract);
      setPayment(paymentReceived);
    });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-create">
              <h1>Creación De Nuevo Contrato</h1>
            </div>
            <div className="spacer20" />
            <Dialog
              header="Información"
              visible={!!contract}
              modal={true}
              onHide={() => navigate(-1)}
            >
              <TabView>
                <TabPanel header="Contrato">
                  <ContractToPrint
                    contract={contract}
                    contractType={contractType}
                  />
                </TabPanel>
                <TabPanel header="Recibo">
                  <Voucher title="Recibo de pago de cuota" payment={payment} />
                </TabPanel>
              </TabView>
            </Dialog>
            <FormProvider {...formMethods}>
              <form className="grid">
                <AffiliateForm isFromCreate={true} />

                <ContractTypeAndBeneficiaries onSave={saveContract} onBack={askToGoBack}>
                    <div className="col-12 md:col-2">
                      <label htmlFor="input">Cantidad Pagos Cuota Afiliación</label>
                    </div>
                    <div className="col-12 md:col-4">
                      <InputText
                        type="number"
                        min={1}
                        className={classNames({
                          "p-invalid": !!errors.lengthRegisterQuota,
                        })}
                        {...register("lengthRegisterQuota", {
                          required: true,
                        })}
                      />
                    </div>
                    <div className="col-12 md:col-2">
                      <label htmlFor="input">Tipo de pago</label>
                    </div>
                    <div className="col-12 md:col-4">
                      <Controller
                        control={control}
                        name="transferType"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Dropdown
                            id="transferType"
                            optionLabel="name"
                            optionValue="id"
                            className={classNames({
                              "p-invalid": !!errors.transferType,
                            })}
                            placeholder="Tipo de pago"
                            {...field}
                            options={paymentTypes}
                          />
                        )}
                      />
                    </div>
                </ContractTypeAndBeneficiaries>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractCreate;
