/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import pdf from "../../utils/pdf";
import GenericCalendar from "../../components/GenericComponents/GenericCalendar";
import { Paginator } from "primereact/paginator";
import {
  convertStringToDate,
  formatCurrencyValue,
  formatDate,
} from "../../utils/format";
import {
  showSuccess,
  showWarning,
  showWarningDialog,
} from "../../utils/message";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import createExcel from "../../utils/createExcel";
import ActionToolbar from "../../components/ActionsToolbar";
import useService from "../../hooks/hook-service";

const AffiliateList = () => {
  const ACTION_IDS = {
    DISAFFILIATE: 2,
    INACTIVE: 3,
  };

  const [states, setStates] = useState([]);
  const [types, setTypes] = useState([]);
  const [affiliate, setAffiliate] = useState({});
  const [affiliates, setAffiliates] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState(0);
  const [sizePage, setSizePage] = useState(10);
  const [provinces, setProvinces] = useState([]);
  const [counties, setCounties] = useState([]);
  const [districts, setDistricts] = useState([]);

  const navigate = useNavigate();
  const { post, manyPost } = useService();
  const location = useLocation();
  const actions = location.state.actions;

  const defaultValues = {
    sizePage: sizePage,
    withSavingAmount: -1,
  };
  const { register, control, getValues, watch } = useForm({
    defaultValues: defaultValues,
  });

  const idProvince = watch("idProvince");
  const idCounty = watch("idCounty");

  useEffect(() => {
    const request = [
      {
        url: "catalog/list",
        body: {
          catalogType: 1000,
        },
      },
      {
        url: "catalog/list",
        body: {
          catalogType: 1013,
        },
      },
      {
        url: "division/list",
        body: {},
      },
    ];
    manyPost(request, (result) => {
      setTypes(result[0].catalogs);
      setStates(result[1].catalogs);
      setProvinces(result[2].divisions);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const data = {
      parentId: idProvince,
    };
    post("division/list", data, ({ divisions }) => {
      if (divisions.length === 0) showWarning("No se encontraron cantones");

      setCounties(divisions);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProvince]);

  useEffect(() => {
    const data = {
      parentId: idCounty,
    };
    post("division/list", data, ({ divisions }) => {
      if (divisions.length === 0) showWarning("No se encontraron districtos");

      setDistricts(divisions);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCounty]);

  useEffect(() => {
    resfreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const search = () => {
    if (page === 0) {
      resfreshData();
    } else {
      const size = getValues("sizePage");
      setSizePage(size);
      onPageChange({ first: 0, page: 0 });
    }
  };

  const doRequest = (request, callback) => {
    post("affiliate/search", request, ({ rows, affiliates }) => {
      if (rows === 0) {
        showWarning("No se encontraron registros");
      }

      callback(rows, affiliates);
    });
  };

  const resfreshData = () => {
    const startCreationDate = getValues("startCreationDate");
    const endCreationDate = getValues("endCreationDate");
    const startLastFeeDate = getValues("startLastFeeDate");
    const endLastFeeDate = getValues("endLastFeeDate");
    let division = null;
    if (getValues("idDistrict") ?? idCounty ?? idProvince)
      division = { id: getValues("idDistrict") ?? idCounty ?? idProvince };

    const request = {
      idCard: getValues("idCard"),
      firstName: getValues("firstName"),
      lastName: getValues("lastName"),
      startNumber: getValues("startNumber"),
      endNumber: getValues("endNumber"),
      startCreationDate: convertStringToDate(startCreationDate),
      endCreationDate: convertStringToDate(endCreationDate),
      startLastFeeDate: convertStringToDate(startLastFeeDate),
      endLastFeeDate: convertStringToDate(endLastFeeDate),
      sizePage: getValues("sizePage"),
      page: page,
      division: division
    };

    const withSavingAmount = getValues("withSavingAmount");
    if (withSavingAmount && withSavingAmount !== -1) {
      request.WithSavingAmount = withSavingAmount;
    }

    const status = getValues("status");
    if (status && status !== "") {
      request.status = {
        id: status,
      };
    }

    const type = getValues("type");
    if (type && type !== "") {
      request.type = {
        id: type,
      };
    }

    doRequest(request, (rows, affiliates) => {
      setTotalRecords(rows);
      setAffiliates(affiliates);
      setAffiliate({});
    });
  };

  const exportToExcel = () => {
    const startCreationDate = getValues("startCreationDate");
    const endCreationDate = getValues("endCreationDate");
    const startLastFeeDate = getValues("startLastFeeDate");
    const endLastFeeDate = getValues("endLastFeeDate");
    let division = null;
    if (getValues("idDistrict") ?? idCounty ?? idProvince)
      division = { id: getValues("idDistrict") ?? idCounty ?? idProvince };

    const request = {
      idCard: getValues("idCard"),
      firstName: getValues("firstName"),
      lastName: getValues("lastName"),
      startNumber: getValues("startNumber"),
      endNumber: getValues("endNumber"),
      startCreationDate: convertStringToDate(startCreationDate),
      endCreationDate: convertStringToDate(endCreationDate),
      startLastFeeDate: convertStringToDate(startLastFeeDate),
      endLastFeeDate: convertStringToDate(endLastFeeDate),
      page: 0,
      division: division
    };

    const withSavingAmount = getValues("withSavingAmount");
    if (withSavingAmount && withSavingAmount !== -1) {
      request.WithSavingAmount = withSavingAmount;
    }

    const status = getValues("status");
    if (status && status !== "") {
      request.status = {
        id: status,
      };
    }

    const type = getValues("type");
    if (type && type !== "") {
      request.type = {
        id: type,
      };
    }

    doRequest(request, (rows, affiliates) => {
      if (rows === 0) return;

      let result = {
        columns: [
          { title: "Número de Asociado", width: { width: 15 } },
          { title: "Cédula", width: { width: 15 } },
          { title: "Nombre", width: { width: 35 } },
          { title: "Estado", width: { width: 15 } },
          { title: "Correo electrónico", width: { width: 30 } },
          { title: "Celular", width: { width: 15 } },
          { title: "Teléfono", width: { width: 15 } },
          { title: "Fecha de nacimiento", width: { width: 20 } },
          { title: "Fecha de Afiliación", width: { width: 20 } },
          { title: "Fecha de última cuota", width: { width: 20 } },
          { title: "Dirección", width: { width: 90 } },
          { title: "Ahorro", width: { width: 20 } },
          { title: "Deuda", width: { width: 20 } },
          { title: "Fecha de fallecimiento", width: { width: 20 } },
          { title: "Saldos en Arreglos Pago", width: { width: 20 } },
        ],
        data: [],
      };

      affiliates.forEach((element) => {
        result.data.push([
          element.number ?? "",
          element.idCard ?? "",
          element.firstName + " " + element.lastName ?? "",
          element.status.name ?? "",
          element.email ?? "",
          element.celphone ?? "",
          element.telephone ?? "",
          formatDate(element.birthDate),
          formatDate(element.creationDate),
          formatDate(element.lastFeeDate),
          element.address ?? "",
          formatCurrencyValue(element.savingAmount),
          formatCurrencyValue(element.amountOwed),
          formatDate(element.diedDate),
          formatCurrencyValue(element.amountArrangement),
        ]);
      });

      createExcel("Afiliados", [result]);
    });
  };

  const exportToPdf = () => {
    const startCreationDate = getValues("startCreationDate");
    const endCreationDate = getValues("endCreationDate");
    const startLastFeeDate = getValues("startLastFeeDate");
    const endLastFeeDate = getValues("endLastFeeDate");
    let division = null;
    if (getValues("idDistrict") ?? idCounty ?? idProvince)
      division = { id: getValues("idDistrict") ?? idCounty ?? idProvince };

    const request = {
      idCard: getValues("idCard"),
      firstName: getValues("firstName"),
      lastName: getValues("lastName"),
      startNumber: getValues("startNumber"),
      endNumber: getValues("endNumber"),
      startCreationDate: convertStringToDate(startCreationDate),
      endCreationDate: convertStringToDate(endCreationDate),
      startLastFeeDate: convertStringToDate(startLastFeeDate),
      endLastFeeDate: convertStringToDate(endLastFeeDate),
      page: 0,
      division: division
    };

    const withSavingAmount = getValues("withSavingAmount");
    if (withSavingAmount && withSavingAmount !== -1) {
      request.WithSavingAmount = withSavingAmount;
    }

    const status = getValues("status");
    if (status && status !== "") {
      request.status = {
        id: status,
      };
    }

    const type = getValues("type");
    if (type && type !== "") {
      request.type = {
        id: type,
      };
    }

    doRequest(request, (rows, affiliates) => {
      if (rows === 0) return;

      const body = [];
      affiliates.forEach((element) => {
        body.push([
          element.idCard,
          element.firstName + " " + element.lastName,
          element.status.name,
          formatDate(element.lastFeeDate),
          element.address,
          element.savingAmount,
          formatDate(element.diedDate),
        ]);
      });

      const data = {
        head: [
          [
            "Cedula",
            "Nombre",
            "Estado",
            "Fecha de última cuota",
            "Dirección",
            "Ahorro",
            "Fecha de fallecimiento",
          ],
        ],
        body: body,
        startY: 25,
        bodyStyles: { valign: "top" },
        styles: { overflow: "linebreak", cellWidth: "wrap" },
        columnStyles: {
          0: { cellWidth: 25 },
          3: { cellWidth: 15 },
          4: { cellWidth: 40 },
        },
      };

      pdf.createPDF("l", data, "Afiliados.pdf", "Reporte de Afiliados");
    });
  };

  const select = (e) => {
    if (e.value) {
      setAffiliate(e.value);
    } else {
      setAffiliate({});
    }
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page);
  };

  const disableAction = (actionId) => {
    if (actionId === ACTION_IDS.DISAFFILIATE && affiliate.status?.id !== 52) {
      return true;
    }

    if (actionId === ACTION_IDS.INACTIVE && affiliate.status?.id !== 52) {
      return true;
    }

    return Object.getOwnPropertyNames(affiliate).length === 0;
  };

  const redirect = (route) => {
    switch (route) {
      case "/contractDisaffiliate":
        dissmissAffiliate();
        break;
      case "/editAffiliate":
      case "/affiliateDetail":
        navigate(route, {
          state: { affiliate: affiliate },
        });
        break;
      case "/affiliateInactive":
        inactive();
        break;
      case "/exportExcel":
        exportToExcel();
        break;
      case "/exportPDF":
        exportToPdf();
        break;
      default:
        break;
    }
  };

  const inactive = () => {
    showWarningDialog(
      {
        title: "Inactivar asociado",
        text: "¿Desea inactivar el afiliado seleccionado?",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      },
      {
        confirm: () => {
          const request = {
            id: affiliate.id,
          };

          post("affiliate/inactive", request, () => {
            showSuccess("El afiliado ha sido inactivado correctamente");
            search();
          });
        },
      }
    );
  };

  const dissmissAffiliate = () => {
    showWarningDialog(
      {
        title: "Finiquitar contrato",
        text: "¿Desea finiquitar el contrato del afiliado seleccionado?",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      },
      {
        confirm: () => {
          const request = {
            id: affiliate.id,
          };
          post("affiliate/disaffiliate", request, () => {
            showSuccess("El contrato del afiliado ha sido finiquitado");
            search();
          });
        },
      }
    );
  };

  const withSavingAmount = [
    { label: "Si", value: true },
    { label: "No", value: false },
    { label: "Todos", value: -1 },
  ];
  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Listado de Asociados</h1>
            </div>
            <ActionToolbar
              actions={actions}
              checkIfHaveToDisable={disableAction}
              executeAction={redirect}
            />
            <label id="searchLabel" htmlFor="input">
              Buscar por{" "}
            </label>
            <div className="spacer20" />
            <div className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">Número de Cédula</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("idCard")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Nombre</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("firstName")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Apellidos</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("lastName")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Número de contrato inicial</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="number" {...register("startNumber")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Número de contrato final</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="number" {...register("endNumber")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Fecha de creación inicial</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="startCreationDate"
                  render={({ field }) => (
                    <GenericCalendar id="startCreationDate" {...field} />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Fecha de creación final</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="endCreationDate"
                  render={({ field }) => (
                    <GenericCalendar id="endCreationDate" {...field} />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Fecha inicial de última cuota</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="startLastFeeDate"
                  render={({ field }) => (
                    <GenericCalendar id="startLastFeeDate" {...field} />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Fecha final de última cuota</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="endLastFeeDate"
                  render={({ field }) => (
                    <GenericCalendar id="endLastFeeDate" {...field} />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Provincia</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="idProvince"
                  render={({ field }) => (
                    <Dropdown
                      id="idProvince"
                      {...field}
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Provincia"
                      options={provinces}
                    />
                  )}
                />
              </div>
              {idProvince && (
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Cantón</label>
                </div>
              )}
              {idProvince && (
                <div className="col-12 md:col-4">
                  <Controller
                    control={control}
                    name="idCounty"
                    render={({ field }) => (
                      <Dropdown
                        id="idCounty"
                        {...field}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Cantón"
                        options={counties}
                      />
                    )}
                  />
                </div>
              )}
              {idCounty && (
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Distrito</label>
                </div>
              )}
              {idCounty && (
                <div className="col-12 md:col-4">
                  <Controller
                    control={control}
                    name="idDistrict"
                    render={({ field }) => (
                      <Dropdown
                        id="idDistrict"
                        {...field}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Distrito"
                        options={districts}
                      />
                    )}
                  />
                </div>
              )}
              <div className="col-12 md:col-2">
                <label htmlFor="input">Estado</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => (
                    <Dropdown
                      id="status"
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Todos"
                      {...field}
                      options={states}
                    />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Tipo de contrato</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="type"
                  render={({ field }) => (
                    <Dropdown
                      id="type"
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Todos"
                      {...field}
                      options={types}
                    />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Asociados con Ahorro</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="withSavingAmount"
                  render={({ field }) => (
                    <Dropdown
                      id="withSavingAmount"
                      {...field}
                      options={withSavingAmount}
                    />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Cantidad de registros a mostrar</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="number" min={1} {...register("sizePage", {
                  valueAsNumber: true,
                })} />
              </div>
              <div className="md:col-12">
                <Button label="Buscar" onClick={search} />
              </div>
            </div>
            <DataTable
              value={affiliates}
              selection={affiliate}
              onSelectionChange={select}
              emptyMessage="No se encontrarón registros"
            >
              <Column selectionMode="single" style={{ width: "4em" }} />
              <Column field="number" header="Número de asociado" />
              <Column field="idCard" header="Número de cédula" />
              <Column field="firstName" header="Nombre" />
              <Column field="lastName" header="Apellidos" />
              <Column
                body={(row) => formatDate(row.payDate)}
                header="Fecha del siguiente pago"
              />
              <Column
                body={(row) => formatDate(row.diedDate)}
                header="Fecha de fallecimiento"
              />
              <Column field="lateQuotas" header="Cuotas pendientes" />
              <Column
                body={(row) => formatCurrencyValue(row.savingAmount)}
                field="savingAmount"
                header="Ahorro"
              />
            </DataTable>
            <Paginator
              first={first}
              totalRecords={totalRecords}
              rows={sizePage}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffiliateList;
