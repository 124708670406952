import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import PermissionEditorTree from "../../components/PermissionTree/PermissionEditorTree";
import { useLocation, useNavigate } from "react-router-dom";
import { showSuccess, showWarningDialog } from "../../utils/message";
import useService from "../../hooks/hook-service";

const UserAssignRole = () => {
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState({});

  const [allPermissions, setAllPermissions] = useState([]);
  const [initialPermissions, setInitialPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const navigate = useNavigate();
  const { post, manyPost } = useService();
  const location = useLocation();
  const user = location.state.user;

  useEffect(() => {
    const request = [
      { url: "role/search", body: {} },
      { url: "user/get", body: user },
    ];
    manyPost(request, (result) => {
      if (!result) return;

      setRoles(result[0].roles);
      user.role = result[1].user.role;
      onChangeRole(result[1].user.role?.id);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChangeRole(user.role?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  const onChangeRole = (roleId) => {
    if (!roleId || roles.length === 0) return;

    const request = {
      id: roleId,
    };
    post("role/get", request, (data) => {
      setInitialPermissions(user.role?.products ?? []);
      setAllPermissions(data.role.products);
      const role = roles.find((x) => x.id === roleId);
      setRole(role);
    });
  };

  const goBackToHome = () => {
    if (user.role?.id !== role?.id) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios sin guardar dentro de la página, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            navigate(-1);
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  const save = () => {
    const data = {
      roleId: role.id,
      userId: user.id,
      products: selectedPermissions,
    };

    post("user/assignpermissions", data, () => {
      showSuccess("El rol ha sido asignado al usuario con éxito");
      navigate(-1);
    });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Asignar rol</h1>
            </div>
            <div className="spacer20" />

            <div className="grid">
              <div className="col-12 md:col-6">
                <Dropdown
                  value={role?.id}
                  options={roles}
                  optionLabel="name"
                  optionValue="id"
                  onChange={(e) => {
                    onChangeRole(e.value);
                  }}
                  placeholder="Roles"
                />
              </div>
              <PermissionEditorTree
                allPermissions={allPermissions}
                initialPermissions={initialPermissions}
                onChange={(value) => setSelectedPermissions(value)}
              />

              <div className="md:col-12">
                <Button label="Guardar" onClick={save} />
                <Button type="button" label="Regresar" onClick={goBackToHome} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAssignRole;
