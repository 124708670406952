/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React from "react";
import { useNavigate } from "react-router-dom";
import FeeForm from "./FeeForm";
import { showSuccess } from "../../utils/message";
import useService from "../../hooks/hook-service";

const FeeCreate = () => {
  const { post } = useService();
  const navigate = useNavigate();

  const createFee = (formData) => {
    post("fee/create", { fee: formData }, () => {
      showSuccess("La couta se creó correctamente");
      navigate(-1);
    });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <FeeForm
              headerClass="custom-card-header-create"
              title="Crear Cuota"
              titleButton="Guardar"
              actionToExecute={createFee}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeCreate;
