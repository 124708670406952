import React, { forwardRef } from "react";
import { InputMask } from "primereact/inputmask";

const GenericCalendar = forwardRef((props, ref) => {
  return (
    <InputMask mask="99/99/9999" slotChar="dd/mm/yyyy" {...props} ref={ref} />
  );
});

export default GenericCalendar;
