import jsPDF from 'jspdf';
import 'jspdf-autotable';

const createPDF = (config, data, name, title) => {
    const doc = new jsPDF(config);
    doc.text(title, 15, 22);
    doc.autoTable(data);
    doc.save(name);
    return true;

};

const pdf = {
    createPDF: createPDF
}

export default pdf;