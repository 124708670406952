import React from "react";
import AssetForm from "./AssetForm";
import { useNavigate } from "react-router-dom";
import { showSuccess } from "../../utils/message";
import useService from "../../hooks/hook-service";

const AssetCreate = () => {
  const defaultAsset = {};

  const navigate = useNavigate();
  const { post } = useService();

  const createAsset = (asset) => {
    const data = {
      asset,
    };

    post("asset/create", data, () => {
      showSuccess("El activo se ha creado correctamente");
      navigate(-1);
    });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <AssetForm
              headerClass="custom-card-header-create"
              title="Crear Activo"
              titleButton="Guardar"
              isCreatedForm={true}
              actionToExecute={createAsset}
              asset={defaultAsset}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetCreate;
