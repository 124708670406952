import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { showSuccess } from "../../utils/message";
import useService from "../../hooks/hook-service";
import ComponentForm from "./ComponentForm";

const ComponentEdit = () => {
    const navigate = useNavigate();
    const { post } = useService();
    const location = useLocation();

    const editComponent = (formData) => {
      const data = {
        component: {
          name: formData.name,
          number: formData.number,
          price:formData.price,
          id: location.state.component.id,
        },
      };
  
      post("component/update", data, () => {
        showSuccess("El componente se ha actualizado correctamente");
        navigate(-1);
      });
    };
  
    return (
      <div className="p-fluid">
        <div className="grid">
          <div className="col-12">
            <div className="custom-card">
              <ComponentForm
                headerClass="custom-card-header-edit"
                title="Editar Componente"
                titleButton="Editar"
                actionToExecute={editComponent}
                component={location.state.component}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default ComponentEdit;