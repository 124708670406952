import React, { useEffect, useState } from "react";
import ActionToolbar from "../../components/ActionsToolbar";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import { showWarning } from "../../utils/message";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const NotificationList = () => {
  const [notifications, setNotifications] = useState([]);
  const [notification, setNotification] = useState({});

  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();
  const actions = location.state.actions;

  useEffect(() => {
    post("notification/search", {}, ({ notifications }) => {
      if (notifications.length === 0) {
        showWarning("No se encontraron registros");
      }
      setNotifications(notifications);
      setNotification({});
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disableAction = () => {
    return Object.getOwnPropertyNames(notification).length === 0;
  };

  const select = (e) => {
    if (e.value) {
      setNotification(e.value);
    } else {
      setNotification({});
    }
  };

  const redirect = (route) => {
    switch (route) {
      case "/notificationSend":
        navigate(route, {
          state: { notification },
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Listado de Notificaciones</h1>
            </div>
            <div className="spacer20" />
            <ActionToolbar
              actions={actions}
              checkIfHaveToDisable={disableAction}
              executeAction={redirect}
            />

            <DataTable
              paginator={true}
              rows={10}
              value={notifications}
              selection={notification}
              emptyMessage="No se encontraron registros"
              onSelectionChange={select}
            >
              <Column selectionMode="single" style={{ width: "4em" }} />
              <Column field="name" header="Nombre" />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationList;
