/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import GenericInputPhone from "../../components/GenericComponents/GenericInputPhone";
import { TabView, TabPanel } from "primereact/tabview";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import DocumentToPrint from "../../components/Vouchers/DocumentToPrint";
import { showError, showSuccess, showWarningDialog } from "../../utils/message";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import GenericInputId from "../../components/GenericComponents/GenericInputId";
import WayToPay from "../../components/WayToPayForm";
import { formatCurrencyValue } from "../../utils/format";
import BillToPrint from "../../components/Vouchers/BillToPrint";
import { useSelector } from "react-redux";
import ServiceEdit from "../../components/Services/ServiceEdit";

const BillCreate = () => {
  const auth = useSelector((state) => state.authManager.value);
  const [contractToPrint, setContractToPrint] = useState();
  const [payment, setPayment] = useState();
  const [services, setServices] = useState([]);
  const [sellConditions, setSellConditions] = useState([]);
  const [daysToPay, setDaysToPay] = useState([]);
  const [servicesAdded, setServicesAdded] = useState([]);
  const [service, setService] = useState();
  const [subtotal, setSubtotal] = useState(0);

  const navigate = useNavigate();
  const { post, manyPost } = useService();

  useEffect(() => {
    const request = [
      {
        url: "catalog/list",
        body: {
          catalogType: 1009,
        },
      },
      {
        url: "service/search",
        body: {
          code: null,
          name: null,
          startPrice: null,
          endPrice: null,
          state: null,
          isAffiliate: false,
        },
      },
    ];
    manyPost(request, (result) => {
      setSellConditions(result[0].catalogs);
      const days = [];
      days.push({ id: "30", name: "30" });
      days.push({ id: "60", name: "60" });
      days.push({ id: "90", name: "90" });
      setDaysToPay(days);
      setServices(result[1].services);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHideEditingService = () => {
    setService();
  };

  const formMethods = useForm();
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { isDirty, errors },
  } = formMethods;
  const idPaymentType = watch("idPaymentType");

  const addService = (service) => {
    const ser = {
      service: service,
      price: service.price,
      quantity: 1,
      tax: 0,
      total: service.price,
    };
    servicesAdded.push(ser);
    let subtotal = 0;
    servicesAdded.forEach((element) => {
      const price = parseInt(element.service.price);
      subtotal += price;
    });
    setSubtotal(subtotal);
    setServicesAdded([...servicesAdded]);
  };

  const deleteService = (index) => {
    servicesAdded.splice(index, 1);
    let subtotal = 0;
    servicesAdded.forEach((element) => {
      const price = parseInt(element.price);
      subtotal += price;
    });
    setSubtotal(subtotal);
    setServicesAdded([...servicesAdded]);
  };

  const askToGoBack = () => {
    if (isDirty === true || servicesAdded.length > 0) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios en el edición de un beneficiario, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            navigate(-1);
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  const askIfDesireAddService = (service) => {
    setService(service);
  };

  const onError = (errors) => {
    showError("Debe completar los campos");
  };

  const onSubmit = (formData) => {
    if (servicesAdded.length === 0) {
      showError("Debe seleccionar al menos un servicio");
      return;
    }

    if (formData.idPaymentType === 38 && !formData.paymentDays) {
      showError("Debe seleccionar la cantidad de días a crédito");
      return;
    }

    const data = {
      bill: {
        number: formData.number,
        client: {
          id: formData.idCard,
          name: formData.name,
          email: formData.email,
          address: formData.address,
          phone: formData.phone.replace("-", ""),
        },
        transferType: {
          id: formData.transferType,
        },
        paymentType: {
          id: formData.idPaymentType,
        },
        paymentDays: formData.paymentDays ?? 0,
        subtotal: subtotal,
        salesTax: formData.salexTax,
        otherTaxes: formData.otherTaxes,
        details: servicesAdded,
        observations: formData.observations,
        referenceNumber: formData.referenceNumber,
      },
    };

    post("bill/create", data, ({ document, bill }) => {
      showSuccess("Se creo correctamente la factura");
      if (idPaymentType === 38) {
        setContractToPrint(document.data);
        setPayment(bill);
      } else {
        navigate(-1);
      }
    });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-create">
              <h1>Crear factura</h1>
            </div>
            <div className="spacer20" />
            <Dialog
              header="Información"
              modal={true}
              onHide={() => navigate(-1)}
              visible={!!contractToPrint}
            >
              <TabView activeIndex={0}>
                <TabPanel header="Documento">
                  <DocumentToPrint document={contractToPrint} />
                </TabPanel>
                {payment && (
                  <TabPanel header="Recibo">
                    <BillToPrint user={auth.user} bill={payment} />
                  </TabPanel>
                )}
              </TabView>
            </Dialog>

            <ServiceEdit
              service={service}
              onClose={onHideEditingService}
              onUpdate={addService}
            />

            <form onSubmit={handleSubmit(onSubmit, onError)} className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">Número de factura</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="number"
                  {...register("number", {
                    required: true,
                  })}
                  className={classNames({
                    "p-invalid": !!errors.number,
                  })}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Cedula del Cliente</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="idCard"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <GenericInputId
                      id="idCard"
                      {...field}
                      className={classNames({
                        "p-invalid": !!errors.idCard,
                      })}
                    />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Nombre del Cliente</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="text"
                  {...register("name", {
                    required: true,
                  })}
                  className={classNames({
                    "p-invalid": !!errors.name,
                  })}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Correo del Cliente</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="email"
                  {...register("email", {
                    required: true,
                  })}
                  className={classNames({
                    "p-invalid": !!errors.email,
                  })}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Dirección del Cliente</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="text"
                  {...register("address", {
                    required: true,
                  })}
                  className={classNames({
                    "p-invalid": !!errors.address,
                  })}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Teléfono del Cliente</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="phone"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <GenericInputPhone
                      id="phone"
                      {...field}
                      className={classNames({
                        "p-invalid": !!errors.phone,
                      })}
                    />
                  )}
                />
              </div>
              <FormProvider {...formMethods}>
                <WayToPay requiredAmount={false} />
              </FormProvider>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Condición de Venta</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="idPaymentType"
                  render={({ field }) => (
                    <Dropdown
                      id="idPaymentType"
                      optionLabel="name"
                      optionValue="id"
                      placeholder="- Seleccione una condición -"
                      {...field}
                      options={sellConditions}
                      className={classNames({
                        "p-invalid": !!errors.idPaymentType,
                      })}
                    />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Observaciones</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("observations")} />
              </div>
              {idPaymentType === 38 && (
                <>
                  <div className="col-12 md:col-2">
                    <label htmlFor="input">Cantidad de días de crédito</label>
                  </div>
                  <div className="col-12 md:col-4">
                    <Controller
                      control={control}
                      name="paymentDays"
                      render={({ field }) => (
                        <Dropdown
                          id="paymentDays"
                          optionLabel="name"
                          optionValue="id"
                          placeholder="- Seleccione los días -"
                          {...field}
                          options={daysToPay}
                        />
                      )}
                    />
                  </div>
                </>
              )}
              <div className="col-12 md:col-2">
                <label htmlFor="input">Impuesto de ventas</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="number" {...register("salexTax")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Otros Impuestos</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="number" {...register("otherTaxes")} />
              </div>
              <div className="col-12" />
              <div className="col-6">Seleccione los servicios</div>
              <div className="col-6">
                SubTotal: {formatCurrencyValue(subtotal)}
              </div>
              <div className="col-12">
                <DataTable
                  paginator={true}
                  rows={10}
                  value={services}
                  emptyMessage="No se encontraron registros"
                >
                  <Column field="code" header="Codigo" />
                  <Column field="name" header="Nombre" />
                  <Column field="description" header="Descripción" />
                  <Column
                    body={(rowData) => formatCurrencyValue(rowData.price)}
                    header="Precio"
                  />
                  <Column
                    body={(rowData) => (
                      <Button
                        type="button"
                        icon="pi pi-plus"
                        className="button-icon"
                        onClick={() => askIfDesireAddService(rowData)}
                        tooltip="Seleccionar"
                      />
                    )}
                    header="Agregar Servicio"
                  />
                </DataTable>
              </div>
              {servicesAdded.length > 0 && (
                <div className="col-12">
                  Servicios agregados
                  <DataTable
                    paginator={true}
                    rows={10}
                    value={servicesAdded}
                    emptyMessage="No se encontraron registros"
                  >
                    <Column field="service.code" header="Codigo" />
                    <Column field="service.name" header="Nombre" />
                    <Column field="service.description" header="Descripción" />
                    <Column
                      body={(rowData) =>
                        formatCurrencyValue(rowData.service.price)
                      }
                      header="Precio"
                    />
                    <Column
                      body={(rowData, column) => (
                        <Button
                          type="button"
                          icon="pi pi-trash"
                          className="p-button-danger button-icon"
                          onClick={() => deleteService(column.rowIndex)}
                          tooltip="Eliminar"
                        />
                      )}
                      header="Eliminar Servicio"
                    />
                  </DataTable>
                </div>
              )}
              <div className="md:col-12">
                <Button label="Guardar" />
                <Button type="button" label="Regresar" onClick={askToGoBack} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillCreate;
