import { sha512 } from "crypto-hash";

export const setAuth = (auth) => {
  localStorage.setItem("auth-user", JSON.stringify(auth));
};

export const getAuth = () => {
  return JSON.parse(localStorage.getItem("auth-user"));
};

export const deleteAuth = () => {
  localStorage.removeItem("auth-user");
};

export const createHash = async (str) => {
  return await sha512(str);
};
