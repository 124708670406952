/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Allan Moya <amoya@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import GenericCalendar from "../../components/GenericComponents/GenericCalendar";
import { Dropdown } from "primereact/dropdown";
import { showWarning } from "../../utils/message";
import { convertStringToDate } from "../../utils/format";
import { DataTable } from "primereact/datatable";
import { Row } from "primereact/row";
import { Card } from "primereact/card";
import _ from "lodash";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import pdf from "../../utils/pdf";
import createExcel from "../../utils/createExcel";
import useService from "../../hooks/hook-service";

const SelectTableToShow = ({
  isSumarizedReport,
  isGroupedReport,
  totalSumToShowCard,
  groupByColumn,
  dataHeader,
  columnsDetailNames,
  report,
  reportResult,
  columnsHeaderNames,
}) => {
  const [expandedRows, setExpandedRows] = useState(false);

  const headerTemplate = (data) => {
    return data[groupByColumn];
  };

  const footerTemplate = (data, index) => {
    return [];
  };

  const rowExpansionTemplate = (data) => {
    let columns = [];

    for (let index = 0; index < columnsDetailNames.length; index++) {
      const element = columnsDetailNames[index];
      columns.push(<Column key={index} field={element} header={element} />);
    }

    let footerGroup = (
      <ColumnGroup>
        <Row>
          <Column footer="Total:" colSpan={6} />
          <Column footer={data.fees[0]["Monto total"]} />
          <Column footer={data.fees[0]["Cantidad de cuotas"]} />
        </Row>
      </ColumnGroup>
    );

    return (
      <div>
        <DataTable
          emptyMessage="No se encontraron registros"
          footerColumnGroup={footerGroup}
          value={data.fees}
        >
          {columns}
        </DataTable>
      </div>
    );
  };

  const getColumnsTable = () => {
    let columns = [];
    let index = 0;
    for (var property in reportResult[0]) {
      if (reportResult[0].hasOwnProperty(property)) {
        columns.push(<Column key={index} field={property} header={property} />);
        index++;
      }
    }

    return columns;
  };

  const getColumnsGroupedTableDynamically = (columnsArray) => {
    let columns = [];

    columns.push(<Column expander={true} style={{ width: "2em" }} />);

    for (let index = 0; index < columnsArray.length; index++) {
      const element = columnsArray[index];
      columns.push(<Column key={index} field={element} header={element} />);
    }

    return columns;
  };

  if (isSumarizedReport) {
    return (
      <>
        <DataTable
          emptyMessage="No se encontraron registros"
          resizableColumns={true}
          value={reportResult}
          paginator={true}
          header={report.title}
          rows={10}
        >
          {getColumnsTable()}
        </DataTable>
        <Card title="Total" className="ui-card-shadow">
          {totalSumToShowCard}
        </Card>
      </>
    );
  } else if (isGroupedReport && report.id !== 7) {
    return (
      <>
        <DataTable
          header={report.title}
          resizableColumns={true}
          value={reportResult}
          emptyMessage="No se encontraron registros"
          rowGroupMode="subheader"
          groupRowsBy={groupByColumn}
          rowGroupHeaderTemplate={headerTemplate}
          rowGroupFooterTemplate={footerTemplate}
          paginator={true}
          rows={10}
        >
          {getColumnsTable()}
        </DataTable>
      </>
    );
  } else if (isGroupedReport && report.id === 7) {
    return (
      <>
        <DataTable
          value={dataHeader}
          header={report.title}
          expandedRows={expandedRows}
          paginator={true}
          rows={20}
          emptyMessage="No se encontraron registros"
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          dataKey={groupByColumn}
        >
          {getColumnsGroupedTableDynamically(columnsHeaderNames)}
        </DataTable>
      </>
    );
  } else {
    return (
      <>
        <DataTable
          resizableColumns={true}
          value={reportResult}
          paginator={true}
          emptyMessage="No se encontraron registros"
          header={report.title}
          rows={10}
        >
          {getColumnsTable()}
        </DataTable>
      </>
    );
  }
};

const ReportDetail = () => {
  const [report, setReport] = useState({});
  const [catalogs, setCatalogs] = useState([]);
  const [reportResult, setReporResult] = useState([]);
  const location = useLocation();
  const { post, manyPost } = useService();
  const navigate = useNavigate();

  const { register, control, handleSubmit } = useForm();

  useEffect(() => {
    const data = {
      id: location.state.report.id,
    };
    post("report/get", data, ({ report }) => setReport(report));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const catalogParameters = report.parameters?.filter(
      (param) => param.type === "catalog"
    );
    if (catalogParameters?.length > 0) {
      const request = catalogParameters.map((param) => {
        return {
          url: "catalog/list",
          body: {
            catalogType: param.option,
          },
        };
      });

      manyPost(request, (result) => {
        let catalogs = [];
        result.forEach((response) => {
          catalogs = catalogs.concat(response.catalogs);
        });
        setCatalogs(catalogs);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  const parametersDesing = () => {
    let params = [];
    report.parameters?.forEach((param, index) => {
      if (param.type === "string") {
        params.push(
          <React.Fragment key={index}>
            <div className="col-12 md:col-2">
              <label htmlFor="input">{param.displayName}</label>
            </div>
            <div className="col-12 md:col-4">
              <InputText {...register(param.name)} type="text" />
            </div>
          </React.Fragment>
        );
      } else if (param.type === "int") {
        params.push(
          <React.Fragment key={index}>
            <div className="col-12 md:col-2">
              <label htmlFor="input">{param.displayName}</label>
            </div>
            <div className="col-12 md:col-4">
              <InputText {...register(param.name)} type="number" />
            </div>
          </React.Fragment>
        );
      } else if (param.type === "float") {
        params.push(
          <React.Fragment key={index}>
            <div className="col-12 md:col-2">
              <label htmlFor="input">{param.displayName}</label>
            </div>
            <div className="col-12 md:col-4">
              <InputText type="number" {...register(param.name)} />
            </div>
          </React.Fragment>
        );
      } else if (param.type === "double") {
        params.push(
          <React.Fragment key={index}>
            <div className="col-12 md:col-2">
              <label htmlFor="input">{param.displayName}</label>
            </div>
            <div className="col-12 md:col-4">
              <InputText type="number" {...register(param.name)} />
            </div>
          </React.Fragment>
        );
      } else if (param.type === "dateTime") {
        params.push(
          <React.Fragment key={index}>
            <div className="col-12 md:col-2">
              <label htmlFor="input">{param.displayName}</label>
            </div>
            <div className="col-12 md:col-4">
              <Controller
                control={control}
                name={param.name}
                render={({ field }) => (
                  <GenericCalendar id={param.name} {...field} />
                )}
              />
            </div>
          </React.Fragment>
        );
      } else if (param.type === "catalog") {
        const catalogTemp = catalogs.filter(
          (catalog) => catalog.catalogTypeId === parseInt(param.option)
        );
        params.push(
          <React.Fragment key={index}>
            <div className="col-12 md:col-2">
              <label htmlFor="input">{param.displayName}</label>
            </div>
            <div className="col-12 md:col-4">
              <Controller
                control={control}
                name={param.name}
                render={({ field }) => (
                  <Dropdown
                    id={param.name}
                    optionLabel="name"
                    optionValue="id"
                    {...field}
                    placeholder={param.displayName}
                    options={catalogTemp}
                  />
                )}
              />
            </div>
          </React.Fragment>
        );
      }
    });
    return <>{params}</>;
  };

  const execute = (formData) => {
    let parameters = Object.keys(formData).map((key) => {
      let value = (formData[key] === "" ? null : formData[key]) ?? null;

      if (
        report.parameters.some(
          (param) => param.name === key && param.type === "dateTime"
        )
      ) {
        value = convertStringToDate(value);
      }
      return {
        name: key,
        value: value,
      };
    });

    let request = {
      report: {
        id: report.id,
        parameters: parameters,
      },
    };

    post("report/execute", request, ({ data }) => {
      if (data.length === 0) {
        showWarning("No hay datos disponibles para mostrar");
      }
      setReporResult(data);
    });
  };

  const processData = (data) => {
    let dataHeader;
    let columnsHeaderNames = [];
    let isSumarizedReport = false;
    let columnsDetailNames = [];
    let groupByColumn = null;
    let sumariceRow = [];
    let existSumarizeColumns = false;
    let isGroupedReport = false;
    let headerColumns = [];
    let detailColumns = [];
    /*------------------Fill de columns----------------------------*/
    for (
      let indexColumns = 0;
      indexColumns < report.columns.length;
      indexColumns++
    ) {
      let column = report.columns[indexColumns];
      if (existSumarizeColumns === false && column.sumarizeColumn === true) {
        existSumarizeColumns = true;
        isSumarizedReport = true;
      }
      if (isGroupedReport === false && column.isGrouping) {
        isGroupedReport = true;
        groupByColumn = column.name;
        dataHeader = _.chain(data)
          .groupBy("Mes")
          .map((fees, Mes) => ({ fees, Mes }))
          .value();
      }
      if (column.isHeader === true) {
        headerColumns.push({ title: column.name, width: { wpx: 90 } });
        columnsHeaderNames.push(column.name);
      } else {
        detailColumns.push({ title: column.name, width: { wpx: 90 } });
        columnsDetailNames.push(column.name);
      }
    }

    /*----------------------------------------------------------- */

    /*--------------------------Sumarize------------------------- */
    let totalSumToShowCard = "";
    if (existSumarizeColumns === true) {
      for (
        let indexSumarizeRow = 0;
        indexSumarizeRow < report.columns.length;
        indexSumarizeRow++
      ) {
        let sumColumn = report.columns[indexSumarizeRow];
        let sum = 0;
        if (sumColumn.sumarizeColumn === true) {
          for (let indexSum = 0; indexSum < data.length; indexSum++) {
            if (data[indexSum][sumColumn.name] !== null) {
              sum += data[indexSum][sumColumn.name];
            }
          }
          totalSumToShowCard += sumColumn.name + ": " + sum + " | ";
          sumariceRow.push({ value: sum ? sum : "" });
        } else {
          sumariceRow.push({ value: "" });
        }
      }
    }

    /*----------------------------------------------------------- */
    return {
      isSumarizedReport,
      isGroupedReport,
      totalSumToShowCard,
      groupByColumn,
      dataHeader,
      columnsDetailNames,
      columnsHeaderNames,
      headerColumns,
      detailColumns,
      sumariceRow,
    };
  };

  const exportToExcel = () => {
    const {
      isSumarizedReport,
      isGroupedReport,
      groupByColumn,
      headerColumns,
      detailColumns,
      sumariceRow,
    } = processData(reportResult);

    const dataTemp = [];
    let sumQuantityQuotas = 0;
    let sumAmountQuotas = 0;
    let result = [];

    if (isGroupedReport === false) {
      for (let indexData = 0; indexData < reportResult.length; indexData++) {
        let tempArray = [];
        for (
          let indexDataColumn = 0;
          indexDataColumn < report.columns.length;
          indexDataColumn++
        ) {
          //insert by columns
          let tempColumn = report.columns[indexDataColumn].name;
          tempArray.push({
            value: reportResult[indexData][tempColumn]
              ? reportResult[indexData][tempColumn]
              : "",
          });
        }
        dataTemp.push(tempArray);
      }
    } else {
      /*---------------------------------Grouped Data------------------------------ */

      let grouped = _.chain(reportResult)
        .groupBy(groupByColumn)
        .map((grouped, groupByColumn) => ({ grouped, groupByColumn }))
        .value();

      for (
        let indexDataGrouped = 0;
        indexDataGrouped < grouped.length;
        indexDataGrouped++
      ) {
        //get object rows
        let tempGroupedData = grouped[indexDataGrouped];
        let hData = [];
        let dData = [];

        let objectHeader = tempGroupedData.grouped[0];
        let array1 = [];
        for (let indexH = 0; indexH < headerColumns.length; indexH++) {
          array1.push(
            objectHeader[headerColumns[indexH].title]
              ? objectHeader[headerColumns[indexH].title]
              : ""
          );
        }

        hData.push(array1);
        for (
          let indexD = 0;
          indexD < tempGroupedData.grouped.length;
          indexD++
        ) {
          let array2 = [];
          for (let indexD2 = 0; indexD2 < detailColumns.length; indexD2++) {
            array2.push(
              tempGroupedData.grouped[indexD][detailColumns[indexD2].title]
                ? tempGroupedData.grouped[indexD][detailColumns[indexD2].title]
                : ""
            );
          }
          if (report.id === 7) {
            sumAmountQuotas += array2[6];
            sumQuantityQuotas += array2[7];
          }
          dData.push(array2);
        }
        if (report.id === 7) {
          let tempTotalArray = [
            null,
            null,
            null,
            null,
            null,
            "Total",
            sumAmountQuotas,
            sumQuantityQuotas,
          ];

          dData.push(tempTotalArray);

          sumAmountQuotas = 0;
          sumQuantityQuotas = 0;
        }
        result.push(
          { columns: headerColumns, data: hData },
          { xSteps: 1, ySteps: 1, columns: detailColumns, data: dData }
        );
      }
    }

    if (isSumarizedReport === true) {
      dataTemp.push(sumariceRow);
    }

    /*----------------------------------------------------------- */
    if (isGroupedReport === false) {
      result.push({ columns: detailColumns, data: dataTemp });
    }

    createExcel(report.title, result);
  };

  const exportToPDF = () => {
    const body = [];
    const columns = report.columns;
    reportResult.forEach((element) => {
      const array = [];
      for (let y = 0; y < columns.length; y++) {
        array.push(element[columns[y].name]);
      }
      body.push(array);
    });

    const headers = [];
    for (let x = 0; x < columns.length; x++) {
      headers.push(columns[x].name);
    }

    const data = {
      head: [headers],
      body: body,
    };

    pdf.createPDF("l", data, report.title + ".pdf", report.title);
  };

  const selectTableToShow = () => {
    const {
      isSumarizedReport,
      isGroupedReport,
      totalSumToShowCard,
      groupByColumn,
      dataHeader,
      columnsDetailNames,
      columnsHeaderNames,
    } = processData(reportResult);

    return (
      <SelectTableToShow
        isSumarizedReport={isSumarizedReport}
        isGroupedReport={isGroupedReport}
        totalSumToShowCard={totalSumToShowCard}
        groupByColumn={groupByColumn}
        dataHeader={dataHeader}
        report={report}
        columnsDetailNames={columnsDetailNames}
        reportResult={reportResult}
        columnsHeaderNames={columnsHeaderNames}
      />
    );
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>{report.title}</h1>
            </div>
            <div className="spacer20" />

            <form onSubmit={handleSubmit(execute)} className="grid">
              {parametersDesing()}
              <div className="md:col-12">
                <Button label="Ejecutar Reporte" />
                <Button
                  type="button"
                  disabled={reportResult?.length === 0}
                  icon="pi pi-external-link"
                  iconPos="left"
                  label="Exportar a Excel"
                  onClick={exportToExcel}
                />
                <Button
                  type="button"
                  disabled={reportResult?.length === 0}
                  icon="pi pi-external-link"
                  iconPos="left"
                  label="Exportar a PDF"
                  onClick={exportToPDF}
                />
              </div>
            </form>
            {reportResult?.length > 0 && selectTableToShow()}
            <div className="spacer20" />
            <Button
              type="button"
              label="Regresar"
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportDetail;
