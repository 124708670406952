/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import ActionToolbar from "../../components/ActionsToolbar";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import { showSuccess, showWarning, showWarningDialog } from "../../utils/message";
import pdf from "../../utils/pdf";
import {
  formatCurrencyValue
} from "../../utils/format";
import createExcel from "../../utils/createExcel";
import { Controller, useForm } from "react-hook-form";

const ComponentList = () => {
  const [component, setComponent] = useState({});
  const [components, setComponents] = useState([]);

  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();
  const actions = location.state.actions;
  const { register, control, handleSubmit } = useForm();

  useEffect(() => {
      handleSubmit(search)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disableAction = (actionId) => {
    return Object.getOwnPropertyNames(component).length === 0;
  };

  const redirect = (route) => {
    switch (route) {
      case "/componentCreate":
        navigate(route);
        break;
      case "/componentEdit":
      case "/componentDetail":
        navigate(route, {
          state: { component: component },
        });
        break;
      case "/componentChangeStatus":
        changeStatus();
        break;
      case "/exportExcel":
        exportToExcel();
        break;
      case "/exportPDF":
        exportToPdf();
        break;
      default:
        break;
    }
  };

  const exportToExcel = () => {
    let result = {
      columns: [
        { title: "Nombre", width: { width: 40 } },
        { title: "Número", width: { width: 20 } },
        { title: "Precio", width: { width: 20 } },
        { title: "Estado", width: { width: 20 } },
      ],
      data: [],
    };

    components.forEach((element) => {
      let status = element.status === 1 ? "Activo" : "Inactivo";
      result.data.push([
        element.name ?? "",
        element.number ?? "",
        formatCurrencyValue(element.price) ?? "",
        status ?? ""
      ]);
    });
    createExcel("Componentes", [result]);
  };

  const exportToPdf = () => {
    const body = [];
    components.forEach((element) => {
      const status = element.status === 1 ? "Activo" : "Inactivo";
      const array = [element.name, element.number, element.price, status];
      body.push(array);
    });
    const data = {
      head: [
        [
          "Nombre", "Número", "Precio", "Estado",
        ],
      ],
      body: body,
      startY: 25,
      bodyStyles: { valign: "top" },
      tableWidth: "auto",
      styles: {
        cellWidth: "wrap",
        rowPageBreak: "auto",
        halign: "justify",
        overflow: "linebreak",
      },
      columnStyles: { text: { cellWidth: "auto" } },
    };
    pdf.createPDF("l", data, "Componentes.pdf", "Reporte de componentes");
  };

  const changeStatus = () => {
    showWarningDialog(
      {
        title: "Cambio de estado",
        text: component.status === 1? "¿Desea desactivar el componente?" : "¿Desea activar el componente?",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      },
      {
        confirm: () => {
          const request = {
            component: {
              id: component.id,
              status: component.status === 1 ? 0 : 1,
            },
          };

          post(component.status === 1 ? "component/disable" : "component/activate", request, () => {
            showSuccess(component.status === 1 ? "El componente se desactivó correctamente" : "El componente se activó correctamente");
            handleSubmit(search)();
          });
        },
      }
    );
  };

  const search = (formData) => {
    const request = {
      name: formData.name,
      number: formData.number,
      status: formData.statusId,
    };

    post("component/search", request, ({ components }) => {
      if (components.length === 0) {
        showWarning("No se encontraron registros");
      }
      setComponents(components);
      setComponent({});
    });
  };

  const select = (e) => {
    if (e.value) {
      setComponent(e.value);
    } else {
      setComponent({});
    }
  };

    return (
      <div className="p-fluid">
        <div className="grid">
          <div className="col-12">
            <div className="custom-card">
              <div className="custom-card-header-info">
                <h1>Listado de Componentes</h1>
              </div>
              <ActionToolbar
              actions={actions}
              checkIfHaveToDisable={disableAction}
              executeAction={redirect}
            />
              <div className="spacer10" />
              <label id="searchLabel" htmlFor="input">
                Buscar por
              </label>
              <div className="spacer20" />
              <div className="grid">
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Nombre</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="text"
                    {...register("name")}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Número</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="number"
                    {...register("number")}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Estado</label>
                </div>
                <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="statusId"
                  render={({ field }) => (
                    <Dropdown
                      id="statusId"
                      options={[
                        { label: "Activo", value: 1 },
                        { label: "Inactivo", value: 0 },
                      ]}
                      placeholder="Estado"
                      {...field}
                    />
                  )}
                />
                </div>
                <div className="md:col-12">
                  <Button label="Buscar" onClick={handleSubmit(search)} />
                </div>
                  <div  className="col-12">
                    <DataTable
                      paginator={true}
                      rows={10}
                      value={components}
                      emptyMessage="No se encontraron registros"
                      selection={component}
                      onSelectionChange={select}
                    >
                      <Column selectionMode="single" style={{ width: "4em" }} />
                      <Column field="name" header="Nombre" />
                      <Column field="number" header="Número" />
                      <Column  body={(row) => formatCurrencyValue(row.price)} header="Precio" />
                      <Column body={(rowData) => rowData.status === 1 ? "Activo" : "Inactivo"
} header="Estado" />
                    </DataTable>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  export default ComponentList;