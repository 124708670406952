/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { showError, showWarningDialog } from "../../utils/message";
import useService from "../../hooks/hook-service";
import { formatCurrencyValue } from "../../utils/format";
import classNames from "classnames";

const ServiceForm = ({
  headerClass,
  title,
  titleButton,
  actionToExecute,
  service }
) => {
  const STATES_ROW_INDEX = {
    CLOSE: -2,
    NEW: -1,
  };

  const [rowIndexItem, setRowIndexItem] = useState(STATES_ROW_INDEX.CLOSE);
  const [isShowingComponents, setIsShowingComponents] = useState(false);
  const [componentSupplies, setComponentSupplies] = useState([]);
  const [supplies, setSupplies] = useState([]);

  const navigate = useNavigate();
  const { post } = useService();

  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
    reset,
    watch,
    setValue
  } = useForm();
  const applyAffiliate = watch("applyAffiliate");

  const formAddOrEditComponent = useForm();

  useEffect(() => {
    post("component/search", {}, ({ components }) => {
      setComponentSupplies(components);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (service) {
      reset({
        code: service.code,
        name: service.name,
        description: service.description,
        price: service.price,
        tax: service.tax,
        applyAffiliate: service.applyAffiliate,
      });
      setSupplies(service.components?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service]);

  const goBackToHome = () => {
    if (isDirty === true) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios sin guardar dentro de la página, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            navigate(-1);
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  const onHideShowingComponents = () => {
    setIsShowingComponents(false);
  };

  const onHideAddOrEdit = () => {
    setRowIndexItem(STATES_ROW_INDEX.CLOSE);
  };

  const desireAddSupply = (data) => {
    setRowIndexItem(STATES_ROW_INDEX.NEW);
    formAddOrEditComponent.reset({
      id: data.id,
      name: data.name,
      price: data.price,
      number: data.number,
      quantity: 1
    });
  };

  const editSupply = (data, rowIndex) => {
    setRowIndexItem(rowIndex);
    formAddOrEditComponent.reset({
      id: data.component.id,
      name: data.component.name,
      price: data.component.price,
      number: data.component.number,
      quantity: data.quantity
    });
  };

  const deleteSupply = (rowIndex) => {
    supplies.splice(rowIndex, 1);
    setSupplies([...supplies]);
  };

  const saveSupply = (formData) => {
    const supply = {
      component: {
        id: formData.id,
        name: formData.name,
        number: formData.number,
        price: formData.price,
        status: 1,
      },
      quantity: formData.quantity,
    };


    if (rowIndexItem === STATES_ROW_INDEX.NEW) {
      supplies.push(supply);
      setIsShowingComponents(false);
    } else {
      supplies[rowIndexItem] = supply;
    }
    setSupplies([...supplies]);
    setRowIndexItem(STATES_ROW_INDEX.CLOSE);
  };

  const onSubmit = (formData) => {
    if (supplies.length === 0) {
      showError("La lista de componentes esta vacía");
      return;
    }

    const service = {
      code: formData.code,
      name: formData.name,
      description: formData.description,
      price: formData.price,
      tax: formData.tax,
      state: 1,
      components: supplies,
      applyAffiliate: formData.applyAffiliate,
    };
    actionToExecute(service);
  };

  const onError = (errors) => {
    showError("Debe completar los campos");
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className={headerClass}>
              <h1>{title}</h1>
            </div>
            <div className="spacer20" />
            <Dialog
              header="Listado de componentes disponibles"
              visible={isShowingComponents}
              modal={true}
              onHide={onHideShowingComponents}
            >
              <div>
                <DataTable
                  paginator={true}
                  rows={10}
                  value={componentSupplies}
                >
                  <Column field="name" header="Nombre" />
                  <Column field="number" header="Número" />
                  <Column body={(rowData) => formatCurrencyValue(rowData.price)} header="Precio" />
                  <Column
                    body={(rowData, column) => {
                      return <div>
                        <Button
                          type="button"
                          icon="pi pi-plus"
                          className="button-icon"
                          onClick={() => desireAddSupply(rowData)}
                        />
                      </div>
                    }}
                    header="Agregar"
                  />
                </DataTable>

                <div>
                  <Button label="Atrás" icon="pi pi-times" onClick={onHideShowingComponents} />
                </div>
              </div>
            </Dialog>
            <Dialog
              header="Componente"
              visible={rowIndexItem !== STATES_ROW_INDEX.CLOSE}
              modal={true}
              onHide={onHideAddOrEdit}
            >
              <div className="grid">
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Nombre</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="text"
                    {...formAddOrEditComponent.register("name")}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Número</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="number"
                    {...formAddOrEditComponent.register("number")}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Precio</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="number"
                    {...formAddOrEditComponent.register("price")}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Cantidad</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="number"
                    {...formAddOrEditComponent.register("quantity", {
                      required: true,
                    })}
                    className={classNames({
                      "p-invalid": !!formAddOrEditComponent.formState.errors.quantity,
                    })}
                  />
                </div>
                <div>
                  <Button
                    label="Guardar"
                    icon="pi pi-pencil"
                    onClick={formAddOrEditComponent.handleSubmit(saveSupply, onError)}
                  />
                  <Button label="Atrás" icon="pi pi-times" onClick={onHideAddOrEdit} />
                </div>
              </div>
            </Dialog>
            <form onSubmit={handleSubmit(onSubmit, onError)} className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">Código GTI</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="text"
                  {...register("code", {
                    required: true,
                  })}
                  className={classNames({
                    "p-invalid": !!errors.code,
                  })}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Nombre</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="text"
                  {...register("name", {
                    required: true,
                  })}
                  className={classNames({
                    "p-invalid": !!errors.name,
                  })}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Description</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="text"
                  {...register("description")}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Precio</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="number"
                  {...register("price", {
                    required: true,
                  })}
                  className={classNames({
                    "p-invalid": !!errors.price,
                  })}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Impuesto</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText
                  type="number"
                  {...register("tax")}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Servicio para Afilados</label>
              </div>
              <div className="col-12 md:col-4">
                <Checkbox
                  onChange={(e) => setValue("applyAffiliate", e.checked)}
                  checked={applyAffiliate}
                />
              </div>
              <div className="md:col-12">
                <Button label={titleButton} />
                <Button
                  type="button"
                  label="Regresar"
                  onClick={goBackToHome}
                />
                <Button
                  type="button"
                  label="Agregar Componente"
                  onClick={(e) => setIsShowingComponents(true)}
                />
              </div>
            </form>
            {supplies.length > 0 && (
              <div>
                <h2>Listado de componentes</h2>
                <DataTable
                  paginator={true}
                  rows={10}
                  value={supplies}
                >
                  <Column field="component.name" header="Nombre" />
                  <Column field="component.number" header="Número" />
                  <Column field="quantity" header="Cantidad" />
                  <Column body={(rowData) => formatCurrencyValue(rowData.component.price)} header="Precio" />
                  <Column body={(rowData, column) => {
                    return <div>
                      <Button
                        type="button"
                        icon="pi pi-pencil"
                        className="button-icon"
                        onClick={() => editSupply(rowData, column.rowIndex)}
                      />
                      <Button
                        type="button"
                        className="p-button-danger button-icon"
                        icon="pi pi-trash"
                        onClick={() => deleteSupply(column.rowIndex)}
                      />
                    </div>
                  }} header="Opciones" />
                </DataTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceForm;