import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import ActionToolbar from "../../components/ActionsToolbar";
import GenericCalendar from "../../components/GenericComponents/GenericCalendar";
import {
  showSuccess,
  showWarning,
  showWarningDialog,
} from "../../utils/message";
import {
  convertStringToDate,
  formatCurrencyValue,
  formatDate,
} from "../../utils/format";
import createExcel from "../../utils/createExcel";
import pdf from "../../utils/pdf";

const AssetList = () => {
  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();
  const actions = location.state.actions;
  const { register, control, handleSubmit } = useForm();

  const [asset, setAsset] = useState({});
  const [assets, setAssets] = useState([]);
  const [states, setStates] = useState([]);

  useEffect(() => {
    post("catalog/list", { catalogType: "1003" }, ({ catalogs }) => {
      setStates(catalogs);
      handleSubmit(search)();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const select = (e) => {
    if (e.value) {
      setAsset(e.value);
    } else {
      setAsset({});
    }
  };

  const disableAction = (actionId) => {
    return Object.getOwnPropertyNames(asset).length === 0;
  };

  const askToDesireToDelete = () => {
    showWarningDialog(
      {
        title: "Eliminar Activo",
        text: "¿Desea eliminar el activo?",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      },
      {
        confirm: () => {
          const request = {
            id: asset.id,
          };

          post("asset/delete", request, () => {
            showSuccess("El activo ha sido eliminado correctamente.");
            handleSubmit(search)();
          });
        },
      }
    );
  };

  const search = (formData) => {
    const request = {
      plateNumber: formData.plateNumber,
      description: formData.description,
      startDate: convertStringToDate(formData.startDate),
      endDate: convertStringToDate(formData.endDate),
    };

    if (formData.state && formData.state !== "") {
      request.state = {
        id: formData.state,
      };
    }

    post("asset/search", request, ({ assets }) => {
      if (assets.length === 0) {
        showWarning("No se encontraron registros");
      }
      setAssets(assets);
      setAsset({});
    });
  };

  const exportToExcel = () => {
    let result = {
      columns: [
        { title: "Número de placa", width: { width: 15 } },
        { title: "Número de factura", width: { width: 10 } },
        { title: "Descripción", width: { width: 40 } },
        { title: "Localización", width: { width: 20 } },
        { title: "Costo", width: { width: 15 } },
        { title: "Observaciones", width: { width: 40 } },
        { title: "Número de referencia", width: { width: 15 } },
        { title: "Precio de venta", width: { width: 15 } },
        { title: "Fecha de compra", width: { width: 15 } },
        { title: "Estado", width: { width: 15 } },
        { title: "Tipo", width: { width: 15 } },
      ],
      data: [],
    };

    assets.forEach((element) => {
      result.data.push([
        element.plateNumber ?? "",
        element.billNumber ?? "",
        element.description ?? "",
        element.localization ?? "",
        formatCurrencyValue(element.cost) ?? "",
        element.observation ?? "",
        element.referenceNumber ?? "",
        formatCurrencyValue(element.sellPrice) ?? "",
        formatDate(element.boughtTime),
        element.state.name ?? "",
        element.type.name ?? "",
      ]);
    });
    createExcel("Activos", [result]);
  };

  const exportToPdf = () => {
    const body = [];
    assets.forEach((element) => {
      const array = [
        element.plateNumber ?? "",
        element.description ?? "",
        element.localization ?? "",
        element.cost ?? "",
        formatDate(element.boughtTime),
        element.state.name ?? "",
        element.type.name ?? "",
      ];
      body.push(array);
    });
    const data = {
      head: [
        [
          "N. Placa",
          "Descripción",
          "Localización",
          "Costo",
          "F. Compra",
          "Estado",
          "Tipo",
        ],
      ],
      body: body,
      startY: 25,
      bodyStyles: { valign: "top" },
      tableWidth: "auto",
      styles: {
        cellWidth: "wrap",
        rowPageBreak: "auto",
        halign: "justify",
        overflow: "linebreak",
      },
      columnStyles: { text: { cellWidth: "auto" } },
    };
    pdf.createPDF("l", data, "Activos.pdf", "Reporte de activos");
  };

  const redirect = (route) => {
    switch (route) {
      case "/assetCreate":
        navigate(route);
        break;
      case "/assetEdit":
      case "/assetDetail":
        navigate(route, {
          state: { asset: asset },
        });
        break;
      case "/assetDelete":
        askToDesireToDelete();
        break;
      case "/exportExcel":
        exportToExcel();
        break;
      case "/exportPDF":
        exportToPdf();
        break;
      default:
        break;
    }
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Listado de Activos</h1>
            </div>
            <ActionToolbar
              actions={actions}
              checkIfHaveToDisable={disableAction}
              executeAction={redirect}
            />
            <div className="spacer10" />
            <label id="searchLabel" htmlFor="input">
              Buscar por{" "}
            </label>
            <div className="spacer20" />
            <div className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">Número de placa</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("plateNumber")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Descripción</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("description")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Fecha de inicio</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field }) => (
                    <GenericCalendar id="startDate" {...field} />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Fecha de finalización</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field }) => (
                    <GenericCalendar id="endDate" {...field} />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Estado</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="state"
                  render={({ field }) => (
                    <Dropdown
                      id="state"
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Estado"
                      {...field}
                      options={states}
                    />
                  )}
                />
              </div>
              <div className="md:col-12">
                <Button label="Buscar" onClick={handleSubmit(search)} />
              </div>
              <DataTable
                className="col-12"
                paginator={true}
                rows={10}
                value={assets}
                selection={asset}
                emptyMessage="No se encontraron registros"
                onSelectionChange={select}
              >
                <Column selectionMode="single" style={{ width: "4em" }} />
                <Column field="plateNumber" header="Número de placa" />
                <Column field="description" header="Descripción" />
                <Column field="localization" header="Localización" />
                <Column
                  body={(rowData) => formatCurrencyValue(rowData.cost)}
                  header="Costo"
                />
                <Column
                  body={(rowData) => formatDate(rowData.boughtTime)}
                  header="Fecha de compra"
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetList;
