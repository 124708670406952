/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React from "react";
import { useNavigate } from "react-router-dom";
import { showSuccess } from "../../utils/message";
import useService from "../../hooks/hook-service";
import ComponentForm from "./ComponentForm";

const ComponentCreate = () => {
  const { post } = useService();
  const navigate = useNavigate();

  const createComponent = (formData) => {
    post("component/create", { component: formData }, () => {
      showSuccess("El componente se creó correctamente");
      navigate(-1);
    });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <ComponentForm
              headerClass="custom-card-header-create"
              title="Crear Componente"
              titleButton="Guardar"
              actionToExecute={createComponent}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComponentCreate;
