import React, { useEffect, useState } from "react";
import ProviderForm from "./ProviderForm";
import { useLocation, useNavigate } from "react-router-dom";
import { showSuccess } from "../../utils/message";
import useService from "../../hooks/hook-service";

const ProviderEdit = () => {
  const [provider, setProvider] = useState({});
  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();

  useEffect(() => {
    post("provider/get", {
        id: location.state.provider.id
    }, ({provider}) => {
      setProvider(provider);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const editProvider = (provider) => {
    const data = {
      provider: {
        ...provider,
        id: location.state.provider.id,
      },
    };

    post("provider/update", data, () => {
      showSuccess("El proveedor se ha actualizado correctamente");
      navigate(-1);
    });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <ProviderForm
              headerClass="custom-card-header-edit"
              title="Editar Usuario"
              titleButton="Editar"
              actionToExecute={editProvider}
              provider={provider}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderEdit;
