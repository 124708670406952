/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Frank Chaves <fchaves@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import GenericCalendar from "../../components/GenericComponents/GenericCalendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import {
  convertStringToDate,
  formatCurrencyValue,
  formatDate,
} from "../../utils/format";
import { Controller, FormProvider, useForm } from "react-hook-form";
import useService from "../../hooks/hook-service";
import { showError, showSuccess, showWarning } from "../../utils/message";
import classNames from "classnames";
import WayToPay from "../../components/WayToPayForm";
import ActionToolbar from "../../components/ActionsToolbar";
import { useLocation, useNavigate } from "react-router-dom";
import createExcel from "../../utils/createExcel";
import pdf from "../../utils/pdf";

const BillsToPayList = () => {
  const ACTION_IDS = {
    PAY: 3,
    INVALIDATE: 4,
  };

  const [receivableInvoice, setReceivableInvoice] = useState({});
  const [receivableInvoices, setReceivableInvoices] = useState([]);
  const [providers, setProviders] = useState([]);
  const [states, setStates] = useState([]);
  const [isInvalidatingBill, setIsInvalidatingBill] = useState(false);
  const [isPayingBill, setIsPayingBill] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const actions = location.state.actions;
  const formInvalidatingBill = useForm();
  const formPayMethods = useForm();
  const { register, control, handleSubmit } = useForm();
  const { post, manyPost } = useService();

  useEffect(() => {
    const request = [
      {
        url: "provider/search",
        body: {
          idCard: null,
          name: "",
        },
      },
      {
        url: "catalog/list",
        body: {
          catalogType: 1014,
        },
      },
    ];
    manyPost(request, (result) => {
      setProviders(result[0].providers);
      setStates(result[1].catalogs);

      handleSubmit(search)();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculate = () => {
    let total = 0;
    receivableInvoices?.forEach((element) => {
      total += element.amount;
    });

    return formatCurrencyValue(total);
  };

  const onError = (errors) => {
    showError("Debe completar los campos");
  };

  const onSubmitInvaliteBill = (formData) => {
    post(
      "receivableinvoices/invalidate",
      {
        receivableInvoice: receivableInvoice,
        motive: formData.motive,
      },
      () => {
        showSuccess("La factura ha sido invalidada");
        formInvalidatingBill.reset();
        setIsInvalidatingBill(false);
        handleSubmit(search)();
      }
    );
  };

  const search = (formData) => {
    const request = {
      number: formData.number,
      providerId: formData.provider,
      referenceNumber: formData.referenceNumber,
      startDate: convertStringToDate(formData.startDate),
      finalDate: convertStringToDate(formData.endDate),
    };

    if (formData.transferType && formData.transferType !== "") {
      request.transferType = {
        id: formData.transferType,
      };
    }

    if (formData.type && formData.type !== "") {
      request.type = {
        id: formData.type,
      };
    }

    if (formData.status && formData.status !== "") {
      request.state = {
        id: formData.status,
      };
    }

    post("receivableinvoices/list", request, ({ receivableInvoices }) => {
      if (receivableInvoices.length === 0) {
        showWarning("No se encontraron registros");
      }
      setReceivableInvoices(receivableInvoices);
      setReceivableInvoice({});
    });
  };

  const disableAction = (actionId) => {
    if (actionId === ACTION_IDS.PAY && receivableInvoice.status?.id !== 61) {
      return true;
    }

    if (
      actionId === ACTION_IDS.INVALIDATE &&
      receivableInvoice.status?.id !== 62
    ) {
      return true;
    }

    return Object.getOwnPropertyNames(receivableInvoice).length === 0;
  };

  const onSubmitPayingBill = (formData) => {
    const request = {
      receivableInvoice: receivableInvoice.id,
      transferType: { id: formData.transferType },
      referenceNumber: formData.referenceNumber,
      observations: formData.observations,
      payDate: formData.payDate,
    };

    post("receivableinvoices/pay", request, () => {
      showSuccess("El pago ha sido realizado.");
      formPayMethods.reset();
      setIsPayingBill(false);
      handleSubmit(search)();
    });
  };

  const askToDesireToInvalidate = () => {
    setIsInvalidatingBill(true);
  };

  const askToDesireToPay = () => {
    setIsPayingBill(true);
  };

  const redirect = (route) => {
    switch (route) {
      case "/billsToPayCreate":
        navigate(route);
        break;
      case "/billsToPayDetail":
        navigate(route, {
          state: { receivableInvoice: receivableInvoice },
        });
        break;
      case "/billsToPayInvalidate":
        askToDesireToInvalidate();
        break;
      case "/billsToPayPayment":
        askToDesireToPay();
        break;
      case "/exportExcel":
        exportToExcel();
        break;
      case "/exportPDF":
        exportToPdf();
        break;
      default:
        break;
    }
  };

  const exportToExcel = () => {
    let result = {
      columns: [
        { title: "Número", width: { width: 15 } },
        { title: "Cédula del proveedor", width: { width: 15 } },
        { title: "Nombre del proveedor", width: { width: 25 } },
        { title: "Fecha de compra", width: { width: 15 } },
        { title: "Fecha de registro", width: { width: 15 } },
        { title: "Estado", width: { width: 15 } },
        { title: "Tipo", width: { width: 15 } },
        { title: "Monto", width: { width: 15 } },
      ],
      data: [],
    };

    receivableInvoices.forEach((element) => {
      result.data.push([
        element.number ?? "",
        element.provider?.idCard ?? "",
        element.provider?.name ?? "",
        formatDate(element.buyDate),
        formatDate(element.registrationDate),
        element.status.name ?? "",
        element.type.name ?? "",
        formatCurrencyValue(element.amount) ?? "",
      ]);
    });
    createExcel("Facturas por Pagar", [result]);
  };

  const exportToPdf = () => {
    const body = [];
    receivableInvoices.forEach((element) => {
      const array = [
        element.number ?? "",
        element.provider?.idCard ?? "",
        element.provider?.name ?? "",
        formatDate(element.buyDate),
        formatDate(element.registrationDate),
        element.status.name ?? "",
        element.type.name ?? "",
        formatCurrencyValue(element.amount) ?? "",
      ];
      body.push(array);
    });
    const data = {
      head: [
        [
          "Número",
          "Cédula del proveedor",
          "Nombre del proveedor",
          "Fecha de compra",
          "Fecha de registro",
          "Estado",
          "Tipo",
          "Monto",
        ],
      ],
      body: body,
      startY: 25,
      bodyStyles: { valign: "top" },
      tableWidth: "auto",
      styles: {
        cellWidth: "wrap",
        rowPageBreak: "auto",
        halign: "justify",
        overflow: "linebreak",
      },
      columnStyles: { text: { cellWidth: "auto" } },
    };
    pdf.createPDF(
      "l",
      data,
      "Facturas-por-Pagar.pdf",
      "Reporte de Facturas por Pagar"
    );
  };

  const select = (e) => {
    if (e.value) {
      setReceivableInvoice(e.value);
    } else {
      setReceivableInvoice({});
    }
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Listado de Facturas por Pagar</h1>
            </div>
            <Dialog
              header="Invalidar Factura"
              visible={isInvalidatingBill}
              modal={true}
              onHide={() => setIsInvalidatingBill(false)}
            >
              <form
                onSubmit={formInvalidatingBill.handleSubmit(
                  onSubmitInvaliteBill,
                  onError
                )}
                className="grid"
              >
                <div className="col-12">
                  ¿Desea invalidar la factura seleccionada?
                </div>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Motivo:</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="text"
                    {...formInvalidatingBill.register("motive", {
                      required: true,
                    })}
                    className={classNames({
                      "p-invalid":
                        !!formInvalidatingBill.formState.errors.motive,
                    })}
                  />
                </div>
                <div className="md:col-12">
                  <Button label="Guardar" />
                  <Button
                    type="button"
                    label="Regresar"
                    onClick={() => setIsInvalidatingBill(false)}
                  />
                </div>
              </form>
            </Dialog>

            <Dialog
              header="Realizar Pago"
              visible={isPayingBill}
              modal={true}
              onHide={() => setIsPayingBill(false)}
            >
              <form
                onSubmit={formPayMethods.handleSubmit(
                  onSubmitPayingBill,
                  onError
                )}
                className="grid"
              >
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Fecha de pago</label>
                </div>
                <div className="col-12 md:col-4">
                  <Controller
                    control={formPayMethods.control}
                    name="payDate"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <GenericCalendar
                        id="payDate"
                        {...field}
                        className={classNames({
                          "p-invalid":
                            !!formPayMethods.formState.errors.payDate,
                        })}
                      />
                    )}
                  />
                </div>
                <FormProvider {...formPayMethods}>
                  <WayToPay requiredAmount={false} />
                </FormProvider>
                <div className="col-12 md:col-2">
                  <label htmlFor="input">Observaciones</label>
                </div>
                <div className="col-12 md:col-4">
                  <InputText
                    type="text"
                    {...formPayMethods.register("observations", {
                      required: true,
                    })}
                    className={classNames({
                      "p-invalid":
                        !!formPayMethods.formState.errors.observations,
                    })}
                  />
                </div>
                <div className="md:col-12">
                  <Button label="Pagar" />
                  <Button
                    type="button"
                    label="Regresar"
                    onClick={() => setIsPayingBill(false)}
                  />
                </div>
              </form>
            </Dialog>

            <ActionToolbar
              actions={actions}
              checkIfHaveToDisable={disableAction}
              executeAction={redirect}
            />

            <div className="spacer10" />
            <label id="searchLabel" htmlFor="input">
              Buscar por
            </label>
            <div className="spacer20" />
            <div className="grid">
              <div className="col-12 md:col-2">
                <label htmlFor="input">Número</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("number")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Número de referencia</label>
              </div>
              <div className="col-12 md:col-4">
                <InputText type="text" {...register("referenceNumber")} />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Proveedor</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="provider"
                  render={({ field }) => (
                    <Dropdown
                      id="provider"
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Seleccione un proveedor"
                      {...field}
                      options={providers}
                    />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Estado</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => (
                    <Dropdown
                      id="status"
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Estado"
                      {...field}
                      options={states}
                    />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Fecha inicial</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field }) => (
                    <GenericCalendar id="startDate" {...field} />
                  )}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Fecha final</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field }) => (
                    <GenericCalendar id="endDate" {...field} />
                  )}
                />
              </div>
              <div className="md:col-12">
                <Button label="Buscar" onClick={handleSubmit(search)} />
              </div>
              <DataTable
                className="col-12"
                rows={10}
                value={receivableInvoices}
                selection={receivableInvoice}
                onSelectionChange={select}
                emptyMessage="No se encontraron registros"
                footer={() =>
                  "Monto total por todas las facturas es de " + calculate()
                }
              >
                <Column selectionMode="single" style={{ width: "4em" }} />
                <Column field="number" header="Número" />
                <Column field="referenceNumber" header="Número de Referencia" />
                <Column
                  body={(rowData) => formatDate(rowData.formatDateRegister)}
                  header="Fecha de Registro"
                />
                <Column
                  body={(rowData) => formatDate(rowData.buyDate)}
                  header="Fecha de Compra"
                />
                <Column
                  body={(rowData) => formatCurrencyValue(rowData.amount)}
                  header="Monto"
                />
                <Column field="type.name" header="Tipo de Factura" />
                <Column field="status.name" header="Estado" />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillsToPayList;
